import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HistoryService {
    constructor(private http: HttpClient) { }

    getAll( moduleId: number, itemId: number) {
      let params = new HttpParams();
      params = params.append('moduleId', moduleId );
      params = params.append('itemId', itemId );
  
      return this.http.get<any[]>(`${environment.apiUrl}/api/history.php`, {params})
        .toPromise()
        .then(
          res => res);
    }
}

