import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '@app/models/constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  userstr: string;
  c: any;

    constructor(private router: Router) {
      this.c = Constants;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      this.userstr = localStorage.getItem('currentUser');

      if ( this.userstr ) {
            const user = JSON.parse(this.userstr);
            if(user.type === this.c.USER_TYPE_CLIENT &&
              state.url === '/notices' ) { return true; }

            if (
                user.type === this.c.USER_TYPE_CLIENT ||
                user.type === this.c.USER_TYPE_YARD_F ||
                user.type === this.c.USER_TYPE_VIEWER
              ) {
                if ( state.url === '/' ) { return true; }
                else { return false; }
            }else {
              if ( user.type === this.c.USER_TYPE_TAX ) {
                if ( state.url === '/contacts' ) { return true; }
              else { this.router.navigate(['/contacts']); return false; }
              }
            }
            return true;

        }

        // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
}
