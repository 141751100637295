<ng-container
  *ngIf="isUserAuthenticated(); then loggedIn; else loggedOut">
</ng-container>

<ng-template #loggedIn>
  <div class="layout-wrapper" [ngClass]="{
      'layout-menu-horizontal': menuMode === 'horizontal',
      'layout-menu-popup': menuMode === 'popup',
      'layout-menu-overlay': menuMode === 'overlay',
      'layout-menu-static': menuMode === 'static',
      'layout-menu-static-inactive': (menuMode == 'static' && menuActive === false)}" (click)="onLayoutClick()">
    <app-topbar></app-topbar>
    <div class="layout-content">
      <p-toast></p-toast>

      <p-sidebar [(visible)]="showSidebar" position="top" [baseZIndex]="10000" [style]="{height: '200px'}">
        <div><h1 style="font-weight:normal">Session time out after: <span style="font-weight: bold; font-size: 1.2em;">{{timeOutStr}}</span></h1></div>
        <p-button type="button" (click)="resetInactTimer()" label="Continue" styleClass="p-button-success"></p-button>
        <p-button type="button" (click)="onLogOut()" label="Log Out" styleClass="p-button-secondary" [style]="{'margin-left': '5px'}"></p-button>
      </p-sidebar>

      <p-sidebar [(visible)]="showRelSidebar" position="top" [baseZIndex]="10000" [style]="{height: '200px'}">
        <h1 style="font-weight:normal">New page version exist! Reload?</h1>
        <p-button type="button" (click)="reloadPage()" label="Reload" styleClass="p-button-success"></p-button>
        <p-button type="button" (click)="showRelSidebar=false;" label="Cancel" styleClass="p-button-secondary" [style]="{'margin-left': '5px'}"></p-button>
      </p-sidebar>

      <p-sidebar [(visible)]="showNoticeSidebar" position="top" [baseZIndex]="10000" [style]="{height: '310px'}">
        <div style="height: 35px; font-size: 20px;" class="bl-hdr">Notice</div>
        <div style="font-weight:bold; font-size: 16px; padding-top: 10px; padding-bottom: 10px;">
          {{notice.subject}}</div>
        <div style="height: 100px;">{{notice.text}}</div>
        <div style="padding: 10px;"> <p-button type="button" (click)="acceptNotice(notice.id)" label="Ok" styleClass="p-button-success"></p-button> </div>
      </p-sidebar>

      <router-outlet></router-outlet>
      <app-settingsedit></app-settingsedit>
      <ng-chat [adapter]="chatAdapter" [userId]="chatUserId" (onMessagesSeen)="onChatMsgSeen($event)"
        (onParticipantChatClosed)="onPartWinClosed($event)" [isDisabled]="!chatEnabled" [searchEnabled]="false"
        [isCollapsed]="true" [title]="'Live chat'" ></ng-chat>
    </div>
  </div>
</ng-template>
<ng-template #loggedOut>
  <router-outlet></router-outlet>
</ng-template>

