<div><span style="display: inline-block; width:120px">Wire to:</span><strong>{{wireTo}}</strong></div>
<div><span style="display: inline-block; width:120px">Wire date: </span><strong>{{wireDate}}</strong></div>
<div><span style="display: inline-block; width:120px">Wire amount: </span><strong>{{priceSum | currency}}</strong></div>
<div><span style="display: inline-block; width:120px">Wire from: </span><strong>{{wireFrom}}</strong></div>
<div style="margin-top: 10px; margin-bottom: 10px;">Please apply payment for below containers:</div>
<table style="border-collapse: collapse; border: 1px solid black;">
  <thead>
      <tr style="background-color: rgb(229, 229, 190); border: 1px solid black;">
          <th style="border: 1px solid black;">Date</th>
          <th style="border: 1px solid black;">Booking</th>
          <th style="border: 1px solid black;">Container</th>
          <th style="border: 1px solid black;">Origin</th>
          <th style="border: 1px solid black;">Destination</th>
          <th style="border: 1px solid black;">Price</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let cont of containerList" style="border: 1px solid black;">
          <td style="border: 1px solid black;">{{cont.loadDate | date: 'MM/dd/yyyy'}}</td>
          <td style="border: 1px solid black;">{{cont.bookingNr}}</td>
          <td style="border: 1px solid black;">{{cont.containerNr}}</td>
          <td style="border: 1px solid black;">{{cont.origin}}</td>
          <td style="border: 1px solid black;">{{cont.destination}}</td>
          <td style="border: 1px solid black;">{{cont.price | currency}}</td>
      </tr>
      <tr style="border: 1px solid black;">
        <td colspan="5" style="text-align: right; font-style: italic;">Total:</td>
        <td style="font-weight: 500; border: 1px solid black;" >{{priceSum | currency}}</td>
      </tr>
  </tbody>
</table>
