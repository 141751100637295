<div class="content-section implementation">
  <p-table #tblObj [value]="notices"   [loading]="loading" scrollHeight="65vh"
   [scrollable]="true"   columnResizeMode="expand" dataKey="id" rowHover="true">
    <ng-template pTemplate="caption">
      <div class="box2" [style.width.%]="40">
        <button *ngIf="currentUser.type!==this.c.USER_TYPE_CLIENT" type="button" pButton icon="pi pi-plus" (click)="onNewNotice()"></button>
      </div>
      <div class="box2" [style.width.%]="60">
        <div class="cap-hdr"><i style="margin-right: 20px;" class="fas fa-exclamation-triangle"></i>Notices</div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="flex: 0 0 45px;"></th>
        <th style="flex: 0 0 120px;">Date</th>
        <th style="flex: 0 0 200px;">Client</th>
        <th style="flex: 0 0 250px;">Subject</th>
        <th >Text</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" >
      <tr class="ui-selectable-row ui-tr" #row [attr.id]="rowData.id" >
        <td style="flex: 0 0 45px; text-align: center;">
          <div>
            <a href="#" (click)="onMenuShow($event, menu, rowData)" ><i class="fas fa-bars"></i></a>
          </div></td>
        <td style="flex: 0 0 120px;">{{rowData.date}}</td>
        <td style="flex: 0 0 200px;">{{rowData.client}}</td>
        <td style="flex: 0 0 250px;">{{rowData.subject}}</td>
        <td>{{rowData.text}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div style="text-align:left">
        <button *ngIf="currentUser.type!==this.c.USER_TYPE_CLIENT" type="button" pButton icon="pi pi-plus"
          (click)="onNewNotice()" label="&nbsp;Add new notice"></button>
      </div>
    </ng-template>
</p-table>
<app-noticeedit (saveNoticeEvt)="saveNotice()"></app-noticeedit>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
</div>
