import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { Invoice, InvoiceItem, InvoiceSubData } from '@app/models/invoice';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })

export class InvoiceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json'  })
  };

  constructor(private http: HttpClient) { }

  getAll() {
      return this.http.get<Invoice[]>(`${environment.apiUrl}/api/invoices.php`)
      .toPromise()
      .then(  res => res as Invoice[]);
  }

  getNew(): Invoice {
    const curDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
    return {
      id: null, uuid: uuidv4() , number: null, date: curDate, contactId: 0, useCompany: true, total: 0.00, items: []
    };
  }

  getItems( invoiceId: number) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_items' );
    params = params.append('invId', invoiceId.toString() );
    return this.http.get<InvoiceItem[]>(`${environment.apiUrl}/api/invoices.php`, {params})
    .toPromise()
    .then(  res => res as InvoiceItem[]);
  }

  getNewItem(tp = 0): InvoiceItem {
    return {
      id: null, name: null, type: tp, quantity: 1, sum: 0, subItem: null,
        uid: Math.random().toString(36).slice(2), editMode: false
    }
  }

  getNewSubItem(): InvoiceSubData {
    return {
      id: null, containerNr: null, car: null, vin: null, yard: null, auctionPrice: 0, paidByClient: false, paidByClientOrd: false,
      value: 0,  buyerFee: 0, storageFee: 0, containerFee: 0, miscFee: 0, deliveryFee: 0, orderId: 0
    }
  }

  update(inv: Invoice) {
    const msg =  {cmd: 'invoice_update',  obj: inv };
    return this.http.post(`${environment.apiUrl}/api/invoices.php`, msg, this.httpOptions );
  }

  insertNew(inv: Invoice): Observable<any> {
    const msg =  {cmd: 'invoice_new',  obj: inv };
    return this.http.post(`${environment.apiUrl}/api/invoices.php`, msg, this.httpOptions );
  }

  getCarList() {
    let params = new HttpParams();
    params = params.append('cmd', 'car_list' );
    return this.http.get<any[]>(`${environment.apiUrl}/api/invoices.php`, {params})
      .toPromise()
      .then(  res => res );
  }
}

