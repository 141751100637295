<p-dialog #invdlg header="Invoice details" [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'1000px'}" appendTo="body">
  <div class="p-grid ui-fluid"   [style.min-height.vh]="20" >
    <form #invoiceForm="ngForm" (ngSubmit)="submitInvoice(invoiceForm)"  >
      <div class="ui-fluid" >

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="15">
            <label for="Date">Date</label>
            <p-calendar [(ngModel)]="invoice.date" dateFormat="mm/dd/yy" id="date" name="date" appendTo="body"
            class="form-control" [showIcon]="true" [style]="{'width':'100%'}"  required ></p-calendar>

          </div>
          <div class="box2" [style.width.%]="30" [style.padding-left.px]="20">
            <label for="number">Number</label>
            <input pInputText id="number" name="number" [style.width.%]="100" [(ngModel)]="invoice.number" required/>
          </div>
          <div class="box2" [style.width.%]="55" [style.padding-left.px]="20">

          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="15" >
            <label for="contactType">Contact type</label>
            <p-dropdown id="contactType" name="contactType" [options]="contactTypes" [(ngModel)]="invoice.useCompany"
            [style]="{'width':'100%', 'margin-bottom': '0px'}" (onChange)="onContactTypeChange()" appendTo="body" ></p-dropdown>
          </div>
          <div class="box2" [style.width.%]="80" [style.padding-left.px]="20">
            <label for="name">{{invoice.useCompany ? 'Company' : 'Contact'}} name</label>
            <p-dropdown id="name" name="name" [options]="contactList" [(ngModel)]="invoice.contactId" appendTo="body" optionValue="id"
              [optionLabel]="invoice.useCompany ? 'company' : 'name' " placeholder="Select contact" [style]="{'width': '100%'}" required></p-dropdown>
          </div>
          <div class="box2" [style.width.%]="5" [style.padding-left.px]="10">
              <button type="button" pButton icon="pi pi-plus" (click)="onAddContact()" style="margin-top: 20px;"></button>
          </div>
        </div>
      </div>
    </form>

    <div style="margin-top: 20px;">
      <p-table #dt [value]="invoice.items" dataKey="uid"  rowHover="true" >
        <ng-template pTemplate="header">
            <tr>
              <th style="width: 50px; text-align: center;"><a href="#" (click)="onMenuShow($event, menu, null)"><i class="fas fa-bars"></i></a>
              </th>
              <th style="width: 70px;">Qty</th>
              <th>Description</th>
              <th style="width: 130px;">Unit price</th>
              <th style="width: 130px;">Line total</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-expanded="expanded">
          <tr *ngIf="item.type==0 || item.type==1">
            <td style="text-align: center;"><a href="#" (click)="onMenuShow($event, menu, item)"><i class="fas fa-bars"></i></a></td>
            <td> <div style="text-align: center;">{{item.quantity}}</div> </td>
            <td> <div>{{item.name? item.name : 'Add description ...'}}</div>    </td>
            <td> <div>{{item.sum | currency:'USD'}}</div>         </td>
            <td> <div><b>{{item.lsum | currency:'USD'}}</b></div>         </td>
          </tr>
          <tr *ngIf="item.type==2">
            <td style="text-align: center;"><a href="#" (click)="onMenuShow($event, menu, item)"><i class="fas fa-bars"></i></a></td>
            <td><button type="button" pButton pRipple [pRowToggler]="item"
                class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
            <td><div style="display: inline-block; width: 65%;">{{item.subItem.car}}</div>
                <div style="float: right; width: 32%;">[&nbsp;{{item.subItem.vin}}&nbsp;]</div></td>
            <td></td>
            <td><div><b>{{item.sum | currency:'USD'}}</b></div></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-item>
          <tr>
            <td colspan="2" style="text-align: right;">Paid<p-checkbox style="margin-left: 10px;"
              [(ngModel)]="item.subItem.paidByClient" [binary]="true" (onChange)="onPaidByChange($event, item.subItem)"></p-checkbox></td>
            <td>CAR VALUE</td>
            <td></td>
            <td><div *ngIf="!item.editMode"><b>{{item.subItem.value | currency:'USD'}}</b></div>
              <div *ngIf="item.editMode"><p-inputNumber [(ngModel)]="item.subItem.value" [inputStyle]="{'width':'100%'}"
                  mode="currency" currency="USD" locale="en-US"></p-inputNumber></div>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>BUYER FEE</td>
            <td></td>
            <td><div *ngIf="!item.editMode"><b>{{item.subItem.buyerFee | currency:'USD'}}</b></div>
              <div *ngIf="item.editMode"><p-inputNumber [(ngModel)]="item.subItem.buyerFee" [inputStyle]="{'width':'100%'}"
              mode="currency" currency="USD" locale="en-US"></p-inputNumber></div>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>DELIVERY FEE [ to yard: {{item.subItem.yard}} ]</td>
            <td></td>
            <td><div *ngIf="!item.editMode"><b>{{item.subItem.deliveryFee | currency:'USD'}}</b></div>
              <div *ngIf="item.editMode"><p-inputNumber [(ngModel)]="item.subItem.deliveryFee" [inputStyle]="{'width':'100%'}"
                mode="currency" currency="USD" locale="en-US"></p-inputNumber></div></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>STORAGE FEE</td>
            <td></td>
            <td><div *ngIf="!item.editMode"><b>{{item.subItem.storageFee | currency:'USD'}}</b></div>
              <div *ngIf="item.editMode"><p-inputNumber [(ngModel)]="item.subItem.storageFee" [inputStyle]="{'width':'100%'}"
                mode="currency" currency="USD" locale="en-US"></p-inputNumber></div></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>CONTAINER FEE [ Container: {{item.subItem.containerNr}} ]</td>
            <td></td>
            <td><div *ngIf="!item.editMode"><b>{{item.subItem.containerFee | currency:'USD'}}</b></div>
              <div *ngIf="item.editMode"><p-inputNumber [(ngModel)]="item.subItem.containerFee" [inputStyle]="{'width':'100%'}"
                mode="currency" currency="USD" locale="en-US"></p-inputNumber></div></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>MISCELLANEOUS FEE</td>
            <td></td>
            <td><div *ngIf="!item.editMode"><b>{{item.subItem.miscFee | currency:'USD'}}</b></div>
              <div *ngIf="item.editMode"><p-inputNumber [(ngModel)]="item.subItem.miscFee" [inputStyle]="{'width':'100%'}"
                mode="currency" currency="USD" locale="en-US"></p-inputNumber></div></td>
          </tr>

        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="4" style="text-align: right;">Invoice total sum:</td>
            <td><b>{{ invoice.total | currency: 'USD' }}</b></td>
          </tr>
        </ng-template>
      </p-table>
      <p-tieredMenu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-tieredMenu>
    </div>

    <p-messages [(value)]="errorMsg" [enableService]="false"></p-messages>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix" >
      <button type="button" pButton icon="pi pi-check" (click)="submitInvoice(invoiceForm); "  label="Save" ></button>
    </div>

  </p-footer>
</p-dialog>
<app-contactedit (newContact)="onContactNewSave($event)"></app-contactedit>
<app-inv-contsel (addToInvoice)="onAddFromContainer($event)"></app-inv-contsel>
<p-dialog header="Edit vehicle details" [(visible)]="displayCarEdDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'600px'}">
  <div class="p-grid ui-fluid"   [style.min-height.vh]="40" >
    <div class="ui-fluid" >
    </div>
  </div>
</p-dialog>

<p-dialog header="Edit invoice text line" [(visible)]="displayTxtDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'600px'}" >
  <div class="p-grid ui-fluid" >
    <div class="ui-fluid" >
      <div class="p-field" [style.height.px]="65">

        <div class="box2" [style.width.%]="10">
          <label for="quantity">Quantity</label>
          <input pInputText id="quantity" name="quantity" [style.width.%]="100" [(ngModel)]="editItem.quantity"/>
        </div>
        <div class="box2" [style.width.%]="70" [style.padding-left.px]="20">
          <label for="description">Description</label>
          <input pInputText id="description" name="description" [style.width.%]="100" [(ngModel)]="editItem.name" />
        </div>
        <div class="box2" [style.width.%]="20" [style.padding-left.px]="20">
          <label for="sum">Sum</label>
          <p-inputNumber [(ngModel)]="editItem.sum" inputId="sum" [inputStyle]="{'width' : '100%'}"
            mode="currency" currency="USD" locale="en-US"></p-inputNumber>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="onCancelItemChanges()" label="Cancel" ></p-button>
    <p-button icon="pi pi-check" (click)="onApplyItemChanges()" label="Apply changes" ></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Add car by vin" [(visible)]="displayVinDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'400px'}" >
  <div class="p-grid ui-fluid" [style.min-height.vh]="30">
    <div class="ui-fluid" >
      <div class="p-field" [style.height.px]="65">
        <div class="box2" [style.width.px]="120">
          <b>Select vehicle:</b>
         </div>
         <div class="box2" [style.width.px]="240" >
           <p-dropdown [options]="carList" [(ngModel)]="selectedVin" appentTo="body"
              [style]="{'width':'100%'}" [filter]="true" optionLabel="vin" optionValue="vin">
           </p-dropdown>
         </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="onCancelItemChanges()" label="Cancel" ></p-button>
    <p-button icon="pi pi-check" (click)="onApplyVinChange()" label="Add to invoice" ></p-button>
  </ng-template>
</p-dialog>
