import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { NgxGalleryImage } from 'ngx-gallery-9/lib/ngx-gallery-image.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PhotoService } from '@app/services/photo.service';
import { Observable, Subject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'app-photoedit',
  templateUrl: './photoedit.component.html',
  styleUrls: ['./photoedit.component.css']
})
export class PhotoEditComponent implements OnInit, AfterViewInit {
  @Input() imageList: NgxGalleryImage[];
  @Input() orderId: number;
  @Input() photoType: number;
  @Output() listChanged = new EventEmitter<number>();
  @Output() dialogHide = new EventEmitter<unknown>();
  @ViewChild('vidCont') vidConatDiv:ElementRef;

  private captureTrig: Subject<void> = new Subject<void>();

  displayDialog = false;
  uploadMode = false;
  curOrderId = 0;
  isMobile = false;
  camWidth: number;
  camHeight: number;
  showCamera = false;
  isLandscape = true;
// latest snapshot
  webcamImage: WebcamImage = null;

  public videoOptions: MediaTrackConstraints = {
     width: {ideal: 1024},
     height: {ideal: 768}
  };


  constructor(
    private photoService: PhotoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.camHeight = 0;
    this.camWidth = 0;
  }

  ngAfterViewInit() {
  }

    onDeleteImage(event, image) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this photo?',
      accept: () => { this.deleteImage(image); }
    });
  }

  onHide() {
    this.dialogHide.emit();
  }

  deleteImage( image ) {
    this.photoService.deleteImage(image.id).
    subscribe( () => {
      this.listChanged.emit(this.orderId);
      this.messageService.add({severity: 'error', summary: 'File deleted!', detail: ''});
    });
  }

  onAddImage() {
    if (!this.uploadMode) {
      this.uploadMode = true;
    } else {
      this.uploadMode = false;
    }
  }

  onCancelUpload() {
    this.uploadMode = false;
  }

  photoUploader( event ) {
    event.files.forEach(file => {
        const img = new Image();
        img.onload = ()=> {
          const elem = document.createElement('canvas');
          const ctx = elem.getContext('2d');
          if(img.width <= 1024 && img.height <= 768){
            elem.width = img.width;
            elem.height = img.height;
            ctx.drawImage(img, 0, 0);
          } else {
            elem.width = 1024;
            elem.height = 768;
            const scale = Math.min(elem.width / img.width, elem.height / img.height);
            const x = (elem.width / 2) - (img.width / 2) * scale;
            const y = (elem.height / 2) - (img.height / 2) * scale;
            ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
          }
          const imageData = elem.toDataURL(file.type);
          const blobBin = atob(imageData.split(',')[1]);
          const array = [];
          for(let i = 0; i < blobBin.length; i++) {
              array.push(blobBin.charCodeAt(i));
          }
          const outBlob = new Blob([new Uint8Array(array)], {type: file.type});
          const outFile = new File( [outBlob], file.name, { type: outBlob.type });

          this.photoService.uploadImage(outFile , this.orderId, this.photoType)
            .subscribe( resp => {
              this.listChanged.emit(this.orderId);
              this.messageService.add({severity: 'info', summary: 'File Uploaded!', detail: file.name});
            });
        };
        img.src = URL.createObjectURL(file);
      });
    this.uploadMode = false;
  }


  show( list: NgxGalleryImage[], orderId: number)  {
    this.curOrderId = orderId;
    this.imageList = list;
    this.displayDialog = true;
    this.webcamImage = null;
    this.isMobile = Boolean(window.innerWidth < 1025);
    this.onResize();
  }

  hide() {
    this.camHeight = 0;
    this.camWidth = 0;
    this.webcamImage = null;
    this.showCamera = false;
    this.displayDialog = false;
  }

  onAddByCamera() {
    this.showCamera = true;
    setTimeout( () => {
      this.camWidth = this.vidConatDiv.nativeElement.offsetWidth/1.3;
      this.camHeight = Math.round(this.vidConatDiv.nativeElement.offsetWidth / 1.33);
      this.onResize();
    }, 500);

  }


  handleImage(webcamImage: WebcamImage): void {
    const img = new Image();
        img.onload = ()=> {
          const elem = document.createElement('canvas');
          const ctx = elem.getContext('2d');
          if(img.width <= 1024 && img.height <= 768){
            elem.width = img.width;
            elem.height = img.height;
            ctx.drawImage(img, 0, 0);
          } else {
            elem.width = 1024;
            elem.height = 768;
            const scale = Math.min(elem.width / img.width, elem.height / img.height);
            const x = (elem.width / 2) - (img.width / 2) * scale;
            const y = (elem.height / 2) - (img.height / 2) * scale;
            ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
          }
          const imageData = elem.toDataURL('image/jpeg'); // type
          const blobBin = atob(imageData.split(',')[1]);
          const array = [];
          for(let i = 0; i < blobBin.length; i++) {
              array.push(blobBin.charCodeAt(i));
          }
          const outBlob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'}); // type
          const outFile = new File( [outBlob], 'Camera.jpeg', { type: outBlob.type });

          this.photoService.uploadImage(outFile , this.orderId, this.photoType)
            .subscribe( resp => {
              this.listChanged.emit(this.orderId);
              this.messageService.add({severity: 'info', summary: 'File Uploaded!', detail: 'Camera.jpeg'});
            });
        };
        img.src = webcamImage.imageAsDataUrl;
  }


  captureImage() {
    this.captureTrig.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.captureTrig.asObservable();
  }

  onResize( event? ) {
    setTimeout( () => {
    if(window.innerWidth > innerHeight) this.isLandscape = true;
    else this.isLandscape = false;
    },200);
  }
}
