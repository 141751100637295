&nbsp;
<div style="padding-bottom: 10px;">
<table style="border-collapse: collapse; border: 1px solid black;">
  <thead>
    <tr style="background-color: rgb(229, 229, 190); border: 1px solid black;">
        <th colspan="5">Vehicle information</th>
    </tr>
</thead>
  <tbody>
    <tr>
      <td>Model:</td>  <td style="font-weight: bold;">{{order.car}}</td> <td style="width: 100px;"></td>
      <td>Keys:</td>   <td style="font-weight: bold;">{{order.keyFob? 'yes':'no'}}</td>
    </tr>
    <tr>
      <td>VIN:</td>    <td style="font-weight: bold;">{{order.vin}}</td> <td></td>
      <td>Title:</td>  <td style="font-weight: bold;">{{order.title? 'yes':'no'}}</td>
    </tr>
    <tr>
      <td>Color:</td><td style="font-weight: bold;">{{order.color}}</td> <td></td>
      <td>Title type:</td><td style="font-weight: bold;">{{order.titleType}}</td>
    </tr>
    <tr>
      <td>Fuel:</td><td style="font-weight: bold;">{{order.fuel}}</td> <td></td>
      <td></td><td></td>
    </tr>
  </tbody>
</table>
</div>

<div *ngIf="order.loadDate"  style="padding-bottom: 10px;">
  <div>Vehicle loaded to {{'[' + order.size + '] container on: '}} <strong>{{order.loadDate}}</strong></div>
  <div>Container Nr.: <strong>{{order.containerNr}}</strong> will be deliverd to <strong>{{order.destination}}</strong> by <strong>{{order.shippingLine}}</strong></div>
</div>

<div *ngIf="galleryImages1.length>0" style="padding-bottom: 10px;">
  <div style="font-weight: bold; font-style: italic; padding-bottom: 5px;"> Photos of vehicle on auction page</div>
  <div *ngFor="let photo of galleryImages1">
    <img [src]="photo.big" />
  </div>
</div>

<div *ngIf="galleryImages2.length>0" style="padding-bottom: 10px;">
  <div style="font-weight: bold; font-style: italic; padding-bottom: 5px;"> Photos of vehicle at yard </div>
  <div *ngFor="let photo of galleryImages2">
    <img [src]="photo.big" />
  </div>
</div>

<div *ngIf="galleryImages3.length>0" style="padding-bottom: 10px;">
  <div style="font-weight: bold; font-style: italic; padding-bottom: 5px;"> Photos of vehicle before loading to container</div>
  <div *ngFor="let photo of galleryImages3">
    <img [src]="photo.big" />
  </div>
</div>
