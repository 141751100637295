import { PhotoService } from './../../services/photo.service';
import { OrdersService } from './../../services/order.service';
import { cloneDeep } from 'lodash';
import { Component, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { Order } from '@app/models/order';
import { NgxGalleryImage } from 'ngx-gallery-9';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-emailbody-order-tpl',
  templateUrl: 'emailbody.order.component.html',
})
export class EmailBodyOrderComponent implements OnInit{
  elRef: ElementRef;
  order: Order;
  galleryImages1: NgxGalleryImage[];
  galleryImages2: NgxGalleryImage[];
  galleryImages3: NgxGalleryImage[];

  constructor(
    elRef: ElementRef,
    private refDet: ChangeDetectorRef,
    private orderService: OrdersService,
    private photoService: PhotoService
    ) {
    this.elRef = elRef;
  }

  ngOnInit() {
    this.order = this.orderService.getNewOrder();
    this.galleryImages1 = [];
    this.galleryImages2 = [];
    this.galleryImages3 = [];
  }

  setData( data: any ) {
    this.order = cloneDeep((data.order) as Order);
    this.galleryImages1 = [];
    this.galleryImages2 = [];
    this.galleryImages3 = [];
    this.refDet.detectChanges();
  }

  getHtmlContent() {
    return this.elRef.nativeElement.innerHTML;
  }

  setPhotoSel( id: number, state: boolean) {
    if( state === true ) {
      this.photoService.getPhotosOf( this.order.id, id )
        .toPromise().then( photos => {
          photos.forEach ( photo => {
            photo.big = `${environment.apiUrl}/` + photo.big;
          });
          if (id === 1) { this.galleryImages1 = photos; }
          else
          if (id === 2) { this.galleryImages2 = photos; }
          else
          if (id === 3) { this.galleryImages3 = photos; }
          this.refDet.detectChanges();
        });
    } else {
      if (id === 1) { this.galleryImages1 = []; }
      else
      if (id === 2) { this.galleryImages2 = []; }
      else
      if (id === 3) { this.galleryImages3 = []; }
    }

  }

  refreshComponent() {
    this.refDet.detectChanges();
  }

}
