import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthenticationService } from './../services/authentication.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
      private authenticationService: AuthenticationService,
      private messageService: MessageService
      ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
          retry(1),
          catchError( err => {

            if (err.status === 401) {
              // auto logout if 401 response returned from api
              this.authenticationService.logout();
              location.reload();
            }

            let errorMessage = '';
            if ( err.error !== undefined && err.error !== null) {
              errorMessage =  err.error.message;
            }
            if ( errorMessage === '' ) {
              errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;
            }


            console.log('Http error: ', errorMessage);
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: errorMessage});
            return throwError(errorMessage);

        }  ));
    }
}
