<p-dialog header="Add cars to invoice from container" [(visible)]="displayDialog" [focusOnShow]="true"
  showEffect="fade" [modal]="true"  [style]="{'width':'1000px'}" appendTo="body">
  <div class="p-grid ui-fluid"   [style.min-height.vh]="30" >
    <div class="p-field" [style.height.px]="65">
      <div class="box2" [style.width.px]="150">
       <b>Select container:</b>
      </div>
      <div class="box2" [style.width.px]="180" [style.padding-left.px]="20">
        <p-dropdown [options]="containerList" [ngModel]="selContainerId" appentTo="body"
          (onChange)="onContainerChange($event)" [style]="{'width':'200px'}" [filter]="true" >
        </p-dropdown>
      </div>

    </div>
    <p-table dataKey="id" [value]="contCars" [style.width.%]="100" rowHover="true" [rows]='6' [loading]="loading">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 50px;"></th>
          <th pColumn="date" style="width: 90px;">Date</th>
          <th pColumn="location">Location</th>
          <th pColumn="buyer" style="width: 70px;">Buyer</th>
          <th pColumn="lot" style="width: 80px;">Lot</th>
          <th pColumn="model">Vehicle</th>
          <th pColumn="vin" style="width: 160px;">VIN</th>
          <th pColumn="auctionPirce" style="width: 100px;">Value</th>
          <th pColumn="consignee">Consignee</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr class="ui-selectable-row ui-tr">
          <td>
            <p-checkbox [(ngModel)]="rowData.selected" [binary]="true" ></p-checkbox>
          </td>
          <td>{{rowData.date}}</td>
          <td>{{rowData.location}}</td>
          <td>{{rowData.buyerNr}}</td>
          <td>{{rowData.lot}}</td>
          <td>{{rowData.year + ' ' +rowData.model}}</td>
          <td>{{rowData.vin}}</td>
          <td>{{rowData.auctionPrice | currency:'USD'}}</td>
          <td>{{rowData.consignee}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="9">
            <div *ngIf="contCars.length>0">
            <button type="button" pButton icon="pi pi-plus" (click)="onAddToInvoice();" label="&nbsp;Add to invoice"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
