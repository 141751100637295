import { Invoice } from '@app/models/invoice';
import { InvoiceService } from '@app/services/invoice.service';
import { InvoiceEditComponent } from '@app/components/invoiceedit/invoiceedit.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoiceview.component.html',
  styleUrls: ['./invoiceview.component.css']
})
export class InvoiceViewComponent implements OnInit,  AfterViewInit {
  @ViewChild(InvoiceEditComponent) invoiceEdit: InvoiceEditComponent;

  loading = false;
  searchBar = false;
  invoiceList: Invoice[];
  tmpInvoice: Invoice;
  menuItems: MenuItem[];

  constructor(
    protected invoiceService: InvoiceService,
    private route: ActivatedRoute,
    ) {}

  ngOnInit(): void {
    this.loadAllInvoices();
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => { // route parameter /contacts? xxx=zzz
      const pars = params;
      if ( pars.add_vin !== undefined ) { // vin from orders
        this.invoiceEdit.createNewInvoice();
        this.invoiceEdit.addCarByVin(pars.add_vin)
          .then( ()=> {
            this.invoiceEdit.show();
          });
      }
      if ( pars.add_container !== undefined ) { // container id from container
        this.invoiceEdit.createNewInvoice();
        this.invoiceEdit.addCarsByContainerId(pars.add_container);
      }

    });
  }

  loadAllInvoices() {
    this.loading = true;
    this.searchBar = false;
    this.invoiceService.getAll()
      .then( list => {
        list.forEach( it=> {
          it.date = it.date ? formatDate(it.date, 'MM/dd/yyyy', 'en') : null;
        });
        this.invoiceList = list;
        this.loading = false;
      });
  }

  onToggleSearchBar( tbl, event ) {
    event.stopPropagation();
    event.preventDefault();

    if (this.searchBar === true) {
      this.searchBar = false;
      tbl.reset();
    } else {
      this.searchBar = true;
    }
  }

  onNewInvoice() {
    this.invoiceEdit.createNewInvoice();
    this.invoiceEdit.show();
  }

  onMenuShow( event, menu, invoice: Invoice, tblObj) {
    this.menuItems = [
        {label: 'Edit invoice', icon: 'fas fa-edit', command: () => { this.onInvoiceEdit(event, invoice) }},
        {label: 'Preview', icon: 'pi pi-eye', command: () => { this.onInvoiceView(event, invoice) }},
        {label: 'Download', icon: 'pi pi-download', command: () => { this.onInvoiceDownload(event, invoice) }},
        {label: 'Email', icon: 'fas fa-at', items: [
          {label: 'Inline', command: () => { this.onInvoiceDownload(event, invoice) }},
          {label: 'Attachment', command: () => { this.onInvoiceDownload(event, invoice) }},
        ]},
    ];
    menu.toggle(event);
    event.stopPropagation();
    event.preventDefault();
  }

  onInvoiceView(event, row) {
    event.stopPropagation();
    event.preventDefault();
    let url = `${environment.apiUrl}/api/invoices.php`;
    url = url + '?cmd=invoice_show&uuid='+row.uuid;
    window.open(url, '_blank', 'noopener');
  }

  onInvoiceDownload(event, row) {
    event.stopPropagation();
    event.preventDefault();
    let url = `${environment.apiUrl}/api/invoices.php`;
    url = url + '?cmd=invoice_show&uuid='+row.uuid+'&pdf';
    window.open(url, '_blank', 'noopener');

  }


  onInvoiceEdit(event, row) {
    event.stopPropagation();
    event.preventDefault();
    this.invoiceEdit.setInvoice(row);
    this.invoiceEdit.show();
  }

  onInvoiceSendByEmail(event, row) {
    event.stopPropagation();
    event.preventDefault();
  }

  afterInvoiceNewSave(invoice) {
    this.loadAllInvoices();
  }

  afterInvoiceUpdateSave(invoice) {
    this.loadAllInvoices();
  }

}
