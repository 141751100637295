﻿import { UsersService } from '@app/services/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
      private http: HttpClient,
      private userService: UsersService,
      public jwtHelper: JwtHelperService
      ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public getUser(): Observable<User> {
      return this.currentUser;
    }

    login(username: string, password: string, tz: number) {
        return this.http.post<any>(`${environment.apiUrl}/api/login.php`, { username, password, tz })
            .pipe(map(res => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', res.token);
                const user = this.jwtHelper.decodeToken(res.token).data;
                if(user.yard === '') user.yard = null;
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      this.currentUserSubject.next(this.userService.getNew());
    }
    isAuthenticated() {
      return (localStorage.getItem('currentUser') == null) ? false : true;
    }

}

