import { cloneDeep } from 'lodash';
import { UsersService } from '@app/services/user.service';
import { User } from '@app/models';
import { NoticeService } from './../services/notice.sevice';
import { Notice } from './../models/notice';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NoticeEditComponent } from '@app/components/noticeedit/noticeedit.component';
import { formatDate } from '@angular/common';
import { MenuItem } from 'primeng/api/menuitem';
import { AuthenticationService } from '@app/services/authentication.service';
import { Constants } from '@app/models/constants';

@Component({
  templateUrl: 'noticeview.component.html',
  styleUrls: ['noticeview.component.css'],
})
export class NoticeViewComponent implements OnInit {
  @ViewChild(NoticeEditComponent) noticeEdit: NoticeEditComponent;

  c: any;
  notices: Notice[];
  tmpNotice: Notice;
  menuItems: MenuItem[];
  users: User[];
  currentUser: User;
  loading = false;

  constructor(
    private noticeService: NoticeService,
    private userService: UsersService,
    private authenticationService: AuthenticationService,
    ) {
    }

  ngOnInit() {
    this.c = Constants;
    this.loading = true;
    this.currentUser = this.userService.getNew();
    this.userService.getAll().toPromise().then(users => {
      this.users = [];
      users.forEach( user => {
          if( Number(user.type) === 3 ) { // client
            this.users.push( user );
          }
      });
      this.noticeEdit.setClientList(this.users);
      this.authenticationService.currentUser.subscribe(
        (user) => {
          this.currentUser = user;
          let userId = 0;
          if(this.currentUser.type===this.c.USER_TYPE_CLIENT) userId = user.id;
          this.loadNotices(userId);
        });

    });
  }

  processNotice( notice: Notice) {
    notice.date =  formatDate(notice.date, 'MM/dd/yyyy', 'en');
    const usr = this.users.find(u => u.id === notice.clientId);
    if(usr) notice.client = usr.name;

    return notice;
  }

  onNewNotice() {
    this.tmpNotice = this.noticeService.getNew();
    this.noticeEdit.show(this.tmpNotice, true);
  }

  onNoticeEdit(event, notice: Notice, edit: boolean) {
    this.tmpNotice = cloneDeep(notice);
    this.noticeEdit.show(this.tmpNotice, edit);
  }

  loadNotices(userId: number) {
    this.loading = true;
    this.noticeService.getAll(userId)
    .then(notices => {
      this.notices = notices;
      this.notices.forEach(not => {
        not = this.processNotice(not);
     });
    this.loading = false;
    });
  }

  onNoticeDelete(event, notice: Notice) {
    this.noticeService.deleteOrder(notice)
      .subscribe( ()=>{
        this.loadNotices(0);
      });
  }

  saveNotice() {
    if(this.tmpNotice.id > 0) { // updated
      const index = this.notices.findIndex(n => n.id === this.tmpNotice.id);
      this.tmpNotice = this.processNotice(this.tmpNotice);
      this.notices[index] = this.tmpNotice;
    } else { // new
      this.loadNotices(0);
    }
  }

  onMenuShow(event, menu, notice: Notice) {
    this.menuItems = [{
      label: 'Options',
      items: [ ]}
    ];
    if(this.currentUser.type===this.c.USER_TYPE_CLIENT) {
      this.menuItems[0].items.push(
        {label: 'Expand notice', icon: 'fas fa-edit', command: () => { this.onNoticeEdit(event, notice, false) }}
      );
    } else {
      this.menuItems[0].items.push(
        {label: 'Edit notice', icon: 'fas fa-edit', command: () => { this.onNoticeEdit(event, notice, true) }},
        { separator:true },
        {label: 'Delete notice', icon: 'far fa-trash-alt', command: () => { this.onNoticeDelete(event, notice) }},
      );
    }
      menu.toggle(event);
      event.stopPropagation();
      event.preventDefault();
    }

}
