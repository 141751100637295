import { Observable } from 'rxjs';
import { InterCompNotifier } from './../services/notifier.service';
import { HistoryService } from './../services/history.service';
import { AuctionService } from './../services/auction.sevice';
import { UsersService } from './../services/user.service';
import { Contact } from './../models/contact';
import { Container } from '@app/models/container';
import { ContainerService } from '@app/services/container.service';
import { OrderEditComponent } from '@app/components/orderedit/orderedit.component';
import { ContainerEditComponent} from '@app/components/containeredit/containeredit.component';
import { AuthenticationService } from '@app/services/authentication.service';
import { YardsService } from '@app/services/yard.service';
import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Order, Yard, User, DocFile, Auction, AuctionAcc } from '@app/models';
import { OrdersService } from '@app/services/order.service';
import { formatDate } from '@angular/common';
import { cloneDeep } from 'lodash';
import { PhotoService } from '@app/services/photo.service';
import { Constants } from '@app/models/constants';
import { MessageService, SelectItem, Message, LazyLoadEvent } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAction } from 'ngx-gallery-9';
import { environment } from '@environments/environment';
import { PhotoEditComponent } from '@app/components/photoedit/photoedit.component';
import { DocFileService } from '@app/services/docfile.service';
import { ContactService } from '@app/services/contact.service';
import { DocEditComponent } from '@app/components/docedit/docedit.component';
import { ContactEditComponent } from '@app/components/contactedit/contactedit.component';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmailEditComponent } from '@app/components/emailedit/emailedit.component';
import { EmailBodyOrderComponent } from '@app/components/emailbody/emailbody.order.component';
import { NavigationExtras, NavigationStart, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  templateUrl: 'orderview.component.html',
  providers: [  ConfirmationService, DialogService],
  styleUrls: [ 'orderview.component.css'],
  animations: [
    trigger('inOutAnimationWidth',  [
        transition(':enter', [  style({ width: 0, opacity: 0 }),  animate('400ms ease-out',  style({ width: '*', opacity: 1 })) ] ),
        transition(':leave', [  style({ width: '*', opacity: 1 }), animate('200ms ease-in',   style({ width: 0, opacity: 0 }))   ] )
      ] )
  ]
})

export class OrderViewComponent implements OnInit {
  @ViewChild(OrderEditComponent) ordEdit: OrderEditComponent;
  @ViewChild(PhotoEditComponent) photoEdit: PhotoEditComponent;
  @ViewChild(ContainerEditComponent) containerEdit: ContainerEditComponent;
  @ViewChild(ContactEditComponent) contactEdit: ContactEditComponent;
  @ViewChild(DocEditComponent) docEdit: DocEditComponent;
  @ViewChildren('row', { read: ElementRef }) rowElement: QueryList<ElementRef>;
  @ViewChild(EmailBodyOrderComponent) emailBodyTpl: EmailBodyOrderComponent;
  @ViewChild('tblObj') tblView: Table;
  @ViewChild('tblCbView') tblCbView;

  ref: DynamicDialogRef;
  c: any;
  tmpOrder: Order;
  currentUser: User;
  orders: Order[];
  loadedOrders: Order[];
  yards: Yard[];
  filterYards: any[];
  intContactList: any[];
  intContactList2: any[];
  filterStatus: any;
  searchBar = false;
  selectedOrder: Order;
  selectedYard: Yard;
  overlayText: string;
  overlayMode: string;
  overlayCarrier: Contact;
  overlayCarrierTel = false;
  loading = false;
  editAuctionInfo = false;
  editCarInfo = false;
  editDispatchInfo = false;
  consigneeEdit = false;
  editPhotoInfo = false;
  photoTypeOptions: SelectItem[];
  selectedPhotoType = 1;
  selectedOrderId = 0;
  displayPhotoDialog = false;
  displayDocDialog = false;
  displayHistDialog = false;
  hideDispatchEdit = false;
  docListCont: DocFile[];
  docListTitle: DocFile[];
  docListDispatch: DocFile[];
  docList: DocFile[];
  fuelTypes: SelectItem[];
  clipbEnabled = false;

  shippingLines: SelectItem[];
  containerList: SelectItem[];
  contactOptions: any[];
  tmpContainer: Container;
//  dropString: string;
  orderEditingEnabled = false;
  galleryOptions: NgxGalleryOptions[];
  galleryOptionsFS: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryActions: NgxGalleryAction[];
  galleryCurSelectedImage: number;
  orderFilter: any;
  carrierList: Contact[];
  consigneeList: any[];
  selectedCarrier: Contact;
  tmpCarrier: Contact;
  autoComplResults: string[];
  docCount = 0;
  menuItems: MenuItem[];
  selItemsMenu: MenuItem[];
  rowExpanded = false;
  outstandingBalance: number;
  globalConsigneeFilter: string;
  globalAuctionFilter: string;
  globalAuctionAccFilter: string;
  globalNoDocFilter: string;
  globalArchivedFilter = false;
  selectionEnabled = false;
  selectedList: string[];
  selectedOrders: Order[];
  selectedOrdersId: string[];
  auctionList: Auction[];
  auctionAccList: AuctionAcc[];
  historyList: any[];
  userPrivilege: any;
  pricesEnabled = true;
  tableWidth = '1900px';
  totalRecords: any;
  editorList: any[];

  enabledColumns = {
    delete: true,
    location: true,
    orderId: true,
    color: true ,
    fuel: true ,
    auctionPrice: true ,
    containerPrice: true ,
    buyerFee: true ,
    buyerPay: true,
    storageFee: true ,
    deliveryPrice: true ,
    miscFee: true,
    subtotal: true,
    carrier: true,
    containers: true,
    key: true,
    title: true,
    lotTag: true,
    clientId: true,
    client: true,
    colSpan: 15
  };
  gfDocTypes: SelectItem[];
  loadingHist: boolean;

  constructor(
    private mNotifier: InterCompNotifier,
    private orderService: OrdersService,
    private yardService: YardsService,
    private photoService: PhotoService,
    private authenticationService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private containerService: ContainerService,
    private contactService: ContactService,
    private docService: DocFileService,
    private userService: UsersService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private historyService: HistoryService,
    private router: Router,
    private auctionService: AuctionService,
    private changeDetectorRef: ChangeDetectorRef
    ) {
      this.userPrivilege = [];
      this.intContactList = [];
      this.intContactList2 = [];
      this.containerList = [];
      this.auctionList = [];
      this.auctionAccList = [];
      this.contactOptions = [];
      this.historyList = [];
      this.filterStatus = [
        {label: 'All', value: ''},
        {label: 'No information', value: 0},
        {label: 'Paid', value: 1},
        {label: 'Dispatched', value: 2},
        {label: 'Picked up', value: 3},
        {label: 'Delivered', value: 4},
        {label: 'Consolidated', value: 5},
        {label: 'Loaded', value: 6},
      ];
      this.photoTypeOptions = [
        {label: 'Auction', value: 1},
        {label: 'Yard', value: 2},
        {label: 'Container', value: 3}   ];

      this.galleryActions = [{
        icon: 'pi pi-copy',
        titleText: 'Copy picture' ,
        onClick: (event, index) => {
          this.copyImageToClipboard( event, index, this);
        }}];
      this.galleryOptions = [  {  width: '100%', height: '300px', thumbnails: false ,
        preview: false, imageDescription: false, imageActions: this.galleryActions  }  ];
      this.galleryOptionsFS = [ {  width: '100%', height: '650px', thumbnails: true ,
        preview: false, imageDescription: true, imageActions: this.galleryActions   }  ];
      this.gfDocTypes = [
        {label: '', value: 0},
        {label: 'Title', value: 'Title'},
        {label: 'BOS (Bill of Sale)', value: 'BOS (Bill of Sale)'},
       // {label: 'Lean release', value: 'Lean release'},
       // {label: 'Additional docs.', value: 'Additional docs.'}
      ];
      this.mNotifier.notification.subscribe(
        (arg: any) => {
          if( !arg ) return;
          if( arg.type === 'editors') {
            this.editorList = arg.data.filter(e => e.module === '1');
            if(this.orders ) {
              this.orders.forEach (
                ord => {
                  const res = this.editorList.find( e => Number(e.itemId) === Number(ord.id) );
                  if(res) {
                    ord.inEditBy = Number(res.editorId);
                    ord.inEditByName = res.username;
                  }
                  else {
                    ord.inEditBy = 0;
                    ord.inEditByName = "";
                  }
                });
              }
          } else
          if( arg.type === 'logout') {
            this.onRowEditFinish();
          }
        }
      );
      this.router.events.subscribe((event) => {
        if(event instanceof NavigationStart) {
          this.onRowEditFinish(); // remove id from editors if any
        }
      });
    }

  ngOnInit() {
    this.loadingHist = false;
    this.totalRecords = 0;
    this.outstandingBalance = 0;
    this.tmpOrder = this.orderService.getNewOrder();
    this.orderFilter = {};
    this.c = Constants;
    this.fuelTypes = [];
    this.docListCont = [];
    this.docListTitle = [];
    this.docListDispatch = [];
    this.selectedList = [];
    this.consigneeList = [];
    this.galleryImages = [];
    this.selectedOrders = [];
    this.selectedOrdersId = [];
    this.orders = [];

    this.authenticationService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;
        if(user && user.id !== 0 ){ // onLogout == NULL
          if(user.type !== this.c.USER_TYPE_YARD_F && user.clientId !== null){
            this.orderFilter.consigneeName = user.clientId;
          }
          if((user as any).yard !== null) {
            this.orderFilter.yard = (user as any).yard;
          }
        }
        this.loading = true;
        this.processPrivileges();
        this.userService.getPriv(user.id)
          .then( p=> {
            this.userPrivilege = p;
            this.processPrivileges();
          });
      }

    );
    this.globalConsigneeFilter = '';
    this.globalAuctionFilter = '';
    this.globalAuctionAccFilter = '';
    this.globalNoDocFilter = '';
    this.auctionService.getAll()
      .then( lst => {
        this.auctionList = lst;
      });
    this.loadInternContacts();
    this.filterYards = [
      {label: 'All', value: ''},
    ];
    this.yardService.getAll().then(yards => {
      this.yards = yards;
      yards.forEach( y => { this.filterYards.push({ label: y.yard, value: y.yard }); });
    });


    this.selectedCarrier =  this.contactService.getNew();
    this.overlayCarrier = this.selectedCarrier;

    this.menuItems = [];
    this.selItemsMenu = [
      {label: 'Add to container',     command: () => { this.selectionToContainer();     }},
      {separator: true},
      {label: 'Clear selection', command: () => {  this.selectedList = []; this.selectionEnabled = false; }},
    ];
  }

  processPrivileges(){
    if(!this.userPrivilege) {
      this.userPrivilege = [];
      this.userPrivilege.order_col_client = '0';
      this.userPrivilege.order_fld_ed_consignee = '1';
    }

    if ( this.currentUser.type === this.c.USER_TYPE_CLIENT) {
      this.enabledColumns.colSpan = 19;
      this.enabledColumns.delete = false;
      this.enabledColumns.fuel = false;
      this.enabledColumns.orderId = false;
      this.enabledColumns.clientId = false;
      if(this.userPrivilege.order_col_client!=='1'){
        this.enabledColumns.client =  false;
      } else {
        this.enabledColumns.client =  true;
        this.enabledColumns.colSpan++;
        this.tableWidth = '2100px';
      }
      this.enabledColumns.carrier = false;
      this.enabledColumns.color = false;
      this.enabledColumns.lotTag = false;
      this.enabledColumns.deliveryPrice = false;
    } else
    if ( this.currentUser.type === this.c.USER_TYPE_VIEWER) {
      this.enabledColumns.delete = false;
      this.enabledColumns.orderId = false;
      this.enabledColumns.clientId = false;
      this.enabledColumns.client = false;
      this.enabledColumns.carrier = false;
      this.enabledColumns.color = false;
      this.enabledColumns.deliveryPrice = false;
      this.enabledColumns.containerPrice = false;
      this.enabledColumns.auctionPrice = false;
      this.enabledColumns.storageFee = false;
      this.enabledColumns.buyerFee = false;
      this.enabledColumns.buyerPay = false;
      this.enabledColumns.miscFee = false;
      this.enabledColumns.subtotal = false;
      this.enabledColumns.lotTag = false;
      this.pricesEnabled = false;
      this.enabledColumns.colSpan = 13;
    } else
    if ( this.currentUser.type === this.c.USER_TYPE_DISPATCH ||
        this.currentUser.type === this.c.USER_TYPE_YARD ||
        this.currentUser.type === this.c.USER_TYPE_YARD_F
      ) {
        this.enabledColumns.clientId = false;
        this.enabledColumns.client = false;
        this.enabledColumns.delete = false;
        this.enabledColumns.fuel = false;
        this.enabledColumns.color = false;
        this.enabledColumns.auctionPrice = false;
        this.enabledColumns.containerPrice = false;
        this.enabledColumns.buyerFee = false;
        this.enabledColumns.buyerPay = false;
        this.enabledColumns.storageFee = false;
        this.enabledColumns.miscFee = false;
        this.enabledColumns.subtotal = false;
        if(this.currentUser.yard==='NY') {
          this.enabledColumns.lotTag = true;
          this.enabledColumns.colSpan = 16;
        } else {
          this.enabledColumns.lotTag = false;
          this.enabledColumns.colSpan = 15;
        }
        this.pricesEnabled = false;
       if(this.currentUser.type === this.c.USER_TYPE_DISPATCH){
        this.enabledColumns.lotTag = false;
        this.enabledColumns.buyerPay = true;
        this.enabledColumns.containers = false;
        this.orderEditingEnabled = true;
        this.enabledColumns.colSpan = 15;
        this.pricesEnabled = true;
      }
    } else
    if ( this.currentUser.type === this.c.USER_TYPE_ADMIN ) {
      this.enabledColumns.lotTag = false;
      this.enabledColumns.key = false;
      this.enabledColumns.fuel = false;
      this.enabledColumns.orderId = false;
      this.enabledColumns.carrier = false;
      this.enabledColumns.containerPrice = false;
      this.enabledColumns.buyerFee = false;
      this.enabledColumns.storageFee = false;
      this.enabledColumns.subtotal = false;
      this.enabledColumns.miscFee = false;
      this.orderEditingEnabled = true;
      this.enabledColumns.colSpan = 18;
    }
    if ( this.currentUser.type === this.c.USER_TYPE_MANAGER ) {
      this.enabledColumns.lotTag = false;
      this.enabledColumns.location = false;
      this.enabledColumns.orderId = false;
      this.enabledColumns.carrier = false;
      this.enabledColumns.containerPrice = false;
      this.enabledColumns.buyerFee = false;
      this.enabledColumns.storageFee = false;
      this.enabledColumns.subtotal = false;
      this.enabledColumns.color = false;
      this.enabledColumns.miscFee = false;
      this.orderEditingEnabled = true;
      this.enabledColumns.colSpan = 16;
    }

  }

  searchAutoCompl( event, field ) {
    this.orderService.suggestList(field, event.query )
     .toPromise().then(data => {
       this.autoComplResults = data;
      });
  }

  onDrop(ev) {
    if (this.orderEditingEnabled) {
      this.onNewOrder();
      const data = ev.event.dataTransfer.getData('text');
      const obj = JSON.parse( data );
      obj.fuel = obj.fuel.charAt(0).toUpperCase() + obj.fuel.slice(1).toLowerCase();
      obj.titleType = obj.docType;
      obj.auctionId = '1'; // Copart
      Object.assign(this.tmpOrder, obj);
    }
  }

  expandClick(rowData, tblObj) {
    if (tblObj.expandedRowKeys[rowData.id] != null) { // colapse
      delete tblObj.expandedRowKeys[rowData.id];
      tblObj.expandedRowKeys = {};
      this.hideDispatchEdit = false;
      this.editAuctionInfo = false;
      this.editCarInfo = false;
      this.editDispatchInfo = false;
      this.rowExpanded = false;
      this.onRowEditFinish();
    } else { // expand
      this.rowExpanded = true;
      this.consigneeEdit = false;
      tblObj.expandedRowKeys = {};
      tblObj.expandedRowKeys[rowData.id] = true;
      this.selectedPhotoType = 1;
      this.loadImages(rowData.id);
      this.fuelTypes = this.ordEdit.getFuelTypes();
      this.galleryCurSelectedImage = 1;
      this.selectedOrderId = Number( rowData.id );
      this.loadContainerData( rowData.containerId );
      this.loadDocList(2, rowData.containerId); // 2 - container docs
      this.loadDocList(3, rowData.id); // 3 - title docs
      this.loadDocList(4, rowData.id); // 4 - dispatch docs
      this.loadCarrierData( rowData.carrierId );
      this.contactService.getConsigneeList(rowData.consignee)
        .then( list => { this.consigneeList = list;}  );
      setTimeout( () => {
        const el = this.rowElement.find(r => r.nativeElement.getAttribute('id') === rowData.id);
        el.nativeElement.scrollIntoView({behavior: 'smooth', inline: 'start', block: 'start'});
      },300 );
    }
  }


  loadInternContacts(){
    this.intContactList = [
      { label: 'All', value: '' }
    ];
    this.intContactList2 = [
      { label: '', value: '' }
    ];
    this.contactService.getContactList('4,3') // 4-International client 3-client
    .then( list => {
      list.forEach( c=> {
        this.intContactList.push({ label: c.clientId, value: c.clientId });
        this.intContactList2.push({ label: c.clientId, value: c.clientId });
      });
    });
  }

  loadCarrierData( id: number) {
    return this.contactService.getContactList('2') // 2-carriers
    .then( list => {
      this.carrierList = list;
      this.selectedCarrier = list.find( c => c.id === id);
      this.overlayCarrierTel = false;
      if (this.selectedCarrier) {
        if (this.selectedCarrier.phone1.length >0  ||
          this.selectedCarrier.phone2.length>0 ||
          this.selectedCarrier.email.length>0 ) {
          this.overlayCarrierTel = true;
          return;
        }
      }
    } );
  }

  loadImages(orderid) {
    let nr = 1;
    this.galleryImages = [];
    this.photoService.getPhotosOf( orderid, this.selectedPhotoType )
    .toPromise().then( photos => {
      if (photos === null) { photos = []; }
      photos.forEach ( photo => {
        photo.small = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=small`;
        photo.medium = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=medium`;
        photo.big = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=big`;
        photo.description = 'Photo: ' + nr + ' of ' + photos.length;

        nr = nr + 1;
      });

      this.galleryImages = photos;
    }
    );
  }

  onImageListChanged(orderid) {
    this.loadImages(orderid);
  }

  getLogTagStyle( tag ) {
    if( Number(tag) >=0 && Number(tag) <= 200 ) return ' background-color: #80A0DF; ';
    if( Number(tag) >200 && Number(tag) <= 300 ) return ' background-color: #F8F880; ';
    if( Number(tag) >=300 && Number(tag) <= 450 ) return ' background-color: #FF8080 ';
    return '';
  }

  processOrder(order) {
    order.car = order.year + ' ' + order.model;
    if (order.paidByClient === '1') {order.paidByClient = true; } else
    if (order.paidByClient === '0') {order.paidByClient = false; }
    if (order.archived === '1') {order.archived = true; } else
    if (order.archived === '0') {order.archived = false; }
    if (order.keyFob === '1') {order.keyFob = true; } else
    if (order.keyFob === '0') {order.keyFob = false; }
    if (order.keyFobD === '1') {order.keyFobD = true; } else
    if (order.keyFobD === '0') {order.keyFobD = false; }
    if (order.title === '1') {order.title = true; } else
    if (order.title === '0') {order.title = false; }
    if (order.titleD === '1') {order.titleD = true; } else
    if (order.titleD === '0') {order.titleD = false; }
    if (order.photo1Cnt === '0') { order.photo1Cnt = null; }
    if (order.photo2Cnt === '0') { order.photo2Cnt = null; }
    if (order.photo3Cnt === '0') { order.photo3Cnt = null; }
    if (order.containerPrice === null) { order.containerPrice = '0.00'; }
    if (order.miscDescr === '') { order.miscDescr = null; }
    if (order.noteDisp === '') { order.noteDisp = null; }
    order.tagStyle = this.getLogTagStyle(order.lotTag);

    order.loadDate = order.loadDate ? formatDate(order.loadDate, 'MM/dd/yyyy', 'en') : null;
    order.pickupDate = order.pickupDate ? formatDate(order.pickupDate, 'MM/dd/yyyy', 'en') : null;
    order.deliveryDate = order.deliveryDate ? formatDate(order.deliveryDate, 'MM/dd/yyyy', 'en') : null;
    order.paymentDate = order.paymentDate ? formatDate(order.paymentDate, 'MM/dd/yyyy', 'en') : null;

    order.dispatchStatus = 0; // no info
    if ( order.paymentDate ) {  order.dispatchStatus = 1; } // paid
    if ( order.orderId ) {   order.dispatchStatus = 2; } // Dispached
    if ( order.pickupDate ) {   order.dispatchStatus = 3; } // picked up
    if ( order.deliveryDate ) {   order.dispatchStatus = 4; } // delivered
    if ( order.containerNr !== null ) {
      if((order.containerNr as string).includes('Consol') ) order.dispatchStatus = 5; // consol
      else order.dispatchStatus = 6;  // loaded
    }

    if ( order.fuel === '' ) { order.fuel = null; }
    order.fuelClass = 'uni-symb fa-color-red';
    order.fuelSymbol = '✖';
    if (typeof order.fuel === 'string') {
      order.fuelSymbol = '✔';
      order.fuelClass = 'uni-symb fa-color-green';
      if ( order.fuel.toUpperCase().indexOf('ELECTRIC') !== -1) {  order.fuelSymbol = '⚠'; order.fuelClass = 'uni-symb  fa-color-red'; }
      if ( order.fuel.toUpperCase().indexOf('HYBRID') !== -1) {  order.fuelSymbol = '⚠'; order.fuelClass = 'uni-symb  fa-color-red'; }
      if ( order.fuel.toUpperCase().indexOf('HYDROGEN') !== -1) {  order.fuelSymbol = '⚠'; order.fuelClass = 'uni-symb fa-color-red'; }
    }
    if ( order.buyerNr === '64581') {
      order.auctionURL = 'https://www.iaai.com/vehicledetails/' + order.lot;
    } else {
      order.auctionURL = 'https://www.copart.com/lot/' + order.lot;
    }
    if( order.auctionId) {
      order.auctionIcon = this.auctionList.find( i=> i.id === order.auctionId).icon;;
    } else order.auctionIcon = null;

    order.containerTrackingURL = 'http://www.shippingline.org/track/?type=container&container=' + order.tracking;

    order.deliveryPrice2 = parseFloat(order.deliveryPrice) + parseFloat(order.storageFee2);
 //   order.subtotal = (
//      Number( order.auctionPrice ) +
//      Number( order.buyerFee ) +
//      Number( order.storageFee ) +
//      Number( order.buyerPay ) +
//      Number( order.miscFee ) +
//      Number( order.containerPrice ));
 //   if( order.paidByClient  ) {
//      order.subtotal = Number(order.subtotal) - Number( order.auctionPrice );
//    }

    if ( (parseFloat(order.deliveryPrice2) - parseFloat(order.buyerPay)) >= 0 ) {
      order.priceClassName = 'dr_error';
    }    else { order.priceClassName = null; }

//    this.outstandingBalance += Number(order.subtotal);

    if ( this.currentUser.type === this.c.USER_TYPE_CLIENT
      || this.currentUser.type === this.c.USER_TYPE_YARD) {
      if (order.shippingLine != null) {
          const str = order.shippingLine.split('-');
          order.shippingLine = str[0];
      }
    } else {
      if (order.forwarder != null && order.forwarder !== '')  {
        order.shippingLine = order.shippingLine + ' - ' + order.forwarder;
      }
    }
    return order;
  }

  onRowEditFinish() {
    if(!this.currentUser || !this.currentUser.id) return;
    this.orderService.editFinish(this.currentUser.id)
      .toPromise().then( a =>{
        if(this.selectedOrder)
          this.selectedOrder.inEditBy = 0;
        });
  }

  onRowEditInit(rowData) {
    rowData.inEditBy = this.currentUser.id;
    this.orderService.editOrder(rowData).toPromise().then();
    this.selectedYard = null;
    this.selectedOrder = rowData;
    this.tmpOrder =  cloneDeep(rowData);
    this.yards.forEach (yard => {
        if ( yard.yard === rowData.yard ) {
          this.selectedYard = yard;
        }
    } );
    this.auctionService.getAuctionAcc()
      .then( lst => { this.auctionAccList = lst; });
  }

  onNewOrder() {
    this.selectedYard = null;
    this.auctionService.getAuctionAcc()
      .then( lst => { this.auctionAccList = lst; });
    this.tmpOrder = this.orderService.getNewOrder();
    this.ordEdit.setUserPrivilege(this.userPrivilege);
    this.ordEdit.show( this.tmpOrder );
  }

  onDeleteOrder( order ) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this order?',
      accept: () => { this.deleteOrder(order); }
    });
  }

  deleteOrder(order) {
    this.orderService.deleteOrder(order)
    .subscribe((ord: Order) => {
      console.log('Order deleted: ', ord);
      const index = this.orders.findIndex(o => o.id === order.id);
      this.orders = this.orders.filter((val, i) => i !== index);
   });
  }

  saveOrder() {
    this.tmpOrder.yard = this.selectedYard.yard;
    this.tmpOrder.containerId = this.tmpContainer.id;
    this.tmpOrder.date = formatDate(this.tmpOrder.date, 'MM/dd/yyyy', 'en');
    this.tmpOrder = this.processOrder(this.tmpOrder);
    this.orderService.updateOrder(this.tmpOrder)
      .subscribe((order: Order) => {
        const index = this.orders.findIndex(o => o.id === order.id);
        this.processOrder(order);
        this.orders.splice(index, 1, order);
        this.orders = [...this.orders];
        this.loadCarrierData(order.carrierId);
    });

  }

  gotNewOrder(event) {
    this.orderService.insertNewOrder(event)
      .subscribe((order: Order) => {
        order = this.processOrder(order);
        this.orders.push(order);
        this.ordEdit.hide();
      });
  }


  toggleOverlay(event, overl, txt) {
    event.stopPropagation();
    event.preventDefault();
    this.overlayMode = 'text';
    this.overlayText = txt;
    overl.toggle(event);
  }

  onEditAuctionInfoToggle(order) {
    if (this.editAuctionInfo) {
      this.editAuctionInfo = false;
      this.onRowEditFinish();
    } else {
        this.editAuctionInfo = true;
        this.editCarInfo = false;
        this.editDispatchInfo = false;
        this.onRowEditInit(order);
    }
  }

  onSaveAuctionInfo() {
    this.editAuctionInfo = false;
    this.onRowEditFinish();
    this.saveOrder();
  }

  onEditCarInfoToggle(order) {
    if (this.editCarInfo) {
      this.editCarInfo = false;
      this.onRowEditFinish();
    } else {
        this.editAuctionInfo = false;
        this.editCarInfo = true;
        this.editDispatchInfo = false;
        this.onRowEditInit(order);
    }
  }

  onSaveCarInfo() {
    this.editCarInfo = false;
    this.onRowEditFinish();
    this.saveOrder();
  }

  onEditDispatchInfoToggle(order: Order) {
    if (this.editDispatchInfo) {
      this.editDispatchInfo = false;
      this.onRowEditFinish();
    } else {
        this.editAuctionInfo = false;
        this.editCarInfo = false;
        this.onRowEditInit(order);
        this.loadContainerData(order.containerId);
        this.contactOptions = [{label: '', value: 0, city: '' }];
        this.carrierList.forEach( c => {
          this.contactOptions.push({ label: c.company, value: c.id, city: c.city  });
        });
        this.containerService.getList()
          .then( list => {  this.containerList = list;  });
        this.editDispatchInfo = true;
    }
  }

  onSaveDispatchInfo() {
    this.editDispatchInfo = false;
    this.onRowEditFinish();
    this.tmpOrder.containerId = this.tmpContainer.id;
    this.saveOrder();
  }

  onPhotoClick(event, type: number, orderId: number) {
    event.stopPropagation();
    event.preventDefault();
    this.selectedOrderId = orderId;
    this.selectedPhotoType = type;
    this.loadImages(orderId);
    this.displayPhotoDialog = true;
   }

  onEditPhotoInfoToggle(rowData: Order) {
    if (this.editPhotoInfo) {
      this.editPhotoInfo = false;
      this.onRowEditFinish();
      this.photoEdit.hide();
    } else {
        this.editPhotoInfo = true;
        this.editAuctionInfo = false;
        this.editCarInfo = false;
        this.editDispatchInfo = false;
        this.photoEdit.show(this.galleryImages, rowData.id);
    }
  }

  onPhotoEditClosed() {
    this.editPhotoInfo = false;
    this.onRowEditFinish();
    const order = this.orders.find( o => Number( o.id ) === Number( this.selectedOrderId ) );
    let cnt = this.galleryImages.length.toString();
    if ( cnt === '0') { cnt = null; }
    if ( this.selectedPhotoType === 1) { order.photo1Cnt = cnt; }
    if ( this.selectedPhotoType === 2) { order.photo2Cnt = cnt; }
    if ( this.selectedPhotoType === 3) { order.photo3Cnt = cnt; }
  }

  onPhotoTypeChange(event, row) {
    this.selectedPhotoType = event.value;
    if(this.selectedPhotoType === 3){
      this.loadImages(row.containerId);
    }
    else this.loadImages(row.id);
  }

  onToggleSearchBar( tbl, event ) {
    if (this.searchBar === true) {
      this.searchBar = false;
      tbl.reset();
    } else {
      this.searchBar = true;
    }
    event.preventDefault();
  }

  onPhotoInedxChange( event ) {
    this.galleryCurSelectedImage = event.index + 1;
  }

  onDownloadImages() {
    this.photoService.downloadPhotos(this.selectedOrderId, this.selectedPhotoType)
    .subscribe( response => {
      const byteString: string = window.atob(response);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob: any = new Blob([int8Array], { type: 'application/zip' });
      const order = this.orders.find( o => Number( o.id ) === Number( this.selectedOrderId ) );
      let filename = order.car.split(' ').join('_');
      filename += '_' + order.vin.substr(11);
      if (this.selectedPhotoType === 1) { filename += '_Auction'; } else
      if (this.selectedPhotoType === 2) { filename += '_Yard_' + order.yard; } else
      if (this.selectedPhotoType === 3) { filename += '_Container'; }
      import('file-saver').then(FileSaver => {
        FileSaver.saveAs(blob, filename + '.zip');
      });
    } );
  }

  onContainerEdit() {
    if (this.tmpContainer.containerNr !== null) {
      this.containerEdit.setVinToBeAdded(this.selectedOrder.vin);
    } else {
      this.containerEdit.setVinToBeAdded('');
    }
    this.containerEdit.show(this.tmpContainer);
  }

  onContainerNew() {
    this.tmpContainer = this.containerService.getNew();
    if (this.tmpContainer.containerNr !== null) {
      this.containerEdit.setVinToBeAdded(this.selectedOrder.vin);
    } else {
      this.containerEdit.setVinToBeAdded('');
    }
    this.containerEdit.show(this.tmpContainer);
  }

  onContainerNewSave(contId: number) {
    this.containerService.getList()
      .then( list => {  this.containerList = list; });
    this.containerService.getById(contId)
      .then( cont => {
        cont.loadDate = cont.loadDate ? formatDate(cont.loadDate, 'MM/dd/yyyy', 'en') : null;
        this.tmpContainer = cont;
        this.tmpOrder.containerId = cont.id;
        this.saveOrder();
      });
  }

  onContainerUpdateSave(contId: number) {
      this.loadContainerData(contId);
      this.tmpOrder.containerId = contId;
      this.saveOrder();
  }

  onContainerChange(event) {
    if( Number(event.value) > 0){
      this.containerService.getById(event.value)
        .then( cont => {
          this.tmpContainer = cont;
        });
    } else {
      this.tmpContainer = this.containerService.getNew();
    }
  }

  loadContainerData(Id) {
    if (Id == null) {
      this.tmpContainer = this.containerService.getNew();
    } else {
      this.containerService.getById(Id)
      .then( cont => {
        cont.loadDate = cont.loadDate ? formatDate(cont.loadDate, 'MM/dd/yyyy', 'en') : null;
        this.tmpContainer = cont;
      });
    }
  }

  showTitleDocViewPanel(event,  ordId) {
    event.preventDefault();
    this.docList = cloneDeep(this.docListTitle);
    this.displayDocDialog = true;
  }

  showTitleDocEditPanel(ordId) {
    const id = Number(ordId);
    this.docList = cloneDeep(this.docListTitle);
    this.docEdit.show( 3, id); // 3 - order title
  }

  showDispatchDocViewPanel(event) {
    event.preventDefault();
    this.docList = cloneDeep(this.docListDispatch);
    this.displayDocDialog = true;
  }

  showDispatchDocEditPanel(ordId) {
    const id = Number(ordId);
    this.docList = cloneDeep(this.docListDispatch);
    this.docEdit.show( 4, id); // 4 - dispatch title
  }

  showContDocViewPanel(event,  contId) {
    event.preventDefault();
    const id = Number(contId);
    this.docList = cloneDeep(this.docListCont);
    this.displayDocDialog = true;
  }

  loadDocList(type: number, id: number ) {
    this.docService.getFileList( type, id )
      .subscribe( lst => {
        if (type === 4) {
          this.docListDispatch = lst;
        } else if (type === 2) {
          this.docListCont = lst;
        } else {
          this.docListTitle = lst;
        }
        this.docList = cloneDeep(lst);
      } );
  }

  onDocListChanged(id) {
    const modId = this.docEdit.getModuleId();
    this.loadDocList(modId, id);
  }

  showCarrierInfoOverlay(event, overl, cont) {
    event.stopPropagation();
    event.preventDefault();
    this.overlayMode = 'carrier';
    this.overlayCarrier = cont;
    overl.toggle(event);
  }

  onCarrierAddNew() {
    this.tmpCarrier = this.contactService.getNew();
    this.contactEdit.show(this.tmpCarrier);
  }

  onCarrierNewSave(id: number) {
    this.tmpOrder.carrierId = id;
    this.loadCarrierData(id).then(
      () => {
        this.contactOptions = [{label: '', value: 0, city: '' }];
        this.carrierList.forEach( c => {
          this.contactOptions.push({ label: c.company, value: c.id, city: c.city  });
        });
      }
    );
  }

  onMenuShow( event, menu, order: Order, tblObj) {
    this.selectedOrder = order;
    this.menuItems = [{
      label: 'Options',
      items: [
        {label: 'Expand row', icon: 'fas fa-expand', command: () => {  this.expandClick(order, tblObj); }},
        {label: 'Add to invoice', icon: 'fas fa-file-alt', command: () => {  this.onCreateInvoice( this.selectedOrder ) ; }},
        {label: 'Send by email', icon: 'fas fa-at', command: () => {  this.onEmailOrder( this.selectedOrder ) ; }},
      ]}
    ];
    if ( this.currentUser.type === this.c.USER_TYPE_ADMIN ||
          this.currentUser.type === this.c.USER_TYPE_MANAGER ) {
      this.menuItems[0].items.push({ separator:true } );
      this.menuItems[0].items.push(
        {label: this.selectedOrder.archived ? 'Archived ✓' : 'Archived -' ,
          icon: 'far fa-file-archive', command: () => {  this.onArchiveOrder( this.selectedOrder ) ; } }
      );
    }
    if ( this.currentUser.type === this.c.USER_TYPE_ADMIN ) {
      this.menuItems[0].items.push({ separator:true } );
      this.menuItems[0].items.push(
        {label: 'Delete order', icon: 'far fa-trash-alt', command: () => {  this.onDeleteOrder( this.selectedOrder ) ; }}
      );
    }
    if ( this.currentUser.history ) {
      this.menuItems[0].items.push(
        {label: 'History', icon: 'fas fa-history', command: () => {  this.onShowHistory( this.selectedOrder ) ; }}
      );
    }


    menu.toggle(event);
    event.stopPropagation();
    event.preventDefault();

  }

  onArchiveOrder( ord ) {
    if( ord.archived )  ord.archived = false;
    else                ord.archived = true;

    this.orderService.archiveOrder(ord)
    .subscribe((ord: Order) => {
      const index = this.orders.findIndex(o => o.id === ord.id);
      this.orders = this.orders.filter((val, i) => i !== index);
   });

  }

  onCreateInvoice( ord: Order ) {
    const navigationExtras: NavigationExtras = {
      queryParams: { add_vin: ord.vin },
      skipLocationChange: true
    };
    this.router.navigate(['/invoices'], navigationExtras );
  }

  onEmailOrder( ord ) {
    this.emailBodyTpl.setData( {
      order: ord
    });

    this.ref = this.dialogService.open(EmailEditComponent, {
      data: {
          to: '',
          emailId: 0, // new email
          subject: 'Details about ' + ord.vin + ' car',
          body: this.emailBodyTpl.getHtmlContent(),
          bodyObj: this.emailBodyTpl,
          order: ord
      },
      header: 'Compose email',
      width: '50%'
    });
    this.ref.onClose.subscribe((resp: any) => {
      if (resp !== undefined) {
      }
    });
  }

  onCBClick(event) {
    event.stopPropagation();
    event.preventDefault();
    if( this.selectedList.length > 0) {
      this.orderService.getOrderById(Number(this.selectedList[0]))
      .then( ord => {
        this.tblView.filter(ord.consigneeCountry, 'consigneeCountry', 'equals');
        this.tblView.filter(ord.yard, 'yard', 'equals');
      });
    } else {
      this.tblView.reset();
    }
  }

  onCBSelClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onSelection(event, but) {
    but.onDropdownButtonClick(event);
  }

  selectionToContainer() {
    this.containerEdit.getNewContainer()
      .then( cont => {
        this.containerEdit.show( cont );
        this.containerEdit.emitEvents = false;
        this.selectedList.forEach( it =>{
          const ord = this.orders.find( o=> Number(o.id) === Number(it) );
          if( ord ) { this.containerEdit.addContainerCarFromOrder( ord ); }
        });
        this.selectedList = [];
        this.selectionEnabled = false;
        this.tblView.reset();
      });
  }

  onSelEnableChange(event) {
    if(event.checked===false){
      this.selectedList = [];
      this.tblView.reset();
    }
  }

  onClientConsigneeEdit(order) {
    this.onRowEditInit(order);
    this.consigneeEdit=true;
  }

  exportExcel() {
    const expArr = [];
    let row ;
    this.orderFilter.limit_count = 65000;
    this.orderService.getAll(this.orderFilter)
      .toPromise().then(orders => {
        orders.forEach (
          item => { item = this.processOrder(item);
          row = { Date :  item.date };
          if( this.enabledColumns.location ) {
            row.Location = item.location;
            row.Sublot = item.sublot;
          }
          row =  { ...row, Buyer :  item.buyerNr, Lot: item.lot, Auto: item.car, 'VIN#': item.vin };
          if( this.enabledColumns.clientId) { row.Consignee = item.consignee; }
          if( this.enabledColumns.client) { row.Client = item.client; }
          if( this.enabledColumns.color) { row.Color = item.color; }
          if( this.enabledColumns.containers) { row.Container = item.containerNr; }
          if( this.enabledColumns.auctionPrice) { row.Auction_price = item.auctionPrice; }
          if( this.enabledColumns.buyerFee) { row.Buyer_fee = item.buyerFee; }
          if( this.enabledColumns.storageFee) { row.Storage_fee = item.storageFee; }
          if( this.enabledColumns.orderId) { row.Order_id = item.orderId; }
          if( this.enabledColumns.carrier) { row.Carrier = item.carrier; }
          if( this.enabledColumns.deliveryPrice) { row.Delivery_price = item.deliveryPrice; }
          if( this.enabledColumns.buyerPay) { row.Delivery_rate = item.buyerPay; }
          if( this.enabledColumns.containerPrice) { row.Container_price = item.containerPrice; }
          if( this.enabledColumns.miscFee) { row.Misc_fee = item.miscFee; }
          if( this.enabledColumns.subtotal) { row.Subtotal = item.orderTotal; }
          row.Yard = item.yard;
          expArr.push(row);
          }
        );

        import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(expArr);
          worksheet['!cols']=[ {wch: 10}];
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, 'orders');
        });

      });

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  toggleGlobalFilter(ev, opgf ){
    this.auctionService.getAuctionAcc()
      .then( lst => {
        this.auctionAccList = lst;
        opgf.toggle(ev);
      });
  }

  globalFilterApply(event, opgf){
    this.onGlobalFilterChange();
    opgf.hide(event);
  }

  globalFilterCancel(event, opgf){
    if( this.globalConsigneeFilter !=='' ||
        this.globalAuctionFilter !=='' ||
        this.globalAuctionAccFilter !== '' ||
        this.globalNoDocFilter !=='' ||
        this.globalArchivedFilter !==false
        ) {

      this.globalConsigneeFilter='';
      this.globalAuctionFilter='';
      this.globalAuctionAccFilter='';
      this.globalNoDocFilter='';
      this.globalArchivedFilter=false;
      this.onGlobalFilterChange();
    }
    opgf.hide(event);
  }

  onGlobalFilterChange(){
    if(this.globalConsigneeFilter!=='') {
      this.orderFilter.globalConsignee = this.globalConsigneeFilter;
    } else {
      this.orderFilter.globalConsignee = undefined;
    }
    if(this.globalAuctionFilter!=='') {
      this.orderFilter.globalAuction = this.globalAuctionFilter;
    } else {
      this.orderFilter.globalAuction = undefined;
    }
    if(this.globalAuctionAccFilter!=='') {
      this.orderFilter.globalAuctionAcc = this.globalAuctionAccFilter;
    } else {
      this.orderFilter.globalAuctionAcc = undefined;
    }
    if(this.globalNoDocFilter!=='') {
      this.orderFilter.globalNoDoc = this.globalNoDocFilter;
    } else {
      this.orderFilter.globalNoDoc = undefined;
    }
    if(this.globalArchivedFilter!==false) {
      this.orderFilter.globalArchived = true;
    } else {
      this.orderFilter.globalArchived = undefined;
    }
    const ev :LazyLoadEvent = {first: 0,  rows: 50 };
    this.loadOrdersLazy(ev);
  }

  onFilter(ev) {
   // this.orderFilter.colFilters = ev.filters;
  }

  copyImageToClipboard(event, index, ordCmp: OrderViewComponent) {
    event.stopPropagation();
    event.preventDefault();
    import('copy-image-clipboard').then(cpImg => {
      const img = ordCmp.galleryImages[index];
      let file = img.big.toString();
      file = file.replace('&size=big','');
      file = file.replace('?crossorigin','');

      this.messageService.add({severity: 'info', summary: 'Picture copyed to clipbord!'
        , detail: decodeURIComponent(file)});

      file.replace('https://transbaltica.com/','');
     // file = encodeURIComponent(file);
      cpImg.default( file );
    })
  }

  onShowHistory( order: Order ) {
    this.historyList = [];
    this.displayHistDialog = true;
    this.loadingHist = true;
    this.historyService.getAll(1, order.id)
      .then( lst=> {
        this.loadingHist = false;
        this.historyList = lst;
      } );
  }

  async loadOrdersLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.orderFilter.limit_start = event.first;
    this.orderFilter.limit_count = event.rows;
    this.orderFilter.colFilters = event.filters;
    await this.orderService.getOrdersInfo(this.orderFilter)
    .then( info => {
      this.outstandingBalance = info.balance;
      this.orderService.getAll(this.orderFilter).toPromise().then(orders => {
        orders.forEach (
          item => { item = this.processOrder(item);  }
        );
        if(Number(this.totalRecords) > Number(info.row_count)) {
          this.orders.splice(Number(info.row_count));
        }
        this.totalRecords = Number(info.row_count);
        this.orders.splice(event.first, event.rows, ...orders);
        this.orders = [...this.orders];
        this.loading = false;
      });
    });

  }

  invoiceView(event, order) {
    event.stopPropagation();
    event.preventDefault();
    let url = `${environment.apiUrl}/api/invoices.php`;
    url = url + '?cmd=invoice_show&uuid='+order.invUUID;
    window.open(url, '_blank', 'noopener');
  }

  clipboardEnable() {
    if(this.clipbEnabled) {
      this.clipbEnabled = false;
      this.selectedOrders = [];
      this.selectedOrdersId = [];
    } else {
      this.clipbEnabled = true;
    }
  }



  onClipbCopy(event) {
    this.selectedOrders = [];
    this.selectedOrdersId.forEach(ordId => {
      const tOrd = this.orders.find(ord => Number(ord.id) === Number(ordId));
      if(tOrd)this.selectedOrders.push(tOrd);
    });

    this.changeDetectorRef.detectChanges();
    const el = this.tblCbView.nativeElement;

    const data = new Blob([el.innerHTML], {type: 'text/html'})
    const item = [new ClipboardItem({'text/html': data})];
    navigator.clipboard.write(item).then();

    this.clipboardEnable();
  }


}

