import { SettingsEditComponent } from './../settingsedit/settingsedit.component';
import { cloneDeep } from 'lodash';
import { DocFile } from './../../models/docfile';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocFileService } from '@app/services/docfile.service';
import { ConfirmationService, MessageService, SelectItem, MenuItem } from 'primeng/api';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-docedit',
  templateUrl: './docedit.component.html',
  styleUrls: ['./docedit.component.css']
})
export class DocEditComponent implements OnInit {
  @Output() listChanged = new EventEmitter<any>();
  @Input() docList: DocFile[];
  showDocEdDlg = false;
  moduleId: number;
  subId: number;
  selectedFile: string;
  fileURL = `${environment.apiUrl}/api/docfiles.php` + '?download_file=';
  tmpDocFile: DocFile;
  dispDocTypes: SelectItem[];
  ordDocTypes: SelectItem[];
  contDocTypes: SelectItem[];
  docTypes: SelectItem[];
  displayDialog: boolean;
  docTypeMenu: MenuItem[];
  selDocType: string;
  editByClient: boolean;

  constructor(
    private docService: DocFileService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.editByClient = false;
    this.docList = null;
    this.moduleId = 0;
    this.subId = 0;
    this.displayDialog = false;
    this.tmpDocFile = null;
    this.docTypeMenu = null;
    this.selDocType = null;
    this.ordDocTypes = [
      {label: '', value: ''},
      {label: 'Title', value: 'Title'},
      {label: 'BOS (Bill of Sale)', value: 'BOS (Bill of Sale)'},
      {label: 'Lean release', value: 'Lean release'},
      {label: 'Additional docs.', value: 'Additional docs.'}   ];
    this.contDocTypes = [
      {label: '', value: ''},
      {label: 'Master B/L (Bill of Landing)', value: 'Master B/L (Bill of Landing)'},
      {label: 'Validated Titles', value: 'Validated Titles'},
      {label: 'Additional docs.', value: 'Additional docs.'},
      {label: 'Container Release', value: 'Container Release'},
    ];
    this.dispDocTypes = [
      {label: '', value: ''},
      {label: 'B/L (Bill of Landing)', value: 'B/L (Bill of Landing)'},
      {label: 'Additional docs.', value: 'Additional docs.'},
    ];

  }

  show(mId: number, sId: number) {
    this.moduleId = mId;
    this.subId = sId;
    this.docService.getFileList( mId, sId )
      .subscribe( list => {this.docList = list; });
    this.displayDialog = true;
    if(Number(this.moduleId)===3) { // 3-order, 2- container, 1-contact
      this.docTypeMenu = [
        {label: 'Title', command: () => { this.selDocType = 'Title';  }},
        {label: 'BOS (Bill of Sale)', command: () => { this.selDocType = 'BOS (Bill of Sale)';   }},
        {label: 'Lean release', command: () => {  this.selDocType = 'Lean release';  }},
        {label: 'Additional docs.', command: () => {  this.selDocType = 'Additional docs.';  }},
      ]
    }else if(Number(this.moduleId)===4){
      this.docTypeMenu = [
        {label: 'B/L (Bill of Landing)', command: () => {  this.selDocType = 'B/L (Bill of Landing)';  }},
        {label: 'Additional docs', command: () => {   this.selDocType = 'Additional docs'; }},
      ]
    } else {
      this.docTypeMenu = [
        {label: 'Master B/L (Bill of Landing)', command: () => {  this.selDocType = 'Master B/L (Bill of Landing)';  }},
        {label: 'Validated Titles', command: () => {  this.selDocType = 'Validated Titles';  }},
        {label: 'Additional docs', command: () => {   this.selDocType = 'Additional docs'; }},
      ]
    }
  }

  docUploader( event,  ctrl) {
    event.files.forEach(file => {
      this.docService.uploadFile(file, this.moduleId, this.subId, this.selDocType)
      .subscribe( resp => {
        this.listChanged.emit(this.subId);
        this.selDocType = null;
        this.messageService.add({ severity: 'info', summary: 'File Uploaded!', detail: file.name});
      });
    });
    ctrl.clear();
  }

  onDeleteDocumentEd( docFile) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this document?',
      accept: () => { this.deleteDocument(docFile); }
    });
  }

  deleteDocument( doc) {
    this.docService.deleteFile(doc.id).
    subscribe( () => {
      this.listChanged.emit(this.subId );
      this.messageService.add({ severity: 'info', summary: 'File deleted!', detail: ''});
    });
  }

  onEditDocument(doc: DocFile){
    this.tmpDocFile = cloneDeep( doc);
    if(Number(this.tmpDocFile.moduleId)===3) { // 3-order, 2- container, 4-dispatch
      this.docTypes = this.ordDocTypes;
    } else if(Number(this.tmpDocFile.moduleId)===4) {
      this.docTypes = this.dispDocTypes;
    } else
      this.docTypes = this.contDocTypes;

    this.showDocEdDlg = true;
  }

  onSaveDocEdit() {
    this.docService.updateFile(this.tmpDocFile, this.tmpDocFile.id)
    .toPromise().then( () => {
      this.listChanged.emit(this.subId );
      this.showDocEdDlg = false;
    });
  }

  setEditByClient( byClient: boolean) {
    this.editByClient = byClient;
  }

  getModuleId(): number {
    return this.moduleId;
  }

}
