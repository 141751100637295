<div >
  <p-table #tblCtrObj [value]="containerList"   [loading]="loading"  [autoLayout]="true"
  [scrollable]="true" scrollHeight="60vh"   columnResizeMode="expand" dataKey="id" rowHover="true">
    <ng-template pTemplate="caption">
      <div class="box2" [style.width.%]="40">
        <div *ngIf="editingEnabled">
          <button *ngIf="!clipbEnabled" type="button" pButton icon="pi pi-plus" (click)="onNewContainer()" ></button>
          <button type="button" pButton pRipple class="p-button-success p-mr-2" (click)="clipboardEnable()"
          style="margin-left: 10px;"  icon="far fa-clipboard" pTooltip="Copy/Paste" tooltipPosition="bottom"></button>
        </div>
      </div>
      <div class="box2" [style.width.%]="50">
        <div class="cap-hdr"><i style="margin-right: 20px;" class="fas fa-fw fa-ship"></i>Containers</div>
      </div>
      <div class="box2" [style.width.%]="10" style="text-align: right;" >
        <button type="button" pButton pRipple icon="pi pi-filter" *ngIf="currentUser.type===c.USER_TYPE_ADMIN ||
             currentUser.type===c.USER_TYPE_MANAGER"
          (click)="toggleGlobalFilter($event, opgf)" class="p-button-outlined "
            [ngClass]="( globalNoDocFilter=='') ? 'p-button-secondary' : 'p-button-danger' "
            style="margin-right: 10px;" pTooltip="Advanced filter" tooltipPosition="bottom"></button>
      </div>

    </ng-template>
    <ng-template pTemplate="header">
        <tr>
          <th style="flex: 0 0 45px;" >
            <a *ngIf="!clipbEnabled" href="#" (click)="onToggleCntSearchBar(tblCtrObj, $event)" ><i class="pi pi-search"></i></a>
          </th>
          <th style="flex: 0 0 120px;">Load date</th>
          <th>Booking</th>
          <th style="flex: 0 0 155px;">
            <span style="padding-right: 10px;"  *ngIf="editingEnabled">
              <p-checkbox [(ngModel)]="selectionEnabled" [binary]="true"></p-checkbox></span><span>Container</span></th>
          <th style="flex: 0 0 70px;">Size</th>
          <th style="flex: 0 0 100px;" *ngIf="enabledColumns.price">Price</th>
          <th style="flex: 0 0 46px; text-align: center;"><i class="fas fa-car"></i></th>
          <th style="flex: 0 0 46px; text-align: center;"><i class="far fa-sticky-note"></i></th>
          <th style="flex: 0 0 46px; text-align: center;"><i class="far fa-folder"></i></th>
          <th >Shipping line</th>
          <th style="flex: 0 0 120px;">Origin</th>
          <th >Destination</th>
          <th >Consignee</th>
          <th style="flex: 0 0 170px;" *ngIf="enabledColumns.tracking">Tracking</th>
          <th style="flex: 0 0 140px;" *ngIf="enabledColumns.payment">Payment</th>
        </tr>
        <tr *ngIf="searchBar">
          <th style="flex: 0 0 45px;"></th>
          <th style="flex: 0 0 120px;"></th>
          <th><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'bookingNr', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 155px;"><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'containerNr', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 70px;"></th>
          <th style="flex: 0 0 100px;" *ngIf="enabledColumns.price"></th>
          <th style="flex: 0 0 46px;"></th>
          <th style="flex: 0 0 46px;"></th>
          <th style="flex: 0 0 46px;"></th>
          <th><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'shippingLine', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 120px;"><p-dropdown [options]="originList" (onChange)="tblCtrObj.filter($event.value, 'origin', 'equals')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" appendTo="body"></p-dropdown></th>
          <th><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'destination', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'destinationAgent', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 170px;" *ngIf="enabledColumns.tracking">
            <input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'tracking', 'contains')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th style="flex: 0 0 140px;" *ngIf="enabledColumns.payment">
            <p-dropdown [options]="pmTypeList" (onChange)="tblCtrObj.filter($event.value, 'payment', 'equals')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" appendTo="body"></p-dropdown>
          </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData  let-expanded="expanded">
        <tr class="ui-selectable-row ui-tr" #row [attr.id]="rowData.id" (click)="expandClick(rowData, tblCtrObj)">
            <td style="text-align: center; flex: 0 0 45px;">
              <div *ngIf="!clipbEnabled"><a href="#" (click)="onMenuShow($event, menu, rowData)"><i class="fas fa-bars"></i></a></div>
              <div *ngIf="clipbEnabled"><p-checkbox [(ngModel)]="selectedContainerId"
                name="cargroup2" value="{{rowData.id}}" (click)="onCBSelClick($event)"></p-checkbox></div>
             </td>

            <td style="flex: 0 0 120px;">{{rowData.loadDate | date:"MM/dd/yy"}}</td>
            <td ><span [style]="(rowData.bookingNr==null || rowData.bookingNr.toLowerCase().includes('pending'))? {'color': 'grey'}:{}">
              {{rowData.bookingNr}}</span></td>
            <td style="flex: 0 0 155px;"><span *ngIf="selectionEnabled" style="padding-right: 10px;">
                <p-checkbox name="controup" value="{{rowData.id}}" [(ngModel)]="selectedList"
                [disabled]="rowData.containerNr.toLowerCase().includes('cons')"></p-checkbox>
              </span>
              <span [style]="(rowData.containerNr==null || rowData.containerNr.toLowerCase().includes('cons'))? {'color': 'grey'}:{}">
                {{rowData.containerNr}}</span></td>
            <td style="flex: 0 0 70px;">{{rowData.size}}</td>
            <td style="flex: 0 0 100px;" *ngIf="enabledColumns.price">{{rowData.price  | currency: 'USD' }}</td>
            <td style="flex: 0 0 46px; text-align: center;">{{rowData.carCount}}</td>
            <td style="flex: 0 0 46px; text-align: center;">
              <a href="#" *ngIf="rowData.note" (click)="toggleOverlay($event, op,  rowData.note)"><i class="far fa-sticky-note"></i></a>
            </td>
            <td style="flex: 0 0 46px; text-align: center;">
              <a href="#" *ngIf="rowData.docCount>0" (click)="showDocViewPanel($event,  rowData.id)"><i class="far fa-file-alt"></i></a>
            </td>
            <td>{{rowData.shippingLine}}<span *ngIf="rowData.forwarder"> - {{rowData.forwarder}}</span></td>
            <td  style="flex: 0 0 120px;">{{rowData.origin}}</td>
            <td>{{rowData.destination}}</td>
            <td>{{rowData.destinationAgent}}</td>
            <td style="flex: 0 0 170px;" *ngIf="enabledColumns.tracking">{{rowData.tracking}}
              <span *ngIf="rowData.tracking"><a href="{{containerTrackURL+rowData.tracking}}" target="_blank" style="float: right;" >
                <i class="fa fa-external-link" aria-hidden="true"></i></a></span>
              </td>
            <td style="flex: 0 0 140px; text-align: center;" [ngSwitch]="rowData.payment" *ngIf="enabledColumns.payment">
              <div *ngSwitchCase="0" class="payment pm-unpaid">UNPAID <a href="#" (click)="loadStoredEmail($event, rowData.emailId)" ><i [style]="rowData.emailId? '':'display: none;'"
                style="float:right;" class="far fa-envelope" ></i></a></div>
              <div *ngSwitchCase="1" class="payment pm-paid">PAID <a href="#" (click)="loadStoredEmail($event,rowData.emailId)"><i [style]="rowData.emailId? '':'display: none;'"
                style="float:right;" class="far fa-envelope" ></i></a></div>
              <div *ngSwitchCase="2" class="payment pm-released">RELEASED <a href="#" (click)="loadStoredEmail($event, rowData.emailId)"><i [style]="rowData.emailId? '':'display: none;'"
                style="float:right;" class="far fa-envelope" ></i></a></div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData >
      <tr>
        <td [attr.colspan]="colSpan" style="background-color:lightgrey" >
          <table>
            <tr>
              <td style="vertical-align: top; flex: 0 0  1080px;">
                <div class="box2" style="margin-left: 20px; width: 1065px;">
                  <div class="card card-w-title" style="padding: 5px;" >
                      <div class="bl-hdr">Container cars<i *ngIf="currentUser.type !== this.c.USER_TYPE_YARD" (click)="onEditContCars(rowData)"
                        class="fas fa-edit bl-hdr-ic"  ></i></div>
                      <div>
                        <p-table dataKey="id" [value]="contCars" [style.width.%]="100" rowHover="true" [rows]='5'>
                          <ng-template pTemplate="header">
                            <tr>
                              <th style="flex: 0 0 100px;">Date</th>
                              <th style="flex: 0 0 150px;">Location</th>
                              <th style="flex: 0 0 80px;">Buyer</th>
                              <th style="flex: 0 0 100px;">Lot</th>
                              <th style="flex: 0 0 60px;" *ngIf="currentUser.yard=='NY'" >Tag</th>
                              <th style="flex: 0 0 180px;">Vehicle</th>
                              <th style="flex: 0 0 180px;">VIN</th>
                              <th style="flex: 0 0 100px;">Value</th>
                              <th style="flex: 0 0 120px;">Client ID</th>
                              <th style="flex: 0 0 45px; text-align: center;"><i class="fas fa-camera"></i></th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                            <tr class="ui-selectable-row ui-tr" *ngIf="rowData.status!=='delete'">
                              <td style="flex: 0 0 100px;">{{rowData.date}}</td>
                              <td style="flex: 0 0 150px;">{{rowData.location}}</td>
                              <td style="flex: 0 0 80px;">{{rowData.buyerNr}}</td>
                              <td style="flex: 0 0 100px;">{{rowData.lot}}</td>
                              <td style="flex: 0 0 60px;" *ngIf="currentUser.yard=='NY'"><div *ngIf="rowData.lotTag" style="width: 30px; padding: 3px; text-align: center;"
                                [style]="rowData.tagStyle">{{rowData.lotTag}}</div></td>
                              <td style="flex: 0 0 180px;">{{rowData.year + ' ' +rowData.model}}</td>
                              <td style="flex: 0 0 180px;">{{rowData.vin}}</td>
                              <td style="flex: 0 0 100px;">{{rowData.auctionPrice | currency:'USD'}}</td>
                              <td style="flex: 0 0 120px;">{{rowData.consignee}}</td>
                              <td style="flex: 0 0 45px;"> <span *ngIf="(rowData.photoCnt > 0)" class="uni-symb">✔</span>  </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                  </div>
                </div>
              </td>
              <td style="vertical-align: top;" *ngIf="selConsignee">
                <div class="box2" style="margin-left: 20px; width: 400px;">
                  <div class="card card-w-title" style="padding: 5px;" >
                    <div class="bl-hdr">Consignee contact info</div>
                    <div class="zbrcont">
                      <div><div class="box2 bl-col1">Company:</div><div class="box2 ellipsis" style="width: 240px;">{{selConsignee.company}}&nbsp;</div></div>
                      <div><div class="box2 bl-col1">Address:</div><div class="box2">
                        <div>{{selConsignee.address1}}</div>
                        <div *ngIf="selConsignee.address2">{{selConsignee.address2}}</div>
                        <div>{{selConsignee.city}}<span [style.margin-left.px]="10">{{selConsignee.zip}}</span>
                        <span *ngIf="selConsignee.state">,&nbsp;{{selConsignee.state}}</span></div>

                        <div class="country-item">
                          <img src="https://transbaltica.com/assets/images/flag_placeholder.png" [class]="'flag flag-' + selConsignee.countryCode.toLowerCase()"
                            *ngIf="selConsignee.country" />
                          <div>{{selConsignee.country}}</div>
                        </div>

                      </div></div>

                      <div *ngIf="selConsignee.phone1"><div class="box2 bl-col1">Phone 1:</div><div class="box2">
                        <span style="width: 220px; display: inline-block;">{{selConsignee.phone1}}</span>
                        <span  style="padding-left: 5px;">
                        <a href="tel:{{selConsignee.phone1}}" ><i style="width: 20px; text-align: center;" class="fas fa-mobile-alt"></i></a>
                        </span></div></div>
                      <div *ngIf="selConsignee.phone2"><div class="box2 bl-col1">Phone 2:</div><div class="box2">
                        <span style="width: 220px; display: inline-block;">{{selConsignee.phone2}}</span>
                        <span style="padding-left: 5px;">
                        <a href="tel:{{selConsignee.phone2}}" ><i style="width: 20px; text-align: center;" class="fas fa-mobile-alt"></i></a>
                        </span></div></div>
                      <div *ngIf="selConsignee.email"><div class="box2 bl-col1">Email:</div><div class="box2">
                        <span class="ellipsis" style="width: 220px;">{{selConsignee.email}}</span>
                        <span style="padding-left: 5px;">
                        <a href="mailto:{{selConsignee.email}}"  ><i style="width: 20px; text-align: center;"  class="fas fa-at"></i></a>
                        </span></div></div>

                      <div *ngIf="selConsignee.note"><div class="box2 bl-col1">Note:</div>
                        <div class="box2" style="max-height: 150px;  overflow-y: auto; white-space: pre-wrap;">{{selConsignee.note}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary" *ngIf="editingEnabled">
      <button *ngIf="!clipbEnabled" type="button" pButton icon="pi pi-plus" (click)="onNewContainer()" label="&nbsp;Add new container" style="margin-right: 10px;"></button>
      <p-splitButton *ngIf="selectedList.length!==0 && !clipbEnabled" label="&nbsp;Payment" icon="far fa-money-bill-alt" (onClick)="onPayment()"
         [model]="paymentItems"></p-splitButton>
      <button *ngIf="clipbEnabled" type="button" pButton icon="far fa-clipboard" (click)="onClipbCopy($event)" label="&nbsp;Copy selected" style="margin-right: 10px;"></button>
    </ng-template>
</p-table>

<p-overlayPanel #opgf [dismissable]="false" >
  <ng-template pTemplate>
    <div class="box2" style="width: 380px" >
      <div class="card card-w-title" style="padding: 2px;">
        <div class="bl-hdr">Advanced filter</div>
        <div><div class="box2 bl-col1" style="vertical-align: middle;  padding-left: 5px;">No document:</div><div class="box2">
          <p-dropdown [options]="gfDocTypes" [(ngModel)]="globalNoDocFilter" appendTo="body"
            placeholder="Select..." [style]="{'width':'100%'}"></p-dropdown>
        </div></div>
        <div><hr></div>
          <div style="text-align: center;">
            <button type="button" pButton pRipple icon="pi pi-filter"  (click)="globalFilterApply($event, opgf)"
              class="p-button-outlined" style="margin-right: 10px;" label="Apply" ></button>
            <button type="button" pButton pRipple icon="pi pi-times"  (click)="globalFilterCancel($event, opgf)"
              class="p-button-outlined" style="margin-right: 10px;" label="Clear"></button>
          </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>

<p-dialog  [(visible)]="displayDocDialog" [focusOnShow]="false"  showEffect="false" [modal]="true" [style]="{width: '600px'}">
  <p-header >
    <div class="box2" [style.width.px]="220"></div>
    <div class="box2" [style.width.px]="250"><span>Document list</span></div>
  </p-header>
  <app-doclist [docList]="docList"></app-doclist>
</p-dialog>

<p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" >
  <ng-template pTemplate >
    <div [style.max-width.px]="400" >{{this.overlayText}}</div>
  </ng-template>
</p-overlayPanel>

<app-containeredit (newContainer)="onContainerNewSave($event)" (refreshContainer)="onUpdateContainerList()"
  (updateContainer)="onContainerUpdateSave($event)"></app-containeredit>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<app-emailbody-cont-tpl style="display: none;"></app-emailbody-cont-tpl>

<div #tblCbView *ngIf="selectedContainers.length > 0">
  <table style=" border-collapse: collapse; border: 1px solid #999; padding: 0.5rem;  text-align: left;">
    <thead>
      <tr style="background-color: #DDD;">
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 120px;">Load date</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">Booking</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 155px;"><span>Container</span></th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 70px;">Size</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 100px;" *ngIf="enabledColumns.price">Price</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 46px; text-align: center;">Cars</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">Shipping line</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 120px;">Origin</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">Destination</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">Consignee</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 170px;" *ngIf="enabledColumns.tracking">Tracking</th>
        <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 140px;" *ngIf="enabledColumns.payment">Payment</th>
      </tr>
    </thead><tbody>
      <ng-template ngFor let-rowData [ngForOf]="selectedContainers" let-i="index">
        <tr >
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 120px;">{{rowData.loadDate | date:"MM/dd/yy"}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">
          <span [style]="(rowData.bookingNr==null || rowData.bookingNr.toLowerCase().includes('pending'))? {'color': 'grey'}:{}">
            {{rowData.bookingNr}}</span></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 155px;">
          <span [style]="(rowData.containerNr==null || rowData.containerNr.toLowerCase().includes('cons'))? {'color': 'grey'}:{}">
            {{rowData.containerNr}}</span></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 70px;">{{rowData.size}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 100px;" *ngIf="enabledColumns.price">{{rowData.price  | currency: 'USD' }}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 46px; text-align: center;">{{rowData.carCount}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">{{rowData.shippingLine}}<span *ngIf="rowData.forwarder"> - {{rowData.forwarder}}</span></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 120px;">{{rowData.origin}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">{{rowData.destination}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; ">{{rowData.destinationAgent}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 170px;" *ngIf="enabledColumns.tracking">{{rowData.tracking}}
          <span *ngIf="rowData.tracking"><a href="{{containerTrackURL+rowData.tracking}}" target="_blank" style="float: right;" >
            <i class="fa fa-external-link" aria-hidden="true"></i></a></span>
          </td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 140px; text-align: center;" [ngSwitch]="rowData.payment" *ngIf="enabledColumns.payment">
          <div *ngSwitchCase="0" class="payment pm-unpaid">UNPAID </div>
          <div *ngSwitchCase="1" class="payment pm-paid">PAID </div>
          <div *ngSwitchCase="2" class="payment pm-released">RELEASED </div>
        </td>
      </tr>
      <tr>
        <td colspan="12" >
          <div style="padding: 10px; background-color: #CDD; ">
          <div style="display: inline-block;">
            <table style=" border-collapse: collapse; border: 1px solid  #999; padding: 0.5rem;  text-align: left;">
              <thead>
                <tr style="background-color: #218a94; color:#FFF; text-align: left;">
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 100px;">Date</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 150px;">Location</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 80px;">Buyer</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 100px;">Lot</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 180px;">Vehicle</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 180px; text-align: center;">VIN</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 100px;">Value</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 120px;">Client ID</th>
                  <th style="border: 1px solid #999; padding: 0.5rem; width: 45px;">Foto</th>
                </tr>
              </thead><tbody>
                <ng-template ngFor let-rowCars [ngForOf]="contCarList[i]"  >
                  <tr style="background-color: #FFF; text-align: left;" >
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 100px;">{{rowCars.date}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 150px;">{{rowCars.location}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 80px;">{{rowCars.buyerNr}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 100px;">{{rowCars.lot}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 180px;">{{rowCars.year + ' ' +rowCars.model}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 180px;">{{rowCars.vin}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 100px;">{{rowCars.auctionPrice | currency:'USD'}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; width: 120px;">{{rowCars.consignee}}</td>
                    <td style="border: 1px solid #999; padding: 0.5rem; text-align: center; width: 45px;">
                      <span *ngIf="rowCars.photoCnt>0 "><b>+</b></span>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
            </div>
            <div style="margin-top: 10px; border: 1px solid #FFF; vertical-align: top; display: table-cell;">
              <div style="background-color: #218a94; font-weight: bold; text-align: center; padding: 0.5rem; color: #FFF;">Consignee contact info</div>

              <div style="display: table-cell; background-color: #FFF" height="100px">
                <div ><div style="vertical-align: top; padding: 0.5rem; font-weight: 700;">Company:</div>
                <div style="padding: 0.5rem;" >{{rowData.consObj.company}}&nbsp;</div></div>
              </div>

              <div style="display: table-cell; background-color: #FFF;" >
                <div ><div style="vertical-align: top; padding: 0.5rem; font-weight: 700;">Address:</div>
                  <div style="padding: 0.5rem;">
                    <div>{{rowData.consObj.address1}}</div>
                    <div *ngIf="rowData.consObj.address2">{{rowData.consObj.address2}}</div>
                    <div>{{rowData.consObj.city}}<span style="margin-left: 10px;">{{rowData.consObj.zip}}</span>
                    <span *ngIf="rowData.consObj.state">,&nbsp;{{rowData.consObj.state}}</span></div>
                      <div>{{rowData.consObj.country}}</div>
                    </div>
                </div>
              </div>

              <div style="display: table-cell; background-color: #FFF;">
                <div *ngIf="rowData.consObj.phone1">
                  <div style="vertical-align: top; padding: 0.5rem; font-weight: 700;">Phone 1:</div>
                  <div style="padding: 0.5rem;">{{rowData.consObj.phone1}}</div>
                </div>
              </div>
              <div style="display: table-cell; background-color: #FFF;" >
                <div *ngIf="rowData.consObj.phone2">
                  <div style="vertical-align: top; padding: 0.5rem; font-weight: 700;">Phone 2:</div>
                  <div style="padding: 0.5rem;">{{rowData.consObj.phone2}}</div>
                </div>
              </div>
              <div style="display: table-cell; background-color: #FFF;">
                <div *ngIf="rowData.consObj.email">
                  <div style="vertical-align: top; padding: 0.5rem; font-weight: 700;">Email:</div>
                  <div style="padding: 0.5rem;">{{rowData.consObj.email}}</div>
                </div>
              </div>
              <div style="display: table-cell; background-color: #FFF;">
                <div *ngIf="rowData.consObj.note"><div style="vertical-align: top; padding: 0.5rem; font-weight: 700;">Note:</div>
                  <div style="max-height: 150px;  overflow-y: auto; white-space: pre-wrap;">{{rowData.consObj.note}}</div>
                </div>
              </div>

            </div>
        </div>
        </td>
      </tr>
    </ng-template>
    </tbody>
  </table>
</div>
