import { SettingsEditComponent } from './components/settingsedit/settingsedit.component';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit, AfterViewInit, NgZone, OnDestroy, ViewChild, Inject} from '@angular/core';
import { MenuService } from '@app/services/menu.service';
import { AuthenticationService } from './services/authentication.service';
import { MyAdapter } from './chat-adapter';
import { ChatAdapter, Message, ParticipantResponse, ChatParticipantType, IChatParticipant, ChatParticipantStatus } from 'ng-chat';
import { ChatService } from './services/chat.service';
import { environment } from '@environments/environment';
import { Observable, Subscription } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { Notice } from './models/notice';
import { NoticeService } from './services/notice.sevice';
import { InterCompNotifier } from './services/notifier.service';
import { UsersService } from './services/user.service';
import { User } from './models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(SettingsEditComponent) settingsEdit: SettingsEditComponent;
  title = 'Transbaltica';
  public menuMode = 'horizontal';
  public menuActive = false;
  public topbarMenuActive = false;
  public chatAdapter: ChatAdapter;
  public userList: User[];
  chatUserId: number;
  innactTimer: number;
  activeTopbarItem: Element;
  menuClick: boolean;
  menuButtonClick: boolean;
  topbarMenuButtonClick: boolean;
  menuHoverActive: boolean;
  showSidebar =  false;
  showRelSidebar =  false;
  showNoticeSidebar =  false;
  inactTimerMax = 60 * 15; // 15min
  timeOutStr: string;
  pingTout = 0;
  subSse: Subscription;
  chatEnabled = false;
  curVer: string;
  notice: Notice;
  private doc = document as Document;
  editorsList: any;

  constructor(
      private mNotifier: InterCompNotifier,
      private menuService: MenuService,
      private noticeService: NoticeService,
      private authenticationService: AuthenticationService,
      private chatService: ChatService,
      private zone: NgZone,
      private primengConfig: PrimeNGConfig,
      private userService: UsersService
  ) {
    this.innactTimer = this.inactTimerMax;
    this.chatUserId = 0;
    this.pingToServer();
    this.chatAdapter = new MyAdapter(chatService);
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.curVer = localStorage.getItem('curVer');
    this.notice = this.noticeService.getNew();
    this.userList = null;
  }

  ngOnDestroy(): void {
    if ( this.subSse ) { this.subSse.unsubscribe(); }
  }

  getMessages(userId: number): Observable<any> {

    return new Observable(
      observer => {
        const source = new EventSource(`${environment.apiUrl}/api/sse.php?userId=` + userId.toString());
        source.onerror = event => {
          this.zone.run(() => { observer.error(event) })
        }
        source.addEventListener('evt_lst', event => {
          this.zone.run(() => {  observer.next(event) })
        });
        source.addEventListener('evt_new', event => {
          this.zone.run(() => {  observer.next(event) })
        });
        source.addEventListener('evt_ieb', event => {
          this.zone.run(() => {  observer.next(event) })
        });

      }
    )
  }

  ngAfterViewInit() {
    setInterval( () => {
      this.checkJWTExpire();
      this.pingTout++;
      if (this.pingTout===10) {
        this.pingTout = 0;
        this.pingToServer();
      }
    }, 1000);
  }

  reloadPage() {
    this.showRelSidebar = false;
    window.location.reload();
  }

  onChatMsgSeen(msgs: Message[]) {
    msgs.forEach( msg => {
      if( Number(msg.toId) === Number(this.chatUserId)) {
        this.chatService.setSeenMessage(msg).toPromise().then();
      }
    });
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.resetInactTimer();
  }

  @HostListener('mouseup', ['$event'])  onEvent(event: MouseEvent) {
    this.resetInactTimer();
  }

  pingToServer() {
    const user = this.authenticationService.currentUserValue;
    const away = this.inactTimerMax - this.innactTimer;
    if (user!==null){ // null if logged out
      if( this.userList === null ) {
        this.userService.getAll()
          .toPromise().then( users => {
          this.userList = users;
        });
      }
      this.chatUserId = user.id;
      this.chatService.pingServer(away, user.id)
      .then( resp => {
        if( this.curVer===null) { // not set. First time
          localStorage.setItem('curVer', resp.ver);
        }
        if( resp.ver !=='') {
          if ( resp.ver !== this.curVer) {
            this.curVer = resp.ver;
            localStorage.setItem('curVer', resp.ver);
            this.showRelSidebar = true;
          }
        }
        if(resp.notice > 0 && !this.showNoticeSidebar) { // check for new notices
          this.loadNoticeData(resp.notice);
          this.showNoticeSidebar = true;
        }
        if(resp.edit_arr) {
          const arg = {type: 'editors', data: resp.edit_arr};
          this.mNotifier.sendNotification(arg);  // send if any obj in editing
        }
      });
      if (!this.subSse) this.subscribeToSse(user.id);
    }
  }

  subscribeToSse( userId: number) {
    this.subSse = this.getMessages(userId).subscribe({
      next: data => {
      //  console.log(data);
        this.processSseEvent(data);
      },
      error: err => {
        console.log(err);
    //    this.subSse.unsubscribe();
   //     this.subSse = null;
      }
    });
  }

  processSseEvent( evt: any) {
    let js: any;
    if(!evt) return;
    switch ( evt.type) {
      case 'evt_lst':
        const partResp: ParticipantResponse[] = [];
        js = JSON.parse(evt.data);
        js.forEach(el => {
          partResp.push( {
            participant: {
              participantType: ChatParticipantType.User,
              id: el.id,
              status: el.status,
              avatar: null,
              displayName: this.userList ? this.userList.find(u => u.id === el.id).name : "" },
            metadata: {  totalUnreadMessages: el.unreadMsg  }
          });
        });
        if( js.length > 0 ) { this.chatEnabled = true; }
        else { this.chatEnabled = false; }
        this.chatAdapter.onFriendsListChanged(partResp);
        break;
      case 'evt_new':
        js = JSON.parse(evt.data);
        const partic: IChatParticipant = {
          participantType: ChatParticipantType.User,
          id: js.fromId,
          status: ChatParticipantStatus.Online,
          avatar: null,
          displayName: this.userList ? this.userList.find(u => u.id === js.fromId).name : ""
        };
        const msg: Message = {
          fromId: js.fromId,
          toId: js.toId,
          message: js.message,
          dateSent: js.dateSent
        };
        this.chatAdapter.onMessageReceived( partic, msg);
        break;
      case 'evt_ieb':
          js = JSON.parse(evt.data);
          const arg = {type: 'editors', data: js};
          this.mNotifier.sendNotification(arg);
        break;
    }
  }

  onPartWinClosed(evt) {
  }

  checkJWTExpire() {
    if ( this.innactTimer && this.isUserAuthenticated()) {
      this.innactTimer--;
      if (this.innactTimer === 0) {
        this.onLogOut();
      }
      if (this.innactTimer < 300) {
        this.showSidebar = true;
      }
    }
    this.timeOutStr = this.transformTimer(this.innactTimer);
  }

  transformTimer(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = (value - minutes * 60);
    return minutes.toString().padStart(2,'0') + ':' + seconds.toString().padStart(2,'0');
  }

  resetInactTimer() {
    this.innactTimer = this.inactTimerMax;
    this.showSidebar = false;
  }

  onMenuButtonClick(event: Event) {
      this.menuButtonClick = true;
      this.menuActive = !this.menuActive;
      event.preventDefault();
  }

  onTopbarMenuButtonClick(event: Event) {
      this.topbarMenuButtonClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      event.preventDefault();
  }

  onTopbarItemClick(event: Event, item: Element) {
      this.topbarMenuButtonClick = true;

      if (this.activeTopbarItem === item) {
        this.activeTopbarItem = null;
      } else {
        this.activeTopbarItem = item;
      }
      event.preventDefault();
  }

  onTopbarSubItemClick(event, item: string) {
      if(item === 'settings' ) {
        const user = this.authenticationService.currentUserValue;
        this.settingsEdit.showDialog(user.id);
      }else
      if(item === 'logout' ) {
        this.onLogOut();
      }
      event.preventDefault();
  }

  onLayoutClick() {
      if (!this.menuButtonClick && !this.menuClick) {
          if (this.menuMode === 'horizontal') {
              this.menuService.reset();
          }

          if (this.isMobile() || this.menuMode === 'overlay' || this.menuMode === 'popup') {
              this.menuActive = false;
          }
          this.menuHoverActive = false;
      }

      if (!this.topbarMenuButtonClick) {
          this.activeTopbarItem = null;
          this.topbarMenuActive = false;
      }

      this.menuButtonClick = false;
      this.menuClick = false;
      this.topbarMenuButtonClick = false;
  }


  onMenuClick() {
      this.menuClick = true;
  }

  isMobile() {
      return window.innerWidth < 1025;
  }

  isHorizontal() {
      return this.menuMode === 'horizontal';
  }

  isTablet() {
      const width = window.innerWidth;
      return width <= 1024 && width > 640;
  }

  onLogOut() {
    const arg = {type: 'logout'};
    this.mNotifier.sendNotification(arg);  // send if any obj in editing
    this.authenticationService.logout();
    window.location.reload();
  }

  getCurrentUser() {
    return this.authenticationService.currentUserValue;
  }

  isUserAuthenticated() {
    return this.authenticationService.isAuthenticated();
  }

  loadNoticeData(id) {
    this.noticeService.getById(id)
      .then(notice => {
      this.notice = notice;
    });
  }

  acceptNotice(id) {
    this.noticeService.accptNotice(id)
      .then(notice => {
      this.notice = notice;
    });
    this.showNoticeSidebar = false;
  }

}
