import { ContainerService } from './../../../services/container.service';
import { Container } from './../../../models/container';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContainerCar } from '@app/models/containerCar';
import { SelectItem } from 'primeng/api/selectitem';
import { Order } from '@app/models';

@Component({
  selector: 'app-inv-contsel',
  templateUrl: './contsel.component.html',
  styleUrls: ['./contsel.component.css']
})
export class ContSelComponent implements OnInit {
  @Output() addToInvoice = new EventEmitter<any[]>();
  container: Container;
  contCars: ContainerCar[];
  containerList: SelectItem[];
  selContainerId: number;
  displayDialog = false;
  loading = false;

  constructor(
    protected containerService: ContainerService
  ) { }

  ngOnInit(): void {
    this.contCars = [];
    this.containerService.getList()
      .then( list => {
        this.containerList = list;
      });

  }

  onContainerChange(event) {
    this.loadConatinerDataById(event.value);
  }

  loadConatinerDataById( contId: number) {
    this.loading = true;
    this.container = null;
    this.selContainerId = contId;
    this.containerService.getById(contId)
      .then( cont => {
        this.container = cont;
      });

    this.containerService.getCarList( contId )
      .then( cars => {
        this.contCars = cars.map(
          el => {
            el.selected = true;
            return el;
        });
        this.loading = false;
      });
  }

  show() {
    this.displayDialog = true;
  }

  onAddToInvoice() {
    const idList = [];
    this.contCars.forEach(
      el => {
        if( el.selected ) { idList.push(el.vin); }
    });
    this.addToInvoice.emit( idList );
    this.displayDialog = false;
  }
}
