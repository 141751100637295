<p-dialog  [(visible)]="displayDialog" [focusOnShow]="true"  showEffect="false" [modal]="true"  [keepInViewport]="true"
 appentTo="body" [style]="{width: '650px'}">
 <p-header >
  <div class="box2" [style.width.px]="220">  </div>
  <div class="box2" [style.width.px]="250"><span>User settings [{{user.username}}]</span></div>
</p-header>
  <div class="box2" style=" width: 100%;">
    <p-tabView  [(activeIndex)]="tabIndex">
      <p-tabPanel header="Contact info" >
        <div class="card card-w-title" style="padding: 5px;" >
          <div class="zbrcont" style="width: 100%;">
            <div><div class="box2 bl-col1">Company:</div><div class="box2">{{contact.company}}</div></div>
            <div><div class="box2 bl-col1">Name:</div><div class="box2">{{contact.name}}</div></div>
            <div><div class="box2 bl-col1">Username:</div><div class="box2">{{user.username}}</div></div>
            <div><div class="box2 bl-col1">Address:</div><div class="box2">{{contact.address1}}</div></div>
            <div><div class="box2 bl-col1">City:</div><div class="box2">{{contact.city}}</div></div>
            <div><div class="box2 bl-col1">State:</div><div class="box2">{{contact.state}}</div></div>
            <div><div class="box2 bl-col1">Country:</div><div class="box2">{{contact.country}}</div></div>
            <div><div class="box2 bl-col1">Zip code:</div><div class="box2">{{contact.zip}}</div></div>
            <div><div class="box2 bl-col1">Phone:</div><div class="box2">{{contact.phone1}}</div></div>
            <div><div class="box2 bl-col1">Email:</div><div class="box2">{{contact.email}}</div></div>
          </div>
        </div>
        <button type="button" pButton icon="far fa-edit" (click)="editContact()" label="Edit"></button>
      </p-tabPanel>
      <p-tabPanel header="Document list" >
        <div class="box2" style="margin-left: 20px; width: 550px;">
          <div class="card card-w-title" style="padding: 5px;" >
            <app-doclist [docList]="docList"></app-doclist>
          </div>
          <button type="button" pButton icon="far fa-edit" (click)="editDocuments()" label="Edit"></button>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Notifications" >
        <div class="zbrcont" >


          <div style="padding: 5px;"><div class="box2 bl-col1" style="width: 90%;">SMS on order status change:</div>
            <div class="box2" style="width: 10%;"><p-inputSwitch [(ngModel)]="user.ordStatusChngSMS"
                (onChange)="onOptionChange($event,'ordStatusChngSMS', user.id)"></p-inputSwitch></div></div>

          <div style="padding: 5px;"><div class="box2 bl-col1" style="width: 90%;">Email on order status 'Loaded':</div>
            <div class="box2" style="width: 10%;"><p-inputSwitch [(ngModel)]="user.ordLoadedEmail"
                  (onChange)="onOptionChange($event,'ordLoadedEmail', user.id)"></p-inputSwitch></div></div>

          <div style="padding: 5px;"><div class="box2 bl-col1" style="width: 90%;">Email on order photos change:</div>
            <div class="box2" style="width: 10%;"><p-inputSwitch [(ngModel)]="user.ordPhotoChngEmail"
                (onChange)="onOptionChange($event,'ordPhotoChngEmail',user.id)"></p-inputSwitch></div></div>

      </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-dialog>
<app-contactedit  (updateContact)="onContactUpdateSave($event)"></app-contactedit>
<app-docedit [docList]="docList"  (listChanged)="onDocListChanged($event)"></app-docedit>
