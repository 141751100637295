
<p-dialog header="New order details" [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'560px'}">
  <div class="p-grid ui-fluid"  dndDropzone [dndAllowExternal]="true"  (dndDrop)="onDrop($event)" >
    <form #orderForm="ngForm" (ngSubmit)="submitOrder(orderForm)"  >
      <div class="box2" [style.width.%]="33">
        <div class="p-col-4"><label for="date">Date</label></div>
        <div class="p-col-8">
          <p-calendar [(ngModel)]="order.date" dateFormat="mm/dd/yy" id="date" name="date"
            class="form-control" [showIcon]="true" [style]="{'width':'100%'}"  required ></p-calendar>
        </div>
      </div>
      <div class="box2" [style.width.%]="34" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="yard">Yard</label></div>
        <div class="p-col-8">
          <p-dropdown [options]="yards" [(ngModel)]="selectedYard" id="yard" name="yard"
            optionLabel="yard" placeholder="Select..." [style]="{'width':'100%'}" required></p-dropdown>
        </div>
      </div>
      <div class="box2" [style.width.%]="33" [style.padding-left.px]="20">
        <div class="p-col-4"> <label for="auctionPrice">Auction price</label> </div>
        <div class="p-col-8">
            <p-inputNumber [(ngModel)]="order.auctionPrice" mode="currency" currency="USD" locale="en-US"
              inputId="auctionPrice" [inputStyle]="{'width':'120px', 'font-weight':'bold'}" name="auctionPrice" appPriceNotZero></p-inputNumber>
        </div>
      </div>

      <div class="box2" [style.width.%]="50" [style.margin-top.px]="5">
        <div class="p-col-4"><label for="auctionId">Auction</label></div>
        <div class="p-col-8">
          <p-dropdown [options]="auctionList" [(ngModel)]="order.auctionId" appendTo="body" id="auctionId" name="auctionId"
            optionLabel="name" optionValue="id" placeholder="Select..." [style]="{'width':'100%'}" required>
            <ng-template let-auction pTemplate="item">
              <div class="country-item">
                <span style="width: 15px; display: inline-block; text-align: center; vertical-align: middle;">
                  <img *ngIf="auction.icon" src="../../../assets/images/{{auction.icon}}"  width="12" height="12"  />
                </span>
                <div style="padding-left: 5px;" >{{auction.name}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="auctionAcc">Auction account</label></div>
        <div class="p-col-8">
          <p-dropdown [options]="auctionAccList" [(ngModel)]="order.auctionAcc" appendTo="body" id="auctionAcc" name="auctionAcc"
          optionLabel="name" optionValue="id" placeholder="Select..." [style]="{'width':'100%'}" required></p-dropdown>
        </div>
      </div>

      <div class="box2" [style.width.%]="50"  [style.margin-top.px]="5">
        <div class="p-col-4"><label for="lot">Lot</label></div>
        <div class="p-col-8">
          <input pInputText id="lot" name="lot" #lot="ngModel" [(ngModel)]="order.lot"
            maxlength="8" minlength="8" class="form-control" [style.width.%]="100" required />
        </div>
      </div>

      <div class="box2" [style.width.%]="50"  [style.padding-left.px]="20">
        <div class="p-col-4"><label for="location">Location</label></div>
        <div class="p-col-8">
            <input pInputText id="location" name="location" [style.width.%]="100" [(ngModel)]="order.location" required/>
        </div>
      </div>

      <div class="box2" [style.width.%]="60"  [style.margin-top.px]="5">
        <div class="p-col-4"><label for="sublot">Sublot</label></div>
        <div class="p-col-8">
          <input pInputText id="sublot" name="sublot" [style.width.%]="100"  [(ngModel)]="order.sublot" />
        </div>
      </div>

      <div class="box2" [style.width.%]="40" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="fuel">Fuel</label></div>
        <div class="p-col-8">
          <p-dropdown [options]="fuelTypes" [(ngModel)]="order.fuel" id="fuel" name="fuel"
             placeholder="Select..." [style]="{'width':'100%'}" required></p-dropdown>
        </div>
      </div>


      <div class="box2" [style.width.%]="15" [style.margin-top.px]="5" >
        <div class="p-col-4"><label for="year">Car year</label></div>
        <div class="p-col-8">
          <input pInputText id="year" name="year"  [style.width.%]="100" [(ngModel)]="order.year"
            style="font-weight: bold;" maxlength="4" minlength="4" required/>
        </div>
      </div>
      <div class="box2" [style.width.%]="85" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="model">Model</label></div>
        <div class="p-col-8" >
            <input pInputText id="model" name="model" [style.width.%]="100"
            style="font-weight: bold;" [(ngModel)]="order.model" required/>
        </div>
      </div>

      <div class="box2" [style.width.%]="60" [style.margin-top.px]="5">
        <div class="p-col-4"><label for="vin">VIN number</label></div>
        <div class="p-col-8">
          <input pInputText id="vin" name="vin" [(ngModel)]="order.vin" style="text-transform: uppercase; font-weight: bold;"
          maxlength="17" minlength="17" [style.width.%]="100" required class="form-control"/>
        </div>
      </div>
      <div class="box2" [style.width.%]="40" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="color">Color</label></div>
        <div class="p-col-8">
          <input pInputText id="color" name="color" [style.width.%]="100" [(ngModel)]="order.color" />
        </div>
      </div>

      <div class="box2" [style.width.%]="50" [style.margin-top.px]="5">
        <div class="p-col-4"><label for="buyerNr">Buyer</label></div>
        <div class="p-col-8">
            <input pInputText id="buyerNr" name="buyerNr" [style.width.%]="100" [(ngModel)]="order.buyerNr" required/>
        </div>
      </div>
      <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="buyerNr">Consignee</label></div>
        <div class="p-col-8">
            <p-dropdown [options]="consigneeList" [(ngModel)]="order.client" id="client" name="client"
               [style]="{'width':'100%'}"  appendTo="body"
               [disabled]="order.consignee=='' || userPrivilege.order_fld_ed_consignee!=='1'" ></p-dropdown>
          </div>
      </div>

      <div class="box2" [style.width.%]="80" [style.margin-top.px]="5">
        <div class="p-col-4"><label for="consignee">Client</label></div>
        <div class="p-col-8">
            <p-dropdown [options]="intContactList" [(ngModel)]="order.consignee" id="consignee" name="consignee"
               [style]="{'width':'100%'}"  appendTo="body" (onChange)="onClientChange($event)" required></p-dropdown>

        </div>
      </div>
      <div class="box2" [style.width.%]="20" [style.margin-top.px]="5" [style.padding-left.px]="20">
        <div class="p-col-4"><label for="paidByClient">Paid by</label></div>
        <div class="p-col-8" >
          <p-checkbox [binary]="true" [(ngModel)]="order.paidByClient" id="paidByClient" name="paidByClient"></p-checkbox>        </div>
      </div>


      <div class="box2" [style.width.px]="20" >
        <div class="p-col-4"><label for="keyFob"><i class="fas fa-key" ></i></label></div>
        <div class="p-col-8"><p-triStateCheckbox [(ngModel)]="order.keyFob" id="keyFob" name="keyFob"></p-triStateCheckbox></div>
      </div>

      <div class="box2" [style.width.px]="20" [style.padding-left.px]="10">
        <div class="p-col-4"><label for="title"><i class="far fa-id-card" ></i></label></div>
        <div class="p-col-8"><p-checkbox id="title" name="title" [(ngModel)]="order.title" [binary]="true"></p-checkbox></div>
      </div>

      <div class="box2" [style.width.%]="90" [style.padding-left.px]="20" [style.margin-top.px]="5">
        <div class="p-col-4"><label for="titleType">Title type</label></div>
        <div class="p-col-8">
          <input pInputText id="titleType" name="titleType" [style]="{'width':'100%'}" [(ngModel)]="order.titleType"/>
        </div>
      </div>
    </form>
    <p-messages [(value)]="errorMsg"></p-messages>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-check" (click)="submitOrder(orderForm); " label="Save"></button>
    </div>
  </p-footer>
  <p-confirmDialog key="orderedit" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>


  </p-dialog>

