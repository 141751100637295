import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appPriceNotZero]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: AppPriceValidateDirective,
    multi: true
  }]
})

export class AppPriceValidateDirective implements Validator {
  validate(control: AbstractControl) : {[key: string]: any} | null {
    if (control.value !== null) {
      if( Number(control.value) < 1 ) {
        return { priceInvalid: true }; // return object if the validation is not passed.
      } else {
        return null;
      }
    }
    return null; // return null if validation is passed.
  }
}
