import { EmailBodyOrderComponent } from '@app/components/emailbody/emailbody.order.component';
import { OrdersService } from './../../services/order.service';
import { EmailService } from '@app/services/email.service';
import { EmailMessage } from '@app/models/email';
import { AfterViewInit, Component,  ElementRef,  OnInit, ViewChild} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Message, MessageService } from 'primeng/api';
import { AuthenticationService } from '@app/services/authentication.service';
import { Order } from '@app/models/order';



@Component({
  selector: 'app-emailedit',
  templateUrl: './emailedit.component.html',
  styleUrls: ['./emailedit.component.css'],
})

export class EmailEditComponent implements OnInit, AfterViewInit {
  @ViewChild('tinyBody') tiny: ElementRef;

  displayDialog = false;
  errorMsg: Message[];
  email: EmailMessage;
  sending = false;
  tinyConfig: any;
  selPhoto: string[] = [];
  bodyObj: any;
  tmpOrder: Order;

  constructor(
    private emailService: EmailService,
    private authUser: AuthenticationService,
    private messageService: MessageService,
    private orderService: OrdersService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig

    ) {
    this.email = {
      id: this.config.data.emailId,
      to: this.config.data.to,
      subject: this.config.data.subject,
      body: this.config.data.body,
      replyTo: null,
      replyName: null,
      userId: 0,
      created: null
    };

    if ( this.config.data.order !== undefined ) {
      this.tmpOrder = this.config.data.order;
    }
    if ( this.config.data.bodyObj !== undefined ) {
      this.bodyObj = this.config.data.bodyObj;
    } else { this.bodyObj = null; }

    this.authUser.currentUser.subscribe(
      user => {
        this.email.replyTo = user.email;
        this.email.replyName = user.name;
        this.email.userId = user.id;
    });
    this.tinyConfig = {
      plugins: 'paste autolink visualblocks visualchars image imagetools link media table charmap hr pagebreak \
                nonbreaking anchor insertdatetime advlist lists textpattern',
      menubar: false,
      readonly: 1,
      invalid_elements : 'a[id]',
      min_height: 550
    };

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if ( this.email.id !== 0) {
      this.tinyConfig.readonly = true;
      this.emailService.loadEmail(this.email.id)
        .then( email => {
          this.email.to = email.to;
          this.email.subject = email.subject;
          this.email.body = email.body;
          this.email.created = email.created;
        });
    }
  }

  photoChanged() {
    let id1 = false;
    let id2 = false;
    let id3 = false;
    this.selPhoto.forEach( p => {
      if (p === 'ph1' ) { id1 = true; }
      else
      if (p === 'ph2' ) { id2 = true; }
      else
      if (p === 'ph3' ) { id3 = true; }
    });
    (this.bodyObj as EmailBodyOrderComponent).setPhotoSel(1,id1);
    (this.bodyObj as EmailBodyOrderComponent).setPhotoSel(2,id2);
    (this.bodyObj as EmailBodyOrderComponent).setPhotoSel(3,id3);
    setTimeout( () => {
      this.email.body = (this.bodyObj as EmailBodyOrderComponent).getHtmlContent();
    }, 500);
  }

  submitEmail(form ) {
    if (form.invalid) {
      this.errorMsg = [{key: 'emailFrmError', severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {
    this.sending = true;
    this.emailService.sendEmail(this.email)
      .toPromise().then( resp => {
        this.sending = false;
        this.messageService.add({ severity: 'info', summary: 'Email sent!', detail:  'Recipient: ' + this.email.to});
        this.ref.close(resp);
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Email not sent!', detail: 'Recipient: ' + this.email.to});
        this.sending = false;
      });
    }
  }
}
