import { SMSMessage } from '@app/models/sms';
import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api/message';
import { SMSService } from '@app/services/sms.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-smsedit',
  templateUrl: './smsedit.component.html',
  styleUrls: ['./smsedit.component.css']
})
export class SMSEditComponent implements OnInit {
  sms: SMSMessage;
  displayDialog = false;
  sending = false;
  errorMsg: Message[];

  constructor(
    private smsService: SMSService,
    private messageService: MessageService,

  ) {   }

  ngOnInit(): void {
    this.sms = {to: null, content: null};
  }

  show( tSMS: SMSMessage) {
    this.sms = tSMS;
    this.displayDialog = true;
  }


  submitSMS(form) {
    if (form.invalid) {
      this.errorMsg = [{key: 'smsFrmError', severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {
      this.sending = false;
      this.smsService.sendSMS(this.sms)
      .toPromise().then( res => {
        const v = (res as any).data;
        const arr = v.split('-');
        arr[0] = arr[0].trim();
        arr[1] = arr[1].trim();
        if(arr[0]==='Success') {
          this.messageService.add({ severity: 'info', summary: 'SMS sent!', detail:  'Id: ' + arr[1]});
          this.displayDialog = false;
        }
      });
    }
  }

}
