  <div class="p-grid ui-fluid"   [style.min-height.vh]="65" >
    <form #emailForm="ngForm" (ngSubmit)="submitEmail(emailForm)">
        <div class="p-field" [style.height.px]="55">
          <div [style.width.%]="50" class="box2">
            <div class="box2" [style.width.%]="10"><b>To:</b></div>
            <div class="box2" [style.width.%]="80"><input pInputText id="to" name="to" [(ngModel)]="email.to" class="form-control"
              placeholder="username@example.com" [style.width.%]="100" required [readonly]="email.id !== 0"/></div>
          </div>
          <div [style.width.%]="50" class="box2">
            <div class="box2" [style.width.%]="15" style="text-align: right; padding-right: 10px;"><b>Subject:</b></div>
            <div class="box2" [style.width.%]="85"><input pInputText id="subject" name="subject" [(ngModel)]="email.subject"
              class="form-control"  [style.width.%]="100" required [readonly]="email.id !== 0"/></div>
          </div>
        </div>
      <div><hr></div>
      <div *ngIf="tmpOrder" class="p-field" [style.height.px]="55">
        <div [style.width.%]="100">
          <div class="box2" [style.width.%]="7"><b>Photo:</b></div>
          <div class="box2" [style.width.%]="43">
            <span style="display: inline-block; width: 130px;">
              <p-checkbox name="phgroup" value="ph1" (onChange)="photoChanged()" [disabled]="tmpOrder.photo1Cnt? true:false"
              label="Auction {{tmpOrder.photo1Cnt? '[' + tmpOrder.photo1Cnt + ']': ''}}" [(ngModel)]="selPhoto"></p-checkbox></span>
            <span style="display: inline-block; width: 130px;">
              <p-checkbox name="phgroup" value="ph2" (onChange)="photoChanged()" [disabled]="tmpOrder.photo2Cnt? true:false"
              label="Yard {{tmpOrder.photo2Cnt? '[' + tmpOrder.photo2Cnt + ']' : ''}}" [(ngModel)]="selPhoto"></p-checkbox></span>
            <span style="display: inline-block; width: 130px;">
              <p-checkbox name="phgroup" value="ph3" (onChange)="photoChanged()" [disabled]="tmpOrder.photo3Cnt? true:false"
              label="Conainer {{tmpOrder.photo3Cnt? '[' + tmpOrder.photo3Cnt + ']': ''}}" [(ngModel)]="selPhoto"></p-checkbox></span>
          </div>
          <div class="box2" [style.width.%]="7"><b>Documents:</b></div>
        </div>
      </div>
      <div class="p-field" [style.width.%]="100">
      <editor #tinyBody name="tinyBody" id="tinyBody"
        apiKey="koy2rgeuh9ozs9jqdtu8s2daprnkcrq9plstkk6y508b3t0e"
        [(ngModel)]="email.body"
        [init]="tinyConfig"
        [disabled]="email.id !== 0"></editor>
      </div>
    </form>
    <p-messages [(value)]="errorMsg" key="emailFrmError"></p-messages>
    <p-progressBar *ngIf="sending" mode="indeterminate" [style]="{'height': '4px'}"></p-progressBar>
    <div style="width: 100%; padding: 5px; height: 45px; background-color: #f4f4f4; margin-top: 10px;"  >
      <button *ngIf="email.id === 0" [disabled]="sending" style="float: right;" type="button" pButton icon="far fa-paper-plane"
        (click)="submitEmail(emailForm); " label="Send email"></button>
      <div *ngIf="email.id !== 0" style="vertical-align: middle; float: right;">Email created: {{email.created | date: 'MM/dd/yyyy'}}</div>
    </div>
  </div>


