<div class="content-section implementation">
  <p-table #tblObj [value]="invoiceList"   [loading]="loading"  [autoLayout]="true" scrollHeight="65vh"
   [scrollable]="true"    columnResizeMode="expand" dataKey="id" rowHover="true">
    <ng-template pTemplate="caption">
      <div class="box2" [style.width.%]="40">
        <button type="button" pButton icon="pi pi-plus" (click)="onNewInvoice()"></button>
      </div>
      <div class="box2" [style.width.%]="60">
        <div class="cap-hdr"><i style="margin-right: 20px;" class="fas fa-fw fa-file-alt"></i>Invoices</div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pColumn="edit"  style="width:45px;">
          <a href="#" (click)="onToggleSearchBar(tblObj, $event)" ><i class="pi pi-search"></i></a>
        </th>
        <th pColumn="date" style="width:120px;">Date</th>
        <th pColumn="number" style="width:150px;">Number</th>
        <th pColumn="Name" style="width:300px;">Name/Company</th>
        <th pColumn="total" style="width:100px;">Total</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" >
      <tr class="ui-selectable-row ui-tr" #row [attr.id]="rowData.id" >
        <td style="width:45px; text-align: center;"><a href="#" (click)="onMenuShow($event, menu, rowData, tblObj)" ><i class="fas fa-bars"></i></a></td>
        <td style="width:120px;">{{rowData.date }}</td>
        <td style="width:150px;">{{rowData.number}}</td>
        <td style="width:300px;"> {{row.useCompany ? rowData.company : rowData.name }}</td>
        <td style="width:100px;">{{rowData.total | currency: 'USD'}}</td>
        <td></td>
      </tr>
    </ng-template>


    <ng-template pTemplate="summary" >
      <div style="text-align:left">
        <button type="button" pButton icon="pi pi-plus" (click)="onNewInvoice()" label="&nbsp;Add new invoice"></button>
      </div>
    </ng-template>
  </p-table>
</div>
<p-slideMenu #menu [popup]="true" [model]="menuItems" viewportHeight="250"></p-slideMenu>
<app-invoiceedit (newInvoice)="afterInvoiceNewSave($event)" (updateInvoice)="afterInvoiceUpdateSave($event)"></app-invoiceedit>

