import { NoticeViewComponent } from './views/noticeview.component';
import { InfobyvinComponent } from './views/infobyvin/infobyvin.component';
import { UserViewComponent, ContainerViewComponent, InvoiceViewComponent, ContactViewComponent } from '@app/views';
import { AuthGuard } from './guards/auth.guard';
import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OrderViewComponent } from '@app/views';

const routes: Routes = [
  { path: '', component: OrderViewComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: OrderViewComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: InvoiceViewComponent, canActivate: [AuthGuard] },
  { path: 'contacts', component: ContactViewComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UserViewComponent, canActivate: [AuthGuard] },
  { path: 'containers', component: ContainerViewComponent, canActivate: [AuthGuard] },
  { path: 'notices', component: NoticeViewComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'infobyvin', component: InfobyvinComponent },
  { path: '**', redirectTo: '' } // otherwise redirect to home
];

@Injectable({
  providedIn: 'root'
})

@NgModule({
  imports: [RouterModule.forRoot(routes , { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
