
import { Component, OnInit } from '@angular/core';
import { Constants } from '@app/models/constants';
import { AppComponent } from './../app.component';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];
    c: any;

    constructor(
      public app: AppComponent,
      ) {
        this.c = Constants;
      }

    ngOnInit() {
        const user = this.app.getCurrentUser();
        if(user.type === this.c.USER_TYPE_TAX){
          this.model = [
            {label: 'Contacts', icon: 'fas fa-users', routerLink: ['/contacts']  }
          ];
          return;
        }
        this.model = [
            {label: 'Orders', icon: 'fas fa-car', routerLink: ['/']  }
        ];
        if (user.type !== this.c.USER_TYPE_CLIENT &&
            user.type !== this.c.USER_TYPE_YARD &&
            user.type !== this.c.USER_TYPE_YARD_F &&
            user.type !== this.c.USER_TYPE_VIEWER
            ) {
          this.model.push( {label: 'Contacts', icon: 'fas fa-users', routerLink: ['/contacts']  } );
          this.model.push( {label: 'Invoices', icon: 'fas fa-file-alt', routerLink: ['/invoices']  });
        }

        if (user.type !== this.c.USER_TYPE_CLIENT &&
            user.type !== this.c.USER_TYPE_YARD_F &&
            user.type !== this.c.USER_TYPE_VIEWER
            ) {
          this.model.push(  {label: 'Containers', icon: 'fas fa-ship', routerLink: ['/containers'] });
        }

        if (user.type === this.c.USER_TYPE_CLIENT ||
            user.type === this.c.USER_TYPE_ADMIN ||
            user.type === this.c.USER_TYPE_MANAGER
          ) {
          this.model.push(  {label: 'Notices', icon: 'fas fa-exclamation-triangle', routerLink: ['/notices'] });
        }

        if (user.type === this.c.USER_TYPE_ADMIN) {
          this.model.push( {label: 'Admin', icon: 'fas fa-lock'  ,
            items: [
              {label: 'Users', icon: 'fas fa-user' , routerLink: ['/users'] },
          ]} );
        }
    }


    onMenuClick() {
        this.app.onMenuClick();
    }
}
