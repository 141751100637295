import { SMSMessage } from '@app/models/sms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })

export class SMSService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json'  })
  };

  constructor(private http: HttpClient) { }

  sendSMS(sms: SMSMessage) {
    const msg =  {cmd: 'sms_send',  obj: sms };
    return this.http.post(`${environment.apiUrl}/api/sms.php`, msg, this.httpOptions );
  }

}

