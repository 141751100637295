import { ContainerService } from './../../services/container.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Contact } from '@app/models/contact';
import { Invoice, InvoiceItem, InvoiceSubData } from '@app/models/invoice';
import { ContactService } from '@app/services/contact.service';
import { InvoiceService } from '@app/services/invoice.service';
import { OrdersService } from '@app/services/order.service';
import { MenuItem, SelectItem } from 'primeng/api';
import { Message } from 'primeng/api/message';
import { ContactEditComponent } from '../contactedit/contactedit.component';
import { ContSelComponent } from './contsel/contsel.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-invoiceedit',
  templateUrl: './invoiceedit.component.html',
  styleUrls: ['./invoiceedit.component.css']
})

export class InvoiceEditComponent implements OnInit {
  @ViewChild(ContactEditComponent) contactEdit: ContactEditComponent;
  @ViewChild(ContSelComponent) contSelComp: ContSelComponent;
  @ViewChild('dt') tblView: Table;
  @Output() newInvoice = new EventEmitter<number>();
  @Output() updateInvoice = new EventEmitter<number>();
  displayDialog = false;
  displayCarEdDialog = false;
  displayTxtDialog = false;
  displayVinDialog = false;
  invoice: Invoice;
  errorMsg: Message[] ;
  contactList: Contact[];
  selectedContact: Contact;
  contactTypes: SelectItem[];
  menuItems: MenuItem[];
  editItem: InvoiceItem;
  tmpInvoice: Invoice;
  selectedVin: string;
  carList: SelectItem[];


  constructor(
    protected contactService: ContactService,
    protected invoiceService: InvoiceService,
    protected containerService: ContainerService,
    protected orderService: OrdersService,

  ) {
    this.selectedContact = null;
    this.selectedVin = null;
    this.contactList = null;
    this.carList = null;
  }

  ngOnInit(): void {
    this.editItem = this.invoiceService.getNewItem();
    this.createNewInvoice();
    this.errorMsg = [];
    this.updateContactList();
    this.contactTypes = [
      { label: 'Company', value: true  },
      { label: 'Name', value: false  }
    ];
  }

  createNewInvoice() {
    this.invoice = this.invoiceService.getNew();
  }

  submitInvoice(form) {
    if (form.invalid) {
      this.errorMsg = [{severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {
      this.calcTotalSum();
      if (this.invoice.id == null) {
        this.invoiceService.insertNew(this.invoice)
        .subscribe((inv: Invoice) => {
          this.newInvoice.emit(inv.id);
        });
        this.displayDialog = false;
      } else {
        this.invoiceService.update(this.invoice)
        .subscribe((cont: Invoice) => {
          this.updateInvoice.emit(cont.id);
        });
        this.invoice.items.forEach(item => {
          item.editMode = false;
          if(this.tblView.isRowExpanded(item))
            this.tblView.toggleRow(item);
        });
      }
    }

  }

  setInvoice( inv: Invoice ) {
    this.invoice = inv;
    this.invoice.items = [];
    this.invoiceService.getItems(inv.id)
      .then( items => {
        if(items){
          items.forEach( i=> {
            if(i.subItem){
              if(i.subItem.paidByClient ==='0')
                i.subItem.paidByClient = false;
              else
                i.subItem.paidByClient = true;
            }
            i.editMode = false;
            this.calcItemSubtotal(i);
          });
          this.invoice.items = items;
          this.calcTotalSum();
        }else items = [];
       } );

  }

  show() {
    this.calcTotalSum();
    this.displayDialog = true;
  }

  onAddContact() {
    this.contactEdit.show(this.contactService.getNew());
  }

  onContactTypeChange(){
    this.updateContactList();
  }

  updateContactList(){
    this.contactService.getContactList('4,3')
    .then( list => {
      if( this.invoice.useCompany) {
        this.contactList = list.filter( i=> { if(i.company !=='') return true; else  return false; }).map( i => i );
        this.contactList.sort(   (a,b) => ( a.company > b.company) ?  1  : -1  );
      } else {
        this.contactList = list.filter( i=> { if(i.name !=='') return true; else  return false; }).map( i => i );
        this.contactList.sort(   (a,b) => ( a.name > b.name ) ? 1 : -1   );
      }
    });
  }

  onContactNewSave(id: number) {
    this.contactService.getContactList('')
    .then( list => {
      this.contactList = list;
      this.selectedContact = this.contactList.find(c => c.id === id);

    } );
  }

  calcTotalSum() {
    this.invoice.total = 0;
    if(this.invoice.items){
      this.invoice.items.forEach( it=> {
        if(it.type !== -1){ // deleted line
          this.calcItemSubtotal(it);
          this.invoice.total = Number(this.invoice.total) + Number(it.lsum);
        }
    });
    }
  }

  onDeleteRow(item: InvoiceItem) {
    item.type = -1;
    this.calcTotalSum();
  }

  onMenuShow( event, menu, item: InvoiceItem) {
    event.stopPropagation();
    event.preventDefault();
    this.menuItems = [
        { label: 'Add to invoice', icon: 'fas fa-plus',
          items: [
            { label: 'Cars in container', icon: 'fas fa-ship', command: ()=> { this.addCarsByContainerId(0); }},
            { label: 'Car by vin', icon: 'fas fa-car', command: ()=> { this.onAddCarByVin(); } },
            { label: 'Text line', icon: 'far fa-edit', command: ()=> { this.onNewTextItem(); } }
          ]
        }
    ];
    if( item !== null ) {
      this.menuItems.unshift({ label: 'Edit', icon: 'fas fa-edit', command: ()=>{this.onEditRow(item); }});

      if( item.type !== 0) {
        this.menuItems.push( { label: 'Delete', icon: 'far fa-trash-alt', command: () => { this.onDeleteRow(item);  }} );
      }
    }
    menu.toggle(event);
  }

  onApplyItemChanges() {
    const pos = this.invoice.items.findIndex( it=> it.uid === this.editItem.uid );
    this.editItem.lsum = Number(this.editItem.quantity) * Number(this.editItem.sum);

    if( pos !== -1) {
      this.invoice.items[pos] = { ...this.editItem };
    } else {
      this.invoice.items.push( { ...this.editItem } );
    }
    this.calcTotalSum();
    this.displayTxtDialog = false;
  }

  onEditRow(item: InvoiceItem) {
    if( item.type == 1) {
      this.onEditTextItem( item );
    }else
    if( item.type == 2) {
      item.editMode = true;
      if(!this.tblView.isRowExpanded(item))
        this.tblView.toggleRow(item);
    }
  }

  onCancelItemChanges() {
    this.displayTxtDialog = false;
  }

  onNewTextItem() {
    this.onEditTextItem( this.invoiceService.getNewItem(1) );
  }

  onEditTextItem( item: InvoiceItem) {
    this.editItem = {...item};
    this.displayTxtDialog = true;
  }

  addCarByVin( vin: string): Promise<unknown> {
    return this.orderService.getAll({ vin })
    .toPromise().then( orders=> {
      if ( orders.length === 1 ) {
        const tmpItem = this.invoiceService.getNewItem();
        const tmpSub = this.invoiceService.getNewSubItem();
        tmpSub.vin = orders[0].vin;
        tmpSub.value = orders[0].auctionPrice;
        tmpSub.auctionPrice = orders[0].auctionPrice;
        tmpSub.orderId = Number(orders[0].id);
        tmpSub.paidByClientOrd = orders[0].paidByClient;
        tmpSub.deliveryFee = orders[0].buyerPay;
        tmpSub.buyerFee = orders[0].buyerFee;
        tmpSub.storageFee = orders[0].storageFee;
        tmpSub.containerFee = orders[0].containerPrice;
        tmpSub.miscFee = orders[0].miscFee;
        tmpSub.car = orders[0].year + ' ' + orders[0].model;
        tmpSub.containerNr = orders[0].containerNr;
        tmpSub.yard = orders[0].yard;
        tmpItem.subItem = tmpSub;
        tmpItem.type = 2; // tupe: 0 - new, 1 - text, 2 - car
        tmpItem.quantity = 1;
        this.calcItemSubtotal(tmpItem);
        tmpItem.lsum = Number(tmpItem.quantity) * Number(tmpItem.sum);
        this.invoice.items.push(tmpItem);
        return ;
      }
    });
  }

  addCarsByContainerId( contId: number) {
    this.contSelComp.loadConatinerDataById(contId);
    this.contSelComp.show();
  }

  onAddFromContainer(lst: any[]) {
        const promises = [];
        lst.forEach( vin =>{
          promises.push( this.addCarByVin( vin ) );
        });
        Promise.all(promises)
          .then( ()=> this.show() );

  }

  calcItemSubtotal( item: InvoiceItem) {
    if(item.type == 2){
      item.sum = (
        Number( item.subItem.value ) +
        Number( item.subItem.deliveryFee ) +
        Number( item.subItem.buyerFee ) +
        Number( item.subItem.storageFee ) +
        Number( item.subItem.containerFee ) +
        Number( item.subItem.miscFee )
      );
    }
    item.lsum = Number(item.quantity) * Number(item.sum);
  }

  onAddCarByVin() {
    this.selectedVin = null;
    this.displayVinDialog = true;
    this.invoiceService.getCarList()
      .then( lst => { this.carList = lst; });
  }

  onApplyVinChange() {
    this.addCarByVin(this.selectedVin)
      .then( ()=> {
        this.displayVinDialog = false;
        this.calcTotalSum();
      } );
  }

  onPaidByChange(state,  subItem: InvoiceSubData ) {
    if(state.checked) {
      subItem.value = 0;
    } else {
      subItem.value = subItem.auctionPrice;
    }
    this.calcTotalSum();
  }
}


