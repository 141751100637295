import { ChatAdapter, ChatParticipantStatus, ChatParticipantType, Group, IChatGroupAdapter, Message, ParticipantResponse } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ChatService } from './services/chat.service';

export class MyAdapter extends ChatAdapter implements IChatGroupAdapter
{

  constructor(
    private chatService: ChatService
  ) {
    super();
  }

  groupCreated(group: Group): void {
    throw new Error('[GroupCreated] Method not implemented.');
  }

  listFriends(): Observable<ParticipantResponse[]> {
    return this.chatService.getUserList();
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    return this.chatService.getMessageHystory(Number(destinataryId));
  }

  sendMessage(message: Message): void {
    this.chatService.sendMessage(message)
      .toPromise().then();
  }
}
