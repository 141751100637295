import { CountryType } from './../models/contact';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Contact } from '@app/models/contact';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class ContactService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getNew() {
    return {id: null, name: null, email: null, phone1: null, phone2: null, address1: null, address2: null, consigneeOf: null,
      contactType: null, contactTypeId: null, company: null,  country: null, countryCode: null, city: null,
      state: null, zip: null, username: null, note: null, docCount: 0, usdot: 0, clientId: null };
  }

  getAll(type: number) {
    let params = new HttpParams();
    params = params.append('type', type.toString() );
    return this.http.get<Contact[]>(`${environment.apiUrl}/api/contacts.php`, {params})
      .toPromise()
      .then(  res => res as Contact[] );
  }

  getById(id) {
    let params = new HttpParams();
    params = params.append('cmd', 'contact_byid' );
    params = params.append('id', id );
    return this.http.get<Contact>(`${environment.apiUrl}/api/contacts.php`, {params})
    .toPromise()
    .then(  res => res as Contact);
  }

  getConsigneeList(val: string) {
    let params = new HttpParams();
    params = params.append('cmd', 'consignee_list' );
    params = params.append('client', val );
    return this.http.get<Contact[]>(`${environment.apiUrl}/api/contacts.php`, {params})
    .toPromise()
    .then(  res => res as Contact[]);
  }

  getConsignee(clientId: string) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_consignee' );
    params = params.append( 'clientId', clientId );
    return this.http.get<Contact>(`${environment.apiUrl}/api/contacts.php`, {params})
    .toPromise()
    .then(  res => res as Contact);
  }


  getContactList(type: string) {
    let params = new HttpParams();
    params = params.append('cmd', 'contact_list' );
    params = params.append('typeId', type );
    return this.http.get<Contact[]>(`${environment.apiUrl}/api/contacts.php`, {params})
    .toPromise()
    .then(  res => res as Contact[]);
  }

  getTypeList() {
    let params = new HttpParams();
    params = params.append('cmd', 'type_list' );
    return this.http.get<any[]>(`${environment.apiUrl}/api/contacts.php`, {params})
    .toPromise()
    .then(  res => res as any[]);
  }


  getCountryList() {
    return this.getCountryAutosuggest('');
  }

  getCarrierAutosuggest(val) {
    let params = new HttpParams();
    params = params.append('cmd' , 'suggest_carrier' );
    params = params.append('company' , val );
    return this.http.get<any[]>(`${environment.apiUrl}/api/contacts.php`, {params})
      .toPromise()
      .then(  res => res as any[] );
  }

  getCarrierByDot(val) {
    let params = new HttpParams();
    params = params.append('cmd' , 'carrier_by_dot' );
    params = params.append('usdot' , val );
    return this.http.get<any>(`${environment.apiUrl}/api/contacts.php`, {params})
      .toPromise()
      .then(  res => res as any );
  }


  getCountryAutosuggest(val) {
    let params = new HttpParams();
    params = params.append('cmd' , 'country_list' );
    params = params.append('val' , val );
    return this.http.get<CountryType[]>(`${environment.apiUrl}/api/contacts.php`, {params})
      .toPromise()
      .then(  res => res as CountryType[] );
  }

  getStateAutosuggest(iso2: string, val: string) {
    let params = new HttpParams();
    params = params.append('cmd' , 'state_list' );
    params = params.append('val' , val );
    params = params.append('countryCode' , iso2 );
    return this.http.get<string[]>(`${environment.apiUrl}/api/contacts.php`, {params})
      .toPromise()
      .then(  res => res as string[] );
  }

  update(cont: Contact) {
    const msg =  {cmd: 'contact_update',  obj: cont };
    return this.http.post(`${environment.apiUrl}/api/contacts.php`, msg, this.httpOptions );
  }

  insertNew( cont: Contact): Observable<any> {
    const msg =  {cmd: 'contact_new',  obj: cont };
    return this.http.post(`${environment.apiUrl}/api/contacts.php`, msg, this.httpOptions );
  }


}

