<div class="content-section implementation">
  <p-table #tblCtrObj [value]="contactList"   [loading]="loading"  [autoLayout]="true" scrollHeight="65vh"
   [scrollable]="true"   columnResizeMode="expand" dataKey="id" rowHover="true">
    <ng-template pTemplate="caption">
      <div class="box2" [style.width.%]="40" >
        <button *ngIf="editingEnabled" type="button" pButton icon="pi pi-plus" (click)="onNewContact()"></button>
      </div>
      <div class="box2" [style.width.%]="60">
        <div class="cap-hdr"><i style="margin-right: 20px;" class="fas fa-fw fa-users"></i>Contacts</div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
    <tr>
        <th pColumn="edit" style="flex: 0 0 45px;"><a href="#" (click)="onToggleCntSearchBar(tblCtrObj, $event)" ><i class="pi pi-search"></i></a></th>
        <th pColumn="typeName" style="flex: 0 0 150px;">Type</th>
        <th pColumn="company">Company</th>
        <th pColumn="name">Name</th>
        <th *ngIf="loadUserType!==2"pColumn="clientId">Client ID</th>
        <th *ngIf="loadUserType!==2" pColumn="consigneeOf">Consignee of</th>
        <th pColumn="note"  style="text-align: center; flex: 0 0 50px;"><i  class="far fa-sticky-note"></i></th>
        <th pColumn="documents" style="text-align: center; flex: 0 0 50px;"><i  class="far fa-folder"></i></th>
        <th *ngIf="loadUserType!==2" pColumn="username" style="width:150px;">username</th>
        <th *ngIf="loadUserType!==2" pColumn="email" style="width:250px;">Email</th>
        <th pColumn="phone" style="flex: 0 0 150px;">Phone</th>
        <th pColumn="address">Address</th>
      </tr>
      <tr *ngIf="searchBar">
        <th style="flex: 0 0 45px;"></th>
        <th  style="flex: 0 0 150px;">
          <p-dropdown [options]="typeList" (onChange)="tblCtrObj.filter($event.value, 'contactType', 'equals')" styleClass="p-column-filter"
             appendTo="body" [disabled]="!filterEnabled"></p-dropdown>
        </th>
        <th>
          <input [disabled]="!filterEnabled" pInputText type="text" [ngModel]="filterCompany" (input)="tblCtrObj.filter($event.target.value, 'company', 'contains')" class="ui-column-filter" style="width: 100%;">
        </th>
        <th><input pInputText type="text" [ngModel]="filterName" (input)="tblCtrObj.filter($event.target.value, 'name', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
        <th *ngIf="loadUserType!==2"><input pInputText type="text" [ngModel]="filterName" (input)="tblCtrObj.filter($event.target.value, 'clientId', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
        <th *ngIf="loadUserType!==2"><input pInputText type="text" [ngModel]="filterName" (input)="tblCtrObj.filter($event.target.value, 'consigneeOfName', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
        <th style="flex: 0 0 50px;"></th>
        <th style="flex: 0 0 50px;"></th>
        <th style="width:150px;" *ngIf="loadUserType!==2"><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'username', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
        <th style="width:250px;" *ngIf="loadUserType!==2"><input pInputText type="text" (input)="tblCtrObj.filter($event.target.value, 'email', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
        <th style="flex: 0 0 150px;"></th>
        <th></th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" >
        <tr class="ui-selectable-row ui-tr" #row [attr.id]="rowData.id" (click)="expandClick(rowData, tblCtrObj)">
          <td style="flex: 0 0 45px; text-align: center;">
            <div *ngIf="editingEnabled"><a href="#" (click)="onMenuShow($event, menu, rowData, tblCtrObj)" ><i class="fas fa-bars"></i></a></div></td>
          <td style="flex: 0 0 150px;">{{rowData.contactType}}</td>
          <td>{{rowData.company}}</td>
          <td>{{rowData.name}}</td>
          <td *ngIf="loadUserType!==2">{{rowData.clientId}}</td>
          <td *ngIf="loadUserType!==2">{{rowData.consigneeOfName}}</td>
          <td style="flex: 0 0 50px; text-align: center;">
              <a href="#" *ngIf="rowData.note" (click)="toggleOverlay($event, op,  rowData.note)"><i class="far fa-sticky-note"></i></a>
          </td>
          <td style="flex: 0 0 50px; text-align: center;">
            <a href="#" *ngIf="rowData.docCount>0" (click)="showDocViewPanel($event,  rowData.id)"><i class="far fa-file-alt"></i></a></td>
          <td *ngIf="loadUserType!==2" style="width:150px;">{{rowData.username}}</td>
          <td *ngIf="loadUserType!==2" style="word-wrap: break-word;" style="width:250px;">
            <a href="mailto:{{rowData.email}}" *ngIf="rowData.email" ><i class="fas fa-at"></i></a>
            &nbsp;{{rowData.email}}</td>
          <td style="flex: 0 0 150px;">
            <div>
            <span *ngIf="rowData.phone1 && rowData.phone1.charAt(0)==='+' && loadUserType!==2">
              <a href="tel:{{rowData.phone1}}"  ><i class="fas fa-mobile-alt"></i></a>&nbsp;
              <a href="#" (click)="onSendSMS($event,  rowData.phone1)" ><i class="fas fa-sms"></i></a>
            </span>
            &nbsp;{{rowData.phone1}}</div>
            <div>
              <span *ngIf="rowData.phone2 && rowData.phone2.charAt(0)==='+' && loadUserType!==2">
                <a href="tel:{{rowData.phone2}}"  ><i class="fas fa-mobile-alt"></i></a>&nbsp;
                <a href="#" (click)="onSendSMS($event,  rowData.phone2)" ><i class="fas fa-sms"></i></a>
              </span>
              &nbsp;{{rowData.phone2}}
            </div>
          </td>
          <td>
            <div>{{rowData.address1}}</div>
            <div *ngIf="rowData.address2">{{rowData.address2}}</div>
            <div>{{rowData.city}}<span [style.margin-left.px]="10">{{rowData.zip}}</span>
            <span *ngIf="rowData.state">,&nbsp;{{rowData.state}}</span></div>

            <div class="country-item">
              <img src="../../../assets/images/flag_placeholder.png" [class]="'flag flag-' + rowData.countryCode.toLowerCase()"
                *ngIf="rowData.country" />
              <div>{{rowData.country}}</div>
            </div>
          </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-contact >
      <tr >
        <td [attr.colspan]="loadUserType!==2? 12:8" style="background-color: lightgrey; height: 400px;" >
          <div >
          <table>
            <tr>
              <td style="vertical-align: top; width: 500px;" >
                <div class="box2" style="margin-left: 20px; width: 420px;">
                  <div class="card card-w-title" style="padding: 5px;" >
                    <div class="bl-hdr">Contact info<i *ngIf="editingEnabled" class="far fa-edit bl-hdr-ic" (click)="onContactEdit($event, contact)"></i></div>
                      <div class="zbrcont">
                        <div><div class="box2 bl-col1">Type:</div><div class="box2">{{contact.contactType}}</div></div>
                        <div><div class="box2 bl-col1">Company:</div><div class="box2">
                          <span class="ellipsis" style="width: 245px;">{{contact.company}}</span></div></div>
                        <div><div class="box2 bl-col1">Client ID:</div><div class="box2">{{contact.clientId}}&nbsp;</div></div>
                        <div *ngIf="contact.consigneeOfName"><div class="box2 bl-col1">Consignee of:</div>
                          <div class="box2 ellipsis" style="width: 245px;">{{contact.consigneeOfName}}&nbsp;</div></div>
                        <ng-container *ngIf="contact.contactTypeId!=2">
                          <div><div class="box2 bl-col1">Name:</div><div class="box2">{{contact.name}}&nbsp;</div></div>
                          <div><div class="box2 bl-col1">Username:</div><div class="box2">{{contact.username}}</div></div>
                        </ng-container>
                        <div *ngIf="contact.address1"><div class="box2 bl-col1">Address 1:</div><div class="box2">{{contact.address1}}</div></div>
                        <div *ngIf="contact.address2"><div class="box2 bl-col1">Address 2:</div><div class="box2">{{contact.address2}}</div></div>
                        <div *ngIf="contact.city"><div class="box2 bl-col1">City:</div><div class="box2">{{contact.city}}</div></div>
                        <div *ngIf="contact.state"><div class="box2 bl-col1">State:</div><div class="box2">{{contact.state}}</div></div>
                        <div *ngIf="contact.country"><div class="box2 bl-col1">Country:</div><div class="box2">
                          <span class="ellipsis" style="width: 250px;">{{contact.country}}</span>
                          <span  style="padding-left: 5px;">
                          <img src="../../../assets/images/flag_placeholder.png" style="width: 20px; text-align: center;"
                            [class]="'flag flag-' + contact.countryCode.toLowerCase()"   />
                          </span></div></div>
                        <div *ngIf="contact.phone1"><div class="box2 bl-col1">Phone 1:</div><div class="box2">
                          <span style="width: 250px; display: inline-block;">{{contact.phone1}}</span>
                          <span  style="padding-left: 5px;">
                          <a href="tel:{{contact.phone1}}" ><i style="width: 20px; text-align: center;" class="fas fa-mobile-alt"></i></a>
                          </span></div></div>
                        <div *ngIf="contact.phone2"><div class="box2 bl-col1">Phone 2:</div><div class="box2">
                          <span style="width: 250px; display: inline-block;">{{contact.phone2}}</span>
                          <span  style="padding-left: 5px;">
                          <a href="tel:{{contact.phone2}}" ><i style="width: 20px; text-align: center;" class="fas fa-mobile-alt"></i></a>
                          </span></div></div>
                        <div *ngIf="contact.email"><div class="box2 bl-col1">Email:</div><div class="box2">
                          <span class="ellipsis" style="width: 250px;">{{contact.email}}</span>
                          <span style="padding-left: 5px;">
                            <a href="mailto:{{contact.email}}"  ><i style="width: 20px; text-align: center;"  class="fas fa-at"></i></a>
                          </span></div></div>

                        <div *ngIf="contact.note"><div class="box2 bl-col1">Note:</div><div class="box2 ellipsis"
                          style="max-height: 150px; width: 250px;">{{contact.note}}&nbsp;</div></div>
                      </div>
                  </div>
                </div>

              </td>
              <td style="vertical-align: top; width: 600px;">
                <div class="box2" style="margin-left: 20px; width: 550px;">
                  <div class="card card-w-title" style="padding: 5px;" >
                    <div class="bl-hdr">Document list<i *ngIf="editingEnabled" class="far fa-edit bl-hdr-ic" (click)="onDocumentEdit($event, contact)"></i></div>
                    <app-doclist [docList]="docList"></app-doclist>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary" >
      <div style="text-align:left">
        <button *ngIf="editingEnabled" type="button" pButton icon="pi pi-plus" (click)="onNewContact()" label="&nbsp;Add new contact"></button>
      </div>
    </ng-template>
  </p-table>
</div>

<p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" >
  <ng-template pTemplate >
    <div [style.max-width.px]="400" >{{this.overlayText}}</div>
  </ng-template>
</p-overlayPanel>

<p-dialog  [(visible)]="displayDocDialog" [focusOnShow]="false"  showEffect="false" [modal]="true" [style]="{width: '600px'}">
  <p-header >
    <div class="box2" [style.width.px]="220">
    </div>
    <div class="box2" [style.width.px]="250"><span>Document list</span></div>
  </p-header>
  <app-doclist [docList]="docList"></app-doclist>
</p-dialog>

<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
<app-contactedit (newContact)="onContactNewSave($event)" (updateContact)="onContactUpdateSave($event)"></app-contactedit>
<app-docedit [docList]="docList"  (listChanged)="onDocListChanged($event)"></app-docedit>
<app-smsedit></app-smsedit>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
