<p-dialog header="Compose SMS message" [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'400px'}">
<div class="p-grid ui-fluid"   [style.min-height.vh]="20" >
  <form #smsForm="ngForm" (ngSubmit)="submitSMS(smsForm)">
    <div class="p-field" [style.height.px]="55">
      <div [style.width.%]="100">
        <div class="box2" [style.width.%]="10"><b>To:</b></div>
        <div class="box2" [style.width.%]="50">
          <input pInputText id="to" name="to" [(ngModel)]="sms.to" class="form-control"
           [style.width.%]="100" required />
        </div>
        <div class="box2" [style.width.%]="40" style="text-align: center;"><strong >{{sms.content?.length + '/160'}}</strong></div>
      </div>
    </div>
    <div class="p-field" [style.height.px]="120">
      <textarea id="content" name="content" pInputTextarea
        [(ngModel)]="sms.content" [style]="{'width':'100%'}" [rows]="5" [maxLength]="160" required>
      </textarea>
    </div>
  </form>
  <p-messages [(value)]="errorMsg" key="smsFrmError"></p-messages>
  <p-progressBar *ngIf="sending" mode="indeterminate" [style]="{'height': '4px'}"></p-progressBar>
  <div style="width: 100%; padding: 5px; height: 45px; background-color: #f4f4f4; margin-top: 10px;"  >
      <button  style="float: right;" type="button" pButton icon="far fa-paper-plane"
        (click)="submitSMS(smsForm); " label="Send SMS"></button>
    </div>
</div>
</p-dialog>

