import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { DocFile } from '@app/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-doclist',
  templateUrl: './doclist.component.html',
  animations: [
    trigger('inOutAnimation',  [
        transition(':enter', [  style({ height: 0, opacity: 0 }),  animate('200ms ease-out',  style({ height: '*', opacity: 1 })) ] ),
        transition(':leave', [  style({ height: '*', opacity: 1 }), animate('100ms ease-in',   style({ height: 0, opacity: 0 }))   ] )
      ] )
  ]
})
export class DocListComponent implements OnInit {
  @Input() docList: DocFile[];
  selectedFile: string;
  selectedFileId = 0;
  fileURL = `${environment.apiUrl}/api/docfiles.php` + '?download_file=';

  constructor(
  ) { }

  ngOnInit(): void {
    this.docList = null;
  }

  fileOpen( fileName, asAttachment) {
    let url = this.fileURL + fileName;
    if ( asAttachment === true) { url = url + '&as_attachment'; }
    window.open(url, '_blank', 'noopener');
    this.selectedFileId = 0;
  }

  toggleDownlOverlay(event,  fileId) {
    if ( this.selectedFileId !== 0) {
      this.selectedFileId = 0;
    } else { this.selectedFileId = fileId; }
    event.stopPropagation();
    event.preventDefault();

  }


}
