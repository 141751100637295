<div style="margin: auto; max-width: 450px;">
  <div style="padding-top: 20px;">
    <p-card >
      <ng-template pTemplate="header">
        <div style="background-image: linear-gradient(to right, #136a8a, #267871); padding: 5px;">
          <div style="font-size: 28px; font-weight: bold; padding-left: 10px;">
            <a href="/" style="color:white;">Transbaltica</a>  </div>
        </div>
      </ng-template>
      <div style="text-align: center;">
        <input type="text" pInputText  [(ngModel)]="vinOfCar"
          placeholder="Last six digits of VIN" minLength="6" maxLength="6"/>
        <button type="button" pButton icon="pi pi-search" style="margin-left: 10px;"
          (click)="onSearchVin($event)" [disabled]="!vinOfCar || vinOfCar.length!=6"></button>
      </div>
      <p-messages [(value)]="errorMsg"></p-messages>
    </p-card>
    <p-card *ngIf="tmpOrder">
      <div  style="margin:auto ; max-width: 400px;">
        <div class="card card-w-title" style="padding: 2px;" >
          <div class="bl-hdr">Auction info</div>
            <div class="zbrcont">
              <div><div class="box2 bl-col1 tw">Date:</div><div class="box2">{{tmpOrder.date}}</div></div>
              <div><div class="box2 bl-col1 tw">Location:</div><div class="box2" class="ellipsis" [style]="{'width':'65%'}">{{tmpOrder.location}}</div></div>
              <div><div class="box2 bl-col1 tw">Vin:</div><div class="box2" >{{tmpOrder.vin}}&nbsp;</div></div>
              <div><div class="box2 bl-col1 tw">Model:</div><div class="box2" class="ellipsis" style="width: 65%;" >{{tmpOrder.car}}</div></div>
              <div><div class="box2 bl-col1 tw">Container:</div><div class="box2">{{tmpOrder.containerNr}}</div></div>
            </div>
          </div>
        </div>

        <div  style="margin:auto ; max-width: 400px; margin-top: 10px;">
          <div class="card card-w-title" style="padding: 2px;">
            <div class="bl-hdr">Photo of vehicle</div>
            <div style="padding-top: 10px;">
              <p-selectButton [options]="photoTypeOptions" [(ngModel)]="selectedPhotoType" (onChange)="onPhotoTypeChange($event, tmpOrder)"></p-selectButton>
                <div  *ngIf="galleryImages.length>0" style="padding-top: 10px; width: 120px; font-weight: bold; float:right">Photo: {{this.galleryCurSelectedImage}} of {{this.galleryImages.length}}</div>
              <div *ngIf="galleryImages.length>0">
                <ngx-gallery [options]="galleryOptions" [images]="galleryImages" (change)="onPhotoInedxChange($event)"></ngx-gallery>
              </div>
              <div *ngIf="galleryImages.length==0" style="width: 100% ">
                <img alt="no image" src="../../assets/layout/images/noimage.jpg" style="display:block; margin:auto;">
              </div>
            </div>
          </div>
        </div>
      </p-card>

  </div>
</div>
