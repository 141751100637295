<div class="topbar">
        <div class="topbar-main">
            <a href="#" id="menu-button" (click)="app.onMenuButtonClick($event)">
                <span class="fas fa-bars"></span>
            </a>


            <div class="app-name">
                <a href="/">{{app.title}}</a>
            </div>

            <a href="#" id="user-display" (click)="app.onTopbarMenuButtonClick($event)">
                <span class="username" [textContent]= "userName" ></span>
                <span class="userrole" [textContent]= "typeName" ></span>
                <img alt="main logo" src="assets/layout/images/avatar.png">
            </a>

            <ul id="topbar-menu" class="fadeInDown animated" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
                <li #profile class = "menuitem-active">
                    <a href="#" (click)="app.onTopbarItemClick($event, profile)">
                        <i class="fas fa-user topbar-icon" ></i>
                        <span class="topbar-item-name">PROFILE</span>
                    </a>
                    <ul>

                        <li role="menuitem">
                            <a href="#" (click)="app.onTopbarSubItemClick($event, 'settings')">
                              <i class="fas fa-cog"></i>
                                <span>Settings</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" (click)="app.onTopbarSubItemClick($event, 'logout')">
                              <i class="fas fa-sign-out-alt"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <app-menu></app-menu>

    </div>
