import { ContactType, CountryType } from './../../models/contact';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Contact } from '@app/models/contact';
import { ContactService } from '@app/services/contact.service';
import { Message } from 'primeng/api';


@Component({
  selector: 'app-contactedit',
  templateUrl: './contactedit.component.html',
  styleUrls: ['./contactedit.component.css']
})

export class ContactEditComponent implements OnInit {
  @Output() newContact = new EventEmitter<number>();
  @Output() updateContact = new EventEmitter<number>();
  displayDialog = false;
  contact: Contact;
  selCompany: any;
  errorMsg: Message[];
  countryList: any[];
  typeList: ContactType[];
  filteredCountry: any[];
  filteredStates: string[];
  selectedCountry: CountryType;
  selectedType: ContactType;
  stateDDButton: boolean;
  suggResults: any[];
  intContactList: any[];
  loadingData = false;
  consigneeArr: string[];
  editByClient: boolean;


  constructor(
    protected contactService: ContactService
  ) {
    this.selectedType = {id: 0, typeName: null};
    this.editByClient = false;
  }

  ngOnInit(): void {
    this.intContactList = [];
    this.contact = this.contactService.getNew();
    this.errorMsg = [];
    this.countryList = [];
    this.consigneeArr = [];
    this.contactService.getCountryList()
      .then( list => { this.countryList = list; } );
    this.contactService.getTypeList()
      .then ( list => {
        this.typeList = list;
      });
    this.filteredStates = [];
    this.stateDDButton = false;
    this.selectedCountry = null;
    this.loadInternContacts();
  }


  submitContact(form) {

    if( typeof(form.value.company) === 'string' ) { this.contact.company = form.value.company; }
    if (form.value.name === null && form.value.company === null) {
      this.errorMsg = [{severity: 'error', summary: 'Form vallidation!', detail: 'NAME or COMPANY required!'}];
    } else
    if (form.invalid) {
      this.errorMsg = [{severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {

      this.contact.consigneeOf = this.consigneeArr.toString();
      this.contact.contactTypeId = this.typeList.find(t => t.typeName === this.selectedType.typeName).id;
      if (this.contact.id == null) {
        this.contactService.insertNew(this.contact)
        .subscribe((cont: Contact) => {
          this.newContact.emit(cont.id);
        });
      } else {
        this.contactService.update(this.contact)
        .subscribe((cont: Contact) => {
          this.updateContact.emit(cont.id);
        });
      }
      this.displayDialog = false;
    }

  }

  show(cont: Contact) {
    this.countryCleared();

    if (cont.contactTypeId === null ) {
      cont.contactTypeId = 0;
    }
    this.selectedType = this.typeList.find(t => Number(t.id) ===  Number(cont.contactTypeId) );

    if (cont.country === null) {
      this.selectedCountry = null;

    } else {
      this.selectedCountry =  {iso2: cont.countryCode, name: cont.country} ;
      this.contactService.getStateAutosuggest(this.selectedCountry.iso2, '')
      .then ( list => { this.filteredStates = list; } );
      this.stateDDButton = true;
    }
    this.selCompany = { company: cont.company, dot: cont.usdot };
    if(cont.consigneeOf) {
      this.consigneeArr = cont.consigneeOf.split(',');
    } else { this.consigneeArr = []; }

    this.contact = cloneDeep(cont);
    this.displayDialog = true;
  }

  filterCountry(event) {
    this.contactService.getCountryAutosuggest(event.query)
    .then( list => {
      this.filteredCountry = list; } );
  }

  filterState(event) {
    if (this.selectedCountry === null) {
      this.filteredStates = [];
    } else {
      this.contactService.getStateAutosuggest(this.selectedCountry.iso2, event.query)
        .then ( list => { this.filteredStates = list; } );
    }
  }

  countrySelected() {
    this.stateDDButton = true;
    this.contact.country = this.selectedCountry.name;
    this.contact.countryCode = this.selectedCountry.iso2;
    this.contact.state = null;
    this.contactService.getStateAutosuggest(this.selectedCountry.iso2, '')
    .then ( list => { this.filteredStates = list; } );
  }

  countryCleared() {
    this.stateDDButton = false;
    this.selectedCountry = null;
    this.contact.country = null;
    this.contact.state = null;
  }

  getCompanySuggest(event) {
    this.contactService.getCarrierAutosuggest(event.query)
      .then( res => { this.suggResults = res;  });
  }

  onCompanySelect(tmpCont) {
    this.loadingData = true;
    this.contactService.getCarrierByDot(tmpCont.dot)
    .then( c=> {
      this.contact.usdot = c.usdot;
      this.contact.company = c.company;
      this.contact.phone1 = c.phone;
      this.contact.address1 = c.address;
      this.contact.city = c.city;
      this.contact.zip = c.zip;
      this.contact.state = c.state;
      this.selectedCountry =  {iso2: 'US', name: c.country} ;
      this.contact.country = this.selectedCountry.name;
      this.contact.countryCode = this.selectedCountry.iso2;
      this.loadingData = false;
      this.contactService.getStateAutosuggest(this.selectedCountry.iso2, '')
      .then ( list => {
        this.stateDDButton = true;
        this.filteredStates = list;
      } );
    });
  }

  setEditByClient( byClient: boolean) {
    this.editByClient = byClient;
  }

  loadInternContacts(){
    this.intContactList = [
      // { label: '', value: null }
    ];
    this.contactService.getContactList('4,3') // 4-International client 3-client
    .then( list => {
      list.forEach( c=> {
        this.intContactList.push({ label: c.clientId, value: c.id });
      });
    });
  }

}
