import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Message, ParticipantResponse } from 'ng-chat';

@Injectable({ providedIn: 'root' })
export class ChatService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) {
  }

  pingServer(away: number, id: number) {
    let params = new HttpParams();
    params = params.append('cmd', 'ping' );
    params = params.append('userId', id.toString() );
    params = params.append('away', away.toString() );
    return this.http.get<any>(`${environment.apiUrl}/api/chat.php`, {params})
      .toPromise()
      .then(  res => res );  }

  getUserList(  ): Observable<ParticipantResponse[]> {
    let params = new HttpParams();
    params = params.append('cmd', 'friend_list' );
      return this.http.get<any[]>(`${environment.apiUrl}/api/chat.php`, {params})
    .pipe( map( (friends:any)=> friends  ));
  }

  getMessageHystory( id: number ): Observable<Message[]> {
    let params = new HttpParams();
    params = params.append('cmd', 'message_list' );
    params = params.append('userId', id.toString() );
    return this.http.get<any[]>(`${environment.apiUrl}/api/chat.php`, {params})
    .pipe( map( (msgs:any)=> msgs  ));
  }

  sendMessage( msg: Message) {
    const body =  {cmd: 'send_messge',  obj: msg };
    return this.http.post(`${environment.apiUrl}/api/chat.php`, body, this.httpOptions);
  }

  setSeenMessage( msg: Message) {
    const body =  {cmd: 'seen_message',  obj: msg };
    return this.http.post(`${environment.apiUrl}/api/chat.php`, body, this.httpOptions);
  }
}
