import { Contact } from './../models/contact';
import { ChatUser, EnabledOption } from './../models/user';
import { UsersService } from '../services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserType, Yard } from '@app/models';
import { cloneDeep } from 'lodash';
import { Message, MessageService } from 'primeng/api';
import { ContactService } from '@app/services/contact.service';
import { ContactEditComponent } from '@app/components/contactedit/contactedit.component';
import { YardsService } from '@app/services/yard.service';
import { timeStamp } from 'console';


@Component({
  templateUrl: 'userview.component.html',
  styleUrls: ['userview.component.css'],
  providers: [MessageService]

})
export class UserViewComponent implements OnInit {
  @ViewChild(ContactEditComponent) contactEdit: ContactEditComponent;
  users: User[];
  userTypes: UserType[];
  selectedType: UserType;
  enabledOptions: EnabledOption[];
  contactList: Contact[];
  selectedContact: Contact;
  selectedEnabledOpt: EnabledOption;
  chatUsers: ChatUser[];
  selectedChatUsers: ChatUser[];
  selectedYards: Yard[];
  yardList: Yard[];

  tmpUser: User;
  errorMsg: Message[] ;
  newUser = false;
  loading = false;
  displayUserDialog = false;
  rowExpanded: boolean;

  constructor(
    private userService: UsersService,
    private contactService: ContactService,
    private yardService: YardsService
    ) {
      this.userTypes = null;
    }

  ngOnInit() {
    this.errorMsg = [];
    this.loading = false;
    this.rowExpanded = false;
    this.contactList = [];
    this.selectedYards = [];

    this.userService.getTypeList()
      .then( lst => {
        this.userTypes = lst;
        this.userService.getAll().toPromise().then(users => {
          this.users = users;
          this.loading = false;
          this.chatUsers = [];
          this.users.forEach (
            item => {
              item = this.processUser(item);
              if( Number(item.type) !== 5 ) {
                this.chatUsers.push( { id: Number(item.id), name: item.username } );
              }
            }
          );
        });
      });
    this.contactService.getContactList('1,3,4,6')
    .then( list => {
      this.contactList = [ this.contactService.getNew(), ...list ];
      });

    this.enabledOptions = [
      { id: null , optionStr: null },
      { id: '0' , optionStr: 'No' },
      { id: '1' , optionStr: 'Yes' },
    ];

    this.yardList = [];
    this.yardService.getAll().then(yards => {
      this.yardList = yards;
    });

    this.selectedType = null;
    this.selectedEnabledOpt = null;
  }

  processUser(user) {
    user.typeName = this.userTypes.find(u => u.typeId === user.type).typeStr;
    if ( user.enabled === '1') { user.enabledBin = true; }
    else { user.enabledBin = false; }
    if ( user.history === '1') { user.history = true; }
    else { user.history = false; }
    if ( user.ordPhotoChngEmail === '1') { user.ordPhotoChngEmail = true; }
    else { user.ordPhotoChngEmail = false; }
    if ( user.ordLoadedEmail === '1') { user.ordLoadedEmail = true; }
    else { user.ordLoadedEmail = false; }
    if ( user.ordStatusChngSMS === '1') { user.ordStatusChngSMS = true; }
    else { user.ordStatusChngSMS = false; }

    user.enabledStr = this.enabledOptions.find(o => o.id ===  user.enabled).optionStr;
    if (user.password === '') { user.password = null; }
    return user;
  }

  onNewUser() {
    this.newUser = true;
    this.tmpUser = this.userService.getNew();
    this.selectedType = null;
    this.selectedEnabledOpt = null;
    this.displayUserDialog = true;
  }

  onEditUser( event, user: User) {
    this.newUser = false;
    this.tmpUser =  cloneDeep(user);
    this.tmpUser.password = null;
    this.displayUserDialog = true;
    this.selectedType = this.userTypes.find(u => u.typeId === this.tmpUser.type);
    this.selectedEnabledOpt = this.enabledOptions.find(o => o.id === this.tmpUser.enabled);

    this.selectedChatUsers = [];
    if(user.canChatWith) {
      const list  = user.canChatWith.split(',');
      if( list ){
        list.forEach( ids => {
          const utmp = this.chatUsers.find( u => Number(u.id) === Number(ids));
          if (utmp) { this.selectedChatUsers.push( utmp );  }
        });
      }
    }
    this.selectedYards = [];
    if(user.yard) {
      const list  = user.yard.split(',');
      if( list ){
        list.forEach( yd => {
          const utmp = this.yardList.find( y => y.yard === yd);
          if (utmp) { this.selectedYards.push( utmp );  }
        });
      }
    }
    event.preventDefault();
  }

  saveUser() {

    this.tmpUser.type = this.userTypes.find(u => u.typeStr === this.selectedType.typeStr).typeId;
    this.tmpUser.enabled = this.enabledOptions.find(o => o.optionStr === this.selectedEnabledOpt.optionStr).id;

    if(this.selectedYards) {
      const chYd = this.selectedYards.map( yd => {   return yd.yard;   });
      this.tmpUser.yard = chYd.toString();
    }
    if(this.selectedChatUsers){
      const chUsr = this.selectedChatUsers.map( item => {   return item.id;   });
      this.tmpUser.canChatWith = chUsr.toString();
    }
    this.displayUserDialog = false;
    if (this.newUser) {
      this.userService.insertNewUser(this.tmpUser)
        .subscribe((user: User) => {
          user = this.processUser(user);
          this.users.push(user);
          this.newUser = false;
      });
    } else {
      this.userService.updateUser(this.tmpUser)
        .subscribe((user: User) => {
          user = this.processUser(user);
          const index = this.users.findIndex(u => u.id === this.tmpUser.id);
          this.users[index] = this.processUser( user);
        });
    }

  }
  submitUser(form) {
    if (form.invalid) {
      this.errorMsg = [{severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {
      this.saveUser();
    }
  }

  onAddContact(user: User) {
    this.contactEdit.show(this.contactService.getNew());
  }

  onContactNewSave(id: number) {
    this.contactService.getContactList('')
      .then( list => {
        this.contactList = list;
      //  this.selectedContact = this.contactList.find(c => c.id === id);

      } );
  }

  onUserEnableChange(e, user: User) {
    if (e.checked) {
      user.enabled = '1';
    } else {
      user.enabled = '0';
    }
    this.userService.updateUser(user)
        .subscribe(usr => {
          usr = this.processUser(usr);
          const index = this.users.findIndex(u => u.id === usr.id);
          this.users[index] = this.processUser(usr);
        });
  }

  expandClick(rowData, tblObj) {
    if (tblObj.expandedRowKeys[rowData.id] != null) { // colapse
      delete tblObj.expandedRowKeys[rowData.id];
      tblObj.expandedRowKeys = {};
      this.rowExpanded = false;
    } else { // expand
      this.rowExpanded = true;
      tblObj.expandedRowKeys = {};
      tblObj.expandedRowKeys[rowData.id] = true;
    }
  }

  onOptionChange(event, name, id){
    this.userService.changeOption( name, event.checked, id ).toPromise().then();
  }

}
