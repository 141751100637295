<p-dialog header="Container details" [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true" >
  <div class="p-grid ui-fluid"   [style.min-height.vh]="25" [class]="tabIndex===1? 'dialog-wt':'dialog-nt'">
  <p-tabView (onChange)="onTabChange($event, container.id)" [(activeIndex)]="tabIndex">
    <p-tabPanel header="Container info" >

      <form #containerForm="ngForm" (ngSubmit)="submitContainer(containerForm)">
        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="33">
            <label for="loadDate">Load date</label>
            <p-calendar [(ngModel)]="container.loadDate" dateFormat="mm/dd/yy" [showIcon]="true" id="loadDate" appendTo="body"
              name="loadDate" class="form-control" [style.width.%]="100" reqiured></p-calendar>
          </div>

          <div class="box2" [style.width.%]="66" [style.padding-left.px]="20">
            <label for="booking">Booking</label>
            <input pInputText id="bookingNr" name="bookingNr" [(ngModel)]="container.bookingNr" class="form-control"
              [style.width.%]="100" />
          </div>

        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="33" >
            <label for="containerNr">Container</label>
            <input pInputText id="containerNr" name="containerNr" [(ngModel)]="container.containerNr" class="form-control"
              [style.width.%]="100" />
          </div>

          <div class="box2" [style.width.%]="33" [style.padding-left.px]="20">
            <label for="size">Size</label>
            <p-dropdown [options]="containerTypes" [(ngModel)]="container.size" placeholder="Select size" name="size"
              id="size" [style]="{'width':'100%'}" [disabled]="editDisabled.size"></p-dropdown>
          </div>

          <div class="box2" [style.width.%]="33" [style.padding-left.px]="20">
            <label for="origin">Origin</label>
            <p-dropdown [options]="originList" [(ngModel)]="container.origin" placeholder="Select ..." name="origin"
              id="origin" [style]="{'width':'100%'}" [disabled]="editDisabled.origin" scrollHeight="350px"></p-dropdown>
          </div>


        </div>


        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="33">
            <label for="shippingLine">Shipping line</label>
            <input pInputText id="shippingLine" name="shippingLine" [style.width.%]="100"
              [(ngModel)]="container.shippingLine" class="form-control" />
          </div>
          <div class="box2" [style.width.%]="33" [style.padding-left.px]="20">
            <label for="forwarder">Forwarder</label>
            <input pInputText id="forwarder" name="forwarder" [style.width.%]="100" [(ngModel)]="container.forwarder"
              class="form-control" />
          </div>
          <div class="box2" [style.width.%]="33" [style.padding-left.px]="20">
            <label for="destination">Destination</label>
              <p-autoComplete [(ngModel)]="container.destination" [suggestions]="destResults" id="destination" name="destination"
                (completeMethod)="getDestSuggest($event)" [inputStyle]="{'width': '100%'}" [disabled]="editDisabled.destination"
                  minLength="3" appendTo="body"></p-autoComplete>
                <span class="fas fa-align-justify sugg-ico"></span>
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="66">
            <label for="destinationAgent">Consignee</label>
            <p-dropdown [options]="intContactList" [(ngModel)]="container.destinationAgent" id="destinationAgent" name="destinationAgent"
              placeholder="Select consignee ..."  [style]="{'width':'100%'}" [disabled]="editDisabled.consignee"></p-dropdown>
          </div>

          <div class="box2" [style.width.%]="34" [style.padding-left.px]="20"  [style.visibility]="currentUser.type === c.USER_TYPE_YARD ? 'hidden' : 'visible' ">
            <label for="price">Price</label>
            <div [style]="{'width':'100%'}">
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon">$</span>
                <input pInputText id="price" style="font-weight: bold; width: 90%;" [(ngModel)]="container.price"
                  name="price" [disabled]="editDisabled.price">
              </div>
            </div>
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="50">
            <label for="shippingLine">Tracking</label>
            <input pInputText id="tracking" name="tracking" [style.width.%]="100" [(ngModel)]="container.tracking"
              class="form-control" />
          </div>

          <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
            <label for="eta">ETA</label>
            <input pInputText id="eta" name="eta" [style.width.%]="100" [(ngModel)]="container.eta"
              class="form-control" />
          </div>
        </div>

        <div class="p-field" >
          <label for="note" [style.padding-top.px]="10">Note</label>
          <textarea pInputTextarea id="note" name="note" style="width: 100%;"
            rows="5" cols="30" [(ngModel)]="container.note" ></textarea>
        </div>

      </form>
      <p-messages [(value)]="errorMsg3"></p-messages>

    </p-tabPanel>
    <p-tabPanel header="Cars loaded"   >
        <p-table dataKey="id" [value]="contCars" [style.width.%]="100" rowHover="true" [rows]='5'>
          <ng-template pTemplate="header">
            <tr>
              <th pColumn="date" style="width: 90px;">Date</th>
              <th pColumn="location">Location</th>
              <th pColumn="buyer" style="width: 70px;">Buyer</th>
              <th pColumn="lot" style="width: 80px;">Lot</th>
              <th pColumn="model">Vehicle</th>
              <th pColumn="vin" style="width: 150px;">VIN</th>
              <th pColumn="auctionPirce" style="width: 100px;">Value</th>
              <th pColumn="consignee">Client ID</th>
              <th style="width: 40px;"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr class="ui-selectable-row ui-tr" *ngIf="rowData.status!=='delete'">
              <td>{{rowData.date}}</td>
              <td>{{rowData.location}}</td>
              <td>{{rowData.buyerNr}}</td>
              <td>{{rowData.lot}}</td>
              <td>{{rowData.year + ' ' +rowData.model}}</td>
              <td>{{rowData.vin}}</td>
              <td>{{rowData.auctionPrice | currency:'USD'}}</td>
              <td>{{rowData.consignee}}</td>
              <td><a href="#" (click)="onDeleteCar(rowData, $event)" [style.display]="currentUser.type === c.USER_TYPE_YARD ? 'none' : null"
                ><i [ngClass]="'far fa-trash-alt' "></i></a></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer" *ngIf="currentUser.type !== c.USER_TYPE_YARD" >
            <tr>
              <td colspan="9">
                <div *ngIf="!addNewCarMode && container.id && !(vinToAdd === '')" class="box2"  style="margin-right: 60px;">
                  <button type="button" pButton icon="pi pi-plus" (click)="onAddVin(container, vinToAdd)"
                    label="&nbsp;{{vinToAdd}}"></button>
                </div>

                <div *ngIf="!addNewCarMode" class="box2" [style.width.px]="130">
                  <button type="button" pButton icon="pi pi-plus" (click)="onAddCar(container)" label="&nbsp;Add car"></button>
                </div>
                <div *ngIf="!addNewCarMode && contCars.length>0" class="box2" [style.width.px]="180">
                  <button type="button" pButton icon="pi pi-plus" (click)="onCreateInvoice(container)" label="&nbsp;Add to invoice"></button>
                </div>

                <div *ngIf="addNewCarMode" [style.width.%]="80" style="margin-left: 50px;">
                  <div class="box2" [style.width.%]="75">
                    <input pInputText id="vin" name="vin" [style.width.%]="100" [(ngModel)]="selectedVIN" />
                  </div>
                  <div class="box2" [style.width.px]="50" [style.padding-left.px]="10">
                    <button type="button" pButton icon="pi pi-plus" [disabled]="selectedVIN.length<17"
                      (click)="onAddCarComplete()"></button>
                  </div>
                  <div class="box2" [style.width.px]="50" [style.padding-left.px]="10">
                    <button type="button" pButton icon="pi pi-times" (click)="addNewCarMode=false"></button>
                  </div>
                </div>
                <p-messages [(value)]="errorMsg2"></p-messages>

              </td>
            </tr>
           </ng-template>
        </p-table>
    </p-tabPanel>

    <p-tabPanel header="Documents" [disabled]="container.id===null">
      <app-doclist [docList]="docList"></app-doclist>
      <div style="width: 100%; padding: 5px; height: 45px; background-color: #f4f4f4; margin-top: 10px;"  >
        <button type="button" pButton icon="far fa-edit" (click)="onDocumentEdit(container.id); " label="Edit list"></button>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Photos" [disabled]="container.id===null">
      <div class="card card-w-title" style="padding: 5px;">
        <div class="bl-hdr">Photo of vehicle<i  (click)="onEditPhotoToggle(container)"
                       [class]="(this.editPhoto) ? 'fas fa-times bl-hdr-ic' : 'fas fa-edit bl-hdr-ic' " ></i>
        </div>
        <div *ngIf="galleryImages.length>0">
          <ngx-gallery [options]="galleryOptions" [images]="galleryImages"  ></ngx-gallery>
        </div>
        <div *ngIf="galleryImages.length==0" style="width: 100% ">
          <img alt="no image" src="../../assets/layout/images/noimage.jpg" style="display:block; margin:auto;">
        </div>
      </div>
    </p-tabPanel>

  </p-tabView>
  <div style="width: 100%; padding: 5px; height: 45px; background-color: #f4f4f4; margin-top: 10px; " *ngIf="tabIndex<2" >
    <button style="float: right;" type="button" pButton icon="pi pi-check" (click)="submitContainer(containerForm); " label="Save"></button>
  </div>

  </div>
  </p-dialog>
  <app-docedit [docList]="docList"  (listChanged)="onDocListChanged($event)"></app-docedit>
  <app-photoedit (dialogHide)="onPhotoEditClosed()" [imageList] = "galleryImages" (listChanged)="onImageListChanged($event)"
  [orderId]="container.id" [photoType]="3"></app-photoedit>

