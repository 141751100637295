import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class InterCompNotifier
{
    private mNotifierSubj = new BehaviorSubject<any>([]);
    public notification = this.mNotifierSubj.asObservable();

    constructor() { }

    public sendNotification(arg) {
      this.mNotifierSubj.next(arg);
  }
}
