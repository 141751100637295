import { ContactService } from '@app/services/contact.service';
import { Contact } from '@app/models/contact';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '@app/models/user';
import { UsersService } from '@app/services/user.service';
import { DocFile } from '@app/models';
import { DocFileService } from '@app/services/docfile.service';
import { ContactEditComponent } from '../contactedit/contactedit.component';
import { DocEditComponent } from '../docedit/docedit.component';

@Component({
  selector: 'app-settingsedit',
  templateUrl: './settingsedit.component.html',
  styleUrls: ['./settingsedit.component.css']
})
export class SettingsEditComponent implements OnInit {
  @ViewChild(ContactEditComponent) contactEdit: ContactEditComponent;
  @ViewChild(DocEditComponent) docEdit: DocEditComponent;
  displayDialog = false;
  user: User;
  contact: Contact;
  tabIndex: number;
  docList: DocFile[];

  constructor(
    private userService: UsersService,
    private contactService: ContactService,
    private docService: DocFileService,
  ) { }


  ngOnInit(): void {
    this.tabIndex = 0;
     this.user = this.userService.getNew();
     this.contact = this.contactService.getNew();
  }

  showDialog(userId: number) {
    this.userService.getById(userId)
      .toPromise().then(
        user=> {
          if ( user.history as any === '1') { user.history = true; }
          else { user.history = false; }
          if ( user.ordPhotoChngEmail as any === '1') { user.ordPhotoChngEmail = true; }
          else { user.ordPhotoChngEmail = false; }
          if ( user.ordLoadedEmail as any === '1') { user.ordLoadedEmail = true; }
          else { user.ordLoadedEmail = false; }
          if ( user.ordStatusChngSMS as any === '1') { user.ordStatusChngSMS = true; }
          else { user.ordStatusChngSMS = false; }
          this.user = user;
          this.loadDocList(Number(user.contactId));
          this.contactService.getById(user.contactId)
            .then( contact => {
              this.contact = contact;
            })
        }
      );
    this.displayDialog = true;
  }

  editContact( ){
    this.contactEdit.setEditByClient(true);
    this.contactEdit.show(this.contact);
  }


  onContactUpdateSave(id){
    this.contactService.getById(id)
    .then( cont => {
      this.contact = cont;
     } );
  }

  editDocuments() {
    const id = Number(this.contact.id);
    this.docEdit.setEditByClient(true);
    this.docEdit.show( 1, id); // 1 - contact module id
  }

  onOptionChange(event, name, id){
     this.userService.changeOption( name, event.checked, id ).toPromise().then();
  }

  loadDocList(id: number) {
    this.docService.getFileList( 1, id )
      .subscribe( list => {
        this.docList = list;
        this.docList.forEach( i=> {
          if(i.type === 'null') i.type = '';
        });
      });
  }

  onDocListChanged(event){
    this.loadDocList(this.contact.id);
  }

}
