import { Photo } from './../models/photo';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { NgxGalleryImage } from 'ngx-gallery-9';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PhotoService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  constructor(private http: HttpClient) {
  }

  getPhotosOf(orderid: number, type: number) {
      const cmd = 'get_photos_of';
      return this.http.post<NgxGalleryImage[]>(`${environment.apiUrl}/api/carphoto.php`, { cmd, orderid, type }, this.httpOptions )
      .pipe(
        map( photos => photos as NgxGalleryImage[]  ));
  }

  uploadImage(image: File, orderId: number, type: number): Observable<Response> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('orderId', orderId.toString());
    formData.append('type', type.toString());
    formData.append('cmd', 'upload_image');

    return this.http.post<Response>(`${environment.apiUrl}/api/carphoto.php`, formData);
  }

  deleteImage(imageId: number): Observable<any> {
    const cmd = 'delete_image';
    return this.http.post<any>(`${environment.apiUrl}/api/carphoto.php`, { cmd, imageId }, this.httpOptions );
  }

  downloadPhotos(orderId: number, type: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('orderid', orderId.toString() );
    params = params.append('type', type.toString() );
    params = params.append('cmd', 'download_photos' );
    return this.http.get(`${environment.apiUrl}/api/carphoto.php`, {params});
  }

}
