export class Constants {
  public static USER_TYPE_ADMIN = '1';
  public static USER_TYPE_DISPATCH = '2';
  public static USER_TYPE_CLIENT = '3';
  public static USER_TYPE_YARD = '4';
  public static USER_TYPE_MANAGER = '6';
  public static USER_TYPE_YARD_F = '7';
  public static USER_TYPE_VIEWER = '8';
  public static USER_TYPE_TAX = '9';

}

