<p-dialog header="Contact details" [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'600px'}">
  <div *ngIf="loadingData" style="width: 600px; height: 500px; padding-top: 20%; padding-left: 40%; position: absolute;">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="p-grid ui-fluid"   [style.min-height.vh]="45" [ngStyle]="loadingData && {'opacity': '.5'}" >
    <form #contactForm="ngForm" (ngSubmit)="submitContact(contactForm)"  >
      <div class="ui-fluid" >
      <div *ngIf="!editByClient">
        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="35" >
            <label for="contactType">Type</label>
            <p-dropdown [options]="typeList" [(ngModel)]="selectedType" id="contactType" name="contactType"
              optionLabel="typeName" placeholder="Select type .." [style]="{'width':'100%'}"></p-dropdown>
            </div>
          <div class="box2" [style.width.%]="65" [style.padding-left.px]="20">
            <label for="company">Company</label>
            <ng-container *ngIf="selectedType.id==2">
              <p-autoComplete [(ngModel)]="selCompany" [suggestions]="suggResults" inputId="company" name="company" field="company" delay="500"
                (completeMethod)="getCompanySuggest($event)" (onSelect)="onCompanySelect($event)"
                [inputStyle]="{'width': '350px'}" [minLength]="selectedType.id==2? 4: 50" appendTo="body">
                <ng-template let-tComp pTemplate="item">
                  <span style="font-family: Calibri; font-weight: 600;">{{tComp.company}}</span>
                  <span style="font-family: Calibri; font-style: italic; font-size: .8em;">,&nbsp;{{tComp.city}}</span>
                </ng-template>
              </p-autoComplete>
              <span  class="fas fa-align-justify sugg-ico"></span>
            </ng-container>
            <ng-container  *ngIf="selectedType.id!=2">
              <input pInputText id="company" name="company" [style.width.%]="100" [(ngModel)]="contact.company"/>
            </ng-container>
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">

          <div class="box2" [style.width.%]="50">
            <label for="name">Name</label>
            <input pInputText id="name" name="name" [style.width.%]="100" [(ngModel)]="contact.name"/>
          </div>
          <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
            <label for="clientId">Client ID</label>
            <input pInputText id="clientId" name="clientId" [style.width.%]="100" [(ngModel)]="contact.clientId" />
          </div>
        </div>

        <div class="p-field" [style.height.px]="65" *ngIf="selectedType.id==7">
          <div class="box2" [style.width.%]="100">
            <label for="consigneeOf">Consignee of</label>
            <p-multiSelect [options]="intContactList" [(ngModel)]="consigneeArr" id="consigneeOf" name="consigneeOf"
               placeholder="Select contcat Id .."  [style]="{'width':'100%'}"></p-multiSelect>
          </div>
        </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="100">
            <label for="email">Email</label>
            <input pInputText id="email" name="email" [style.width.%]="100" [(ngModel)]="contact.email" />
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="50">
            <label for="enabled">Mobile</label>
            <input pInputText id="phone1" name="phone1"  [style.width.%]="100" [(ngModel)]="contact.phone1"/>
          </div>
          <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
            <label for="state">Phone 2</label>
            <input pInputText id="phone2" name="phone2" [style.width.%]="100" [(ngModel)]="contact.phone2"/>
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="50">
            <label for="address1">Address 1</label>
            <input pInputText id="adress1" name="address1" [style.width.%]="100" [(ngModel)]="contact.address1" />
          </div>
          <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
            <label for="address2">Address 2</label>
            <input pInputText id="adress2" name="address2" [style.width.%]="100" [(ngModel)]="contact.address2" />
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="50"  >
            <label for="city">City</label>
            <input pInputText id="city" name="city" [style.width.%]="100" [(ngModel)]="contact.city" />
          </div>


          <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
            <label for="state">State</label>
            <p-autoComplete [(ngModel)]="contact.state" [suggestions]="filteredStates" (completeMethod)="filterState($event)"
              [disabled]="stateDDButton? null:true"
                name="state" id="state" [style]="{'width':'100%'}" appendTo="body" [dropdown]="true">
                </p-autoComplete><span style="margin-right: 46px;" class="fas fa-align-justify sugg-ico"></span>
          </div>
        </div>

        <div class="p-field" [style.height.px]="65">
          <div class="box2" [style.width.%]="50" >
            <label for="zip">Postal code</label>
            <input pInputText id="zip" name="zip" [style.width.%]="100" [(ngModel)]="contact.zip" />
          </div>

          <div class="box2" [style.width.%]="50" [style.padding-left.px]="20">
            <label for="country">Country</label>
            <p-autoComplete [(ngModel)]="selectedCountry" [suggestions]="filteredCountry" (completeMethod)="filterCountry($event)"
              (onSelect)="countrySelected()" (onClear)="countryCleared()" field="name"
                name="country" id="country" [style]="{'width':'100%'}" appendTo="body" [dropdown]="true">
              <ng-template let-country pTemplate="item">
                  <div class="country-item">
                      <img src="../../../assets/images/flag_placeholder.png" [class]="'flag flag-' + country.iso2.toLowerCase()" />
                      <div>{{country.name}}</div>
                  </div>
              </ng-template>
            </p-autoComplete><span style="margin-right: 46px;" class="fas fa-align-justify sugg-ico"></span>
          </div>
        </div>

        <div class="p-field" *ngIf="!editByClient">
          <label for="note" [style.padding-top.px]="10">Note</label>
          <textarea pInputTextarea id="note" name="note"  [style.width.%]="100"
            rows="5" cols="30" [(ngModel)]="contact.note" ></textarea>
        </div>
      </div>

    </form>
    <p-messages [(value)]="errorMsg" [enableService]="false"></p-messages>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix" >
      <button type="button" pButton icon="pi pi-check" (click)="submitContact(contactForm); "  label="Save" ></button>
    </div>

  </p-footer>
</p-dialog>



