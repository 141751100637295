<body class="login-body" >
  <div style="background-image: linear-gradient(to right, #136a8a, #267871); width: 95%; max-width: 400px; margin: auto; padding: 5px;;">
          <div style="font-size: 28px; font-weight: bold; padding-left: 10px;">
            <a href="/" style="color:white;">Transbaltica</a>  </div>
  </div>
  <div class="login-panel ui-fluid" style="width: 95%; max-width: 400px; padding: 50px 50px;">
  <div class="ui-g">
      <div class="ui-g-12 logo-container">

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="ui-g-12">
              <div class="input-wrapper">
                  <input type="text" autocomplete="off" formControlName="username" placeholder="Username" class="ui-inputtext ui-widget"
                  [ngClass]="{ 'ng-dirty ng-invalid': submitted && f.username.errors }" />
                  <div *ngIf="submitted && f.username.errors" class="ui-message ui-widget ui-corner-all ui-message-error">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                  </div>
              </div>
          </div>
        </div>
        <div class="form-group">
          <div class="ui-g-12">
              <div class="input-wrapper">
                  <input type="password" autocomplete="off" formControlName="password" placeholder="Password" class="ui-inputtext ui-widget"
                  [ngClass]="{ 'ng-dirty ng-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="ui-message ui-widget ui-corner-all ui-message-error">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
            </div>
          </div>
        </div>
        <div class="ui-g-12 button-container">
            <button  class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only green-btn raised-btn rounded-btn" type="submit">
                <div class="ui-button-text ui-c" style="width: 160px;">Sign In</div>
            </button>
        </div>
        <div>
          <p-messages [value]="msgs" [closable]="false"></p-messages>
        </div>

      </form>
      </div>
  </div>
  <div class="ui-g"><a href="#" (click)="onInfoByVIN($event)">Car info by VIN...</a></div>
</div>
</body>
