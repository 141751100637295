import { UsersService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppComponent} from './app.component';
import { User, UserType } from './models';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
  user: User;
  userName: string;
  userTypes: UserType[];
  typeName: string;
  expireIn: number;
  constructor(
    public app: AppComponent,
    private autServ: AuthenticationService,
    private userService: UsersService,
    public jwtHelper: JwtHelperService
    ) {
      this.userTypes = null;
    }

  ngOnInit() {
    // this.userTypes = this.usersService.getUserTypes();
    this.userService.getTypeList()
      .then( lst => {
        this.userTypes = lst;
        this.autServ.currentUser.subscribe(
          user => {
            this.user = user;
            if (user) {
              this.typeName = this.userTypes.find(u => u.typeId === user.type).typeStr;
              this.userName = (user.name===null ? user.username : user.name);
              this.app.checkJWTExpire();
            }
          });
        });
  }





}
