import { SMSEditComponent } from '@app/components/smsedit/smsedit.component';
import { SMSMessage } from '@app/models/sms';
import { SMSService } from '@app/services/sms.service';
import { DocFileService } from '@app/services/docfile.service';
import { DocFile } from './../models/docfile';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ContactService } from '@app/services/contact.service';
import { Contact, ContactType } from '@app/models/contact';
import { ContactEditComponent } from '@app/components/contactedit/contactedit.component';
import { DocEditComponent } from '@app/components/docedit/docedit.component';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { AuthenticationService } from '@app/services/authentication.service';
import { Constants } from '@app/models/constants';
import { User } from '@app/models/user';


@Component({
  selector: 'app-contact-view',
  templateUrl: './contactview.component.html',
  styleUrls: ['./contactview.component.css'],
})
export class ContactViewComponent implements OnInit, AfterViewInit {
  @ViewChild(ContactEditComponent) contactEdit: ContactEditComponent;
  @ViewChild(DocEditComponent) docEdit: DocEditComponent;
  @ViewChild(SMSEditComponent) smsEdit: SMSEditComponent;
  @ViewChildren('row', { read: ElementRef }) rowElement: QueryList<ElementRef>;
  @ViewChild('tblCtrObj') tblView: any;
  c: any;
  loading = false;
  contactList: Contact[];
  typeList: ContactType[];
  docList: DocFile[];
  searchBar: boolean;
  overlayText: string;
  tmpContact: Contact;
  selectedFile: string;
  filterContactId: number;
  filterCompany: string;
  filterName: string;
  menuItems: MenuItem[];
  displayDocDialog = false;
  editingEnabled = true;
  filterEnabled = true;
  currentUser: User;
  loadUserType: number;
  interval: any;
  fileURL = `${environment.apiUrl}/api/docfiles.php` + '?download_file=';

  constructor(
    private contactService: ContactService,
    private docService: DocFileService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    ) { }

  ngOnInit(): void {
    this.c = Constants;
    this.loading = true;
    this.searchBar = false;
    this.loadUserType = 0;
    this.authenticationService.currentUser.subscribe(
      (user) => {
        if(user){ // onLogout == NULL
          this.currentUser = user;
          if(user.type === this.c.USER_TYPE_TAX ){
            this.loadUserType = 2; // carrier
            this.editingEnabled = false;
        //    this.filterEnabled = false;
          }
        }
      });
    this.contactService.getAll(this.loadUserType)
      .then( list => {
        this.contactList = list;
        if(this.currentUser.type !== this.c.USER_TYPE_TAX ){
          this.contactList.forEach( c => {
            c.consigneeOfName = this.getConsigneeList( c.consigneeOf);
          });
        }
        this.loading = false;
      });
      this.menuItems = [];
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => { // route parameter /contacts/id
      if ( params.id !== undefined ) {
        this.filterContactId = params.id ;
        this.waitLoadComplete();
        this.searchBar = true;
      } else { this.filterContactId = 0; }
    });
  }

  waitLoadComplete() {
    if ( this.loading ) {
      setTimeout( () => { this.waitLoadComplete(); }, 100);
    } else {
      if (this.filterContactId!==0) {
        this.contactService.getById(this.filterContactId)
        .then( c=> {
          if ( c.company !=='' ) {
            this.tblView.filters = {company: {value: c.company, matchMode: 'contains'}};
            this.filterCompany = c.company;
          }else {
            this.tblView.filters = {name: {value: c.name, matchMode: 'contains'}};
            this.filterName = c.name;
          }
        });

        this.tblView.expandedRowKeys = {};
        this.tblView.expandedRowKeys[this.filterContactId] = true;
        this.loadDocList(Number(this.filterContactId));
        setTimeout( () => { this.scrollToId(this.filterContactId); },100 );
      }
    }
  }

  scrollToId(id: number) {
    const el = this.rowElement.find(r => r.nativeElement.getAttribute('id') === id);
    el.nativeElement.scrollIntoView({behavior: 'smooth', inline: 'start', block: 'start'});
  }

  onNewContact() {
    this.tmpContact = this.contactService.getNew();
    this.contactEdit.show(this.tmpContact);
  }

  expandClick(rowData, tblObj) {
    if (tblObj.expandedRowKeys[rowData.id] != null) { // colapse
      delete tblObj.expandedRowKeys[rowData.id];
      tblObj.expandedRowKeys = {};

    } else { // expand
      tblObj.expandedRowKeys = {};
      tblObj.expandedRowKeys[rowData.id] = true;
      this.loadDocList(Number(rowData.id));
      setTimeout( () => { this.scrollToId(rowData.id); },200 );
    }
  }

  onMenuShow( event, menu, contact: Contact, tblObj) {
    this.menuItems = [{
      label: 'Options',
      items: [
        {label: 'Expand row', icon: 'fas fa-expand', command: () => {  this.expandClick(contact, tblObj); }},
        {label: 'Edit row', icon: 'fas fa-edit', command: () => { this.onContactEdit(event, contact) }},
        {separator: true }
      ]}
    ];
    /*
    if( contact.phone1 ){
      this.menuItems[0].items.push({label: contact.phone1, icon: 'fas fa-sms',
        command: () => { this.onSendSMS(event, contact.phone1) }});
    }
    if( contact.phone2 ){
      this.menuItems[0].items.push({label: contact.phone2, icon: 'fas fa-sms',
        command: () => { this.onSendSMS(event, contact.phone2) }});
    } */

    menu.toggle(event);
    event.stopPropagation();
    event.preventDefault();

  }

  onToggleCntSearchBar( tbl, event ) {
    event.preventDefault();
    this.contactService.getTypeList()
      .then ( list => {
        list.forEach( it => {
          it.value = it.typeName;
          it.label = it.typeName;
        } );
        this.typeList = list;
      });

    if (this.searchBar === true) {
      this.filterContactId = 0;
      this.filterCompany = '';
      this.filterName = '';
      this.searchBar = false;
      tbl.reset();
    } else {
      this.searchBar = true;
    }

  }

  loadDocList(id: number) {
    this.docService.getFileList( 1, id )
      .subscribe( list => {
        this.docList = list;
        this.docList.forEach( i=> {
          if(i.type === 'null') i.type = '';
        });
      });

  }

  onContactEdit(event, row) {
    event.stopPropagation();
    event.preventDefault();
    this.loadDocList(row.id);
    this.tmpContact = row;
    this.contactEdit.show(this.tmpContact);
  }

  onDocumentEdit(event, contact) {
    const id = Number(contact.id);
    this.docEdit.show( 1, id); // 1 - contact module id
  }

  onContactNewSave(id: number) {
    this.loading = true;
    this.contactService.getAll(0)
    .then( list => {
      this.contactList = list;
      this.contactList.forEach( c => {
        c.consigneeOfName = this.getConsigneeList( c.consigneeOf);
      });
      this.loading = false;
    });
  }

  onContactUpdateSave(id: number) {
    this.contactService.getById(id)
    .then( cont => {
      cont.consigneeOfName = this.getConsigneeList( cont.consigneeOf );
      const index = this.contactList.findIndex(ct => ct.id === id);
      this.contactList[index] = cont;
     } );
  }

  toggleOverlay(event, overl, txt) {
    event.stopPropagation();
    event.preventDefault();
    this.overlayText = txt;
    overl.toggle(event);
  }

  showDocViewPanel(event, contId) {
    event.stopPropagation();
    event.preventDefault();
    this.loadDocList(contId);
    this.displayDocDialog = true;
  }

  onDocListChanged(id) {
    this.loadDocList(id);
  }

  onSendSMS(event, phone) {
    event.stopPropagation();
    event.preventDefault();
    const sms: SMSMessage = {to: phone, content: ''};
    this.smsEdit.show(sms);
  }

  getConsigneeList( idList: string) {
    let str = '';
    let sep = '';
    if(idList) {
      const arr = idList.split(',');
      arr.forEach( el => {
        const sub = this.contactList.find( c => Number(c.id) === Number(el)).clientId;
        str = str + sep + sub;
        sep = ', ';
      });
    }
    return str;
  }


}
