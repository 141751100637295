<div class="content-section implementation"  dndDropzone [dndAllowExternal]="true"  (dndDrop)="onDrop($event)"  [style]="{width: tableWidth}">
  <p-table #tblObj [value]="orders"  [autoLayout]="true"  [scrollable]="true" [loading]="loading" [filterDelay]="700" [resizableColumns]="true"
    dataKey="id" rowHover="true" [scrollHeight]="rowExpanded? '70vh' : '60vh' "
    [virtualScroll]="true" [lazy]="true" (onLazyLoad)="loadOrdersLazy($event)" [rows]="50" [virtualRowHeight]="40">
    <ng-template pTemplate="caption">
      <div class="box2" [style.width.%]="40">
        <div class="p-d-flex">
        <button *ngIf="orderEditingEnabled && !clipbEnabled" type="button" pButton pRipple icon="pi pi-plus" (click)="onNewOrder()"></button>
        <button type="button" pButton pRipple icon="far fa-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"
          style="margin-left: 10px;" pTooltip="XLS" tooltipPosition="bottom"></button>
        <button *ngIf="orderEditingEnabled" type="button" pButton pRipple class="p-button-success p-mr-2" (click)="clipboardEnable()"
          style="margin-left: 10px;"  icon="far fa-clipboard" pTooltip="Copy/Paste" tooltipPosition="bottom"></button>

        </div>
      </div>
      <div class="box2" [style.width.%]="40" >
        <div class="cap-hdr" style="text-align: center;"><i style="margin-right: 20px;" class="fas fa-car"></i>Auction orders [{{totalRecords}}]</div>
      </div>
      <div class="box2" [style.width.%]="10" style="text-align: right;" >
        <div *ngIf="currentUser.type===c.USER_TYPE_ADMIN">
          <button type="button" pButton pRipple icon="pi pi-filter"(click)="toggleGlobalFilter($event, opgf)" class="p-button-outlined "
            [ngClass]="(globalConsigneeFilter=='' && globalAuctionFilter==''
              && globalAuctionAccFilter=='' && globalNoDocFilter=='') ? 'p-button-secondary' : 'p-button-danger' "
            style="margin-right: 10px;" pTooltip="Advanced filter" tooltipPosition="bottom"></button>
        </div>
      </div>

      <div class="box2" [style.width.%]="10" >
        <div *ngIf="currentUser.type===c.USER_TYPE_CLIENT || currentUser.type===c.USER_TYPE_ADMIN " style="background-color: #fFfFfF; padding-left: 10px; ">
          <div>Outstanding balance:</div>
          <div>{{outstandingBalance | currency: 'USD' }}</div>
        </div>
        <div *ngIf="currentUser.type===c.USER_TYPE_MANAGER" style="text-align: right;">
          <button type="button" pButton pRipple icon="pi pi-filter"(click)="toggleGlobalFilter($event, opgf)" class="p-button-outlined "
            [ngClass]="(globalConsigneeFilter=='' && globalAuctionFilter==''
              && globalAuctionAccFilter=='' && globalNoDocFilter=='') ? 'p-button-secondary' : 'p-button-danger' "
            style="margin-right: 10px;" pTooltip="Advanced filter" tooltipPosition="bottom"></button>
        </div>
      </div>

    </ng-template>
    <ng-template pTemplate="header" >
        <tr>
          <th style="flex: 0 0 45px">
            <a  *ngIf="!clipbEnabled" href="#" (click)="onToggleSearchBar(tblObj,$event)" ><i class="pi pi-search"></i></a>
          </th>
          <th  style="flex: 0 0 100px;">Date</th>
          <th pResizableColumn style="flex: 0 0 150px;" *ngIf="enabledColumns.location">Location</th>
          <th style="flex: 0 0 80px;">Buyer</th>
          <th style="flex: 0 0 110px;">Lot</th>
          <th style="flex: 0 0 60px;" *ngIf="enabledColumns.lotTag">Tag</th>
          <th pResizableColumn  >Vehicle</th>
          <th style="flex: 0 0 200px" >
            <span *ngIf="currentUser.type===c.USER_TYPE_ADMIN  ||
            currentUser.type===c.USER_TYPE_MANAGER" style="padding-right: 10px;">
            <p-checkbox [(ngModel)]="selectionEnabled" [binary]="true" (onChange)="onSelEnableChange($event)"></p-checkbox></span><span>VIN</span></th>
          <th style="flex: 0 0 120px;" *ngIf="enabledColumns.clientId">Client ID</th>
          <th style="flex: 0 0 130px;" *ngIf="enabledColumns.client">Consignee</th>
          <th style="flex: 0 0  80px;" *ngIf="enabledColumns.color">Color</th>
          <th style="flex: 0 0 45px; text-align: center;" *ngIf="enabledColumns.key"><i class="fas fa-key"></i></th>
          <th style="flex: 0 0 45px; text-align: center;"><i class="far fa-id-card"></i></th>
          <th style="flex: 0 0 45px; text-align: center;" *ngIf="enabledColumns.fuel"><i class="fas fa-tint"></i></th>
          <th style="flex: 0 0 150px; text-align: center;" *ngIf="enabledColumns.containers">Container</th>
          <th style="flex: 0 0 95px; white-space: pre-wrap;" *ngIf="enabledColumns.auctionPrice">Auction price</th>
          <th style="flex: 0 0 80px; white-space: pre-wrap;" *ngIf="enabledColumns.buyerFee">Buyer fee</th>
          <th style="flex: 0 0 80px; white-space: pre-wrap;" *ngIf="enabledColumns.storageFee">Storage fee</th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.orderId">Order ID</th>
          <th pResizableColumn *ngIf="enabledColumns.carrier">Carrier</th>
          <th style="flex: 0 0 80px; white-space: pre-wrap;" *ngIf="enabledColumns.deliveryPrice">Delivery price</th>
          <th style="flex: 0 0 80px; white-space: pre-wrap;" *ngIf="enabledColumns.buyerPay">Delivery rate</th>
          <th style="flex: 0 0 90px; white-space: pre-wrap;" *ngIf="enabledColumns.containerPrice">Container price</th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.miscFee">Misc. fee</th>
          <th style="flex: 0 0 95px;" *ngIf="enabledColumns.subtotal">Subtotal</th>
          <th style="flex: 0 0 100px">Yard</th>
          <th style="flex: 0 0 140px;">Status</th>
        </tr>
        <tr *ngIf="searchBar">
          <th style="flex: 0 0 45px"></th>
          <th style="text-align: center; flex: 0 0 100px;"><p-columnFilter type="date" field="date" display="menu" hideOnClear="true"></p-columnFilter></th>
          <th  style="flex: 0 0 150px;" *ngIf="enabledColumns.location">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'location', 'contains')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th style="flex: 0 0 80px"><input pInputText type="text" (input)="tblObj.filter($event.target.value, 'buyerNr', 'startsWith')" class="ui-column-filter" style="width: 100%;"></th>
          <th  style="flex: 0 0 110px"><input pInputText type="text" (input)="tblObj.filter($event.target.value, 'lot', 'startsWith')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 60px;" *ngIf="enabledColumns.lotTag"></th>
          <th ><input pInputText type="text" (input)="tblObj.filter($event.target.value, 'car', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 200px"><input pInputText type="text" (input)="tblObj.filter($event.target.value, 'vin', 'contains')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 120px" *ngIf="enabledColumns.clientId">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'consignee', 'startsWith')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th style="flex: 0 0 130px" *ngIf="enabledColumns.client">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'client', 'startsWith')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th style="flex: 0 0 80px" *ngIf="enabledColumns.color">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'color', 'startsWith')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th style="flex: 0 0 45px;" *ngIf="enabledColumns.key"></th>
          <th style="flex: 0 0 45px;"></th>
          <th style="flex: 0 0 45px;" *ngIf="enabledColumns.fuel"></th>
          <th style="flex: 0 0 150px;" *ngIf="enabledColumns.containers">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'containerNr', 'startsWith')" class="ui-column-filter" style="width: 100%;"></th>
          <th style="flex: 0 0 95px;" *ngIf="enabledColumns.auctionPrice"></th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.buyerFee"></th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.storageFee"></th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.orderId">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'orderId', 'startsWith')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th *ngIf="enabledColumns.carrier">
            <input pInputText type="text" (input)="tblObj.filter($event.target.value, 'carrier', 'startsWith')" class="ui-column-filter" style="width: 100%;">
          </th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.deliveryPrice"></th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.buyerPay"></th>
          <th style="flex: 0 0 90px;" *ngIf="enabledColumns.containerPrice"></th>
          <th style="flex: 0 0 80px;" *ngIf="enabledColumns.miscFee"></th>
          <th style="flex: 0 0 95px;" *ngIf="enabledColumns.subtotal"></th>
          <th style="flex: 0 0 100px;"><p-dropdown [options]="filterYards" (onChange)="tblObj.filter($event.value, 'yard', 'equals')"
            styleClass="ui-column-filter"   [style]="{'width':'100%'}" appendTo="body"></p-dropdown></th>
          <th style="flex: 0 0 140px;"><p-dropdown [options]="filterStatus" (onChange)="tblObj.filter($event.value, 'dispatchStatus', 'equals')"
            styleClass="ui-column-filter"   [style]="{'width':'100%'}" appendTo="body"></p-dropdown></th>
          </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-expanded="expanded" >
        <tr class="ui-selectable-row ui-tr" #row [attr.id]="rowData.id" (click)="expandClick(rowData, tblObj)"
          [style]="rowData.inEditBy=='0'? null : 'background-color: #c4afff;' " >
            <td  style="flex: 0 0 45px" *ngIf="rowData.inEditBy=='0'">
              <div *ngIf="!clipbEnabled"><a href="#" (click)="onMenuShow($event, menu, rowData, tblObj)" ><i class="fas fa-bars"></i></a></div>
              <div *ngIf="clipbEnabled"><p-checkbox [(ngModel)]="selectedOrdersId"
                name="cargroup2" value="{{rowData.id}}" (click)="onCBSelClick($event)"></p-checkbox></div>
            </td>
            <td style="flex: 0 0 45px" *ngIf="rowData.inEditBy!='0'" pTooltip="{{rowData.inEditByName}}">
              <i [class]="'fas fa-edit bl-hdr-ic' " ></i>
            </td>
            <td style="flex: 0 0 100px;">{{rowData.date}}</td>
            <td style="flex: 0 0 150px;" *ngIf="enabledColumns.location"><span *ngIf="rowData.sublot"><a href="#" (click)="toggleOverlay($event, op, rowData.sublot)">
              <i class="fas fa-info-circle"></i></a></span><span class="fa-text">{{rowData.location}}</span></td>
            <td style="flex: 0 0 80px;">
              {{rowData.buyerNr}}</td>
            <td style="flex: 0 0 110px;"><a href="#" [ngClass]="rowData.photo1Cnt? null : 'a-disabled'" ><i class="fas fa-camera" (click)="onPhotoClick($event, 1,rowData.id)" ></i></a>
              <span class="fa-text"><a href="{{rowData.auctionURL}}"  target="_blank">{{rowData.lot}}</a></span></td>
            <td *ngIf="enabledColumns.lotTag" style="flex: 0 0 60px; text-align: center;">
              <div *ngIf="rowData.lotTag" style="width: 30px; padding: 3px; text-align: center;" [style]="rowData.tagStyle">{{rowData.lotTag}}</div></td>
            <td >
              <span style="padding-right: 5px; width: 15px; display: inline-block; text-align: center; vertical-align: middle;">
                <img *ngIf="rowData.auctionIcon" src="../../../assets/images/{{rowData.auctionIcon}}"  width="12" height="12"  />
                </span>
              {{rowData.car}}</td>
            <td style= "flex: 0 0 200px"><span *ngIf="selectionEnabled" style="display: inline-block; padding-right: 10px; width: 30px;">
              <p-checkbox name="cargroup" value="{{rowData.id}}" *ngIf="rowData.client!==''"
                [(ngModel)]="selectedList" (click)="onCBClick($event)">Paid</p-checkbox>
            </span><span>{{rowData.vin}}</span>
              <div *ngIf="rowData.invId"><a href="#" (click)="invoiceView($event, rowData)">
                <i style="margin-right: 3px;" class="far fa-fw fa-file-alt"></i></a>{{rowData.invNr}}</div></td>
            <td style="flex: 0 0 120px;" *ngIf="enabledColumns.clientId">{{rowData.consignee}}</td>
            <td style="flex: 0 0 130px;" *ngIf="enabledColumns.client">
              <span *ngIf="selectionEnabled && rowData.consigneeCountry" style="padding-right: 5px;">
                <img src="../../../assets/images/flag_placeholder.png" style="width: 20px; text-align: center;"
                  [class]="'flag flag-' + rowData.consigneeCountry.toLowerCase()"   />
                </span>
              <span>{{rowData.client}}</span></td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.color">{{rowData.color}}</td>
            <td style="flex: 0 0 45px; text-align: center;" *ngIf="enabledColumns.key"><div *ngIf="(rowData.keyFobD!==null)">
              <span [class]=" rowData.keyFobD ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                {{ rowData.keyFobD ? '✔':'✖'}} </span>
              </div></td>
            <td style="flex: 0 0 45px; text-align: center;" *ngIf="!rowData.titleType">
              <span *ngIf="(rowData.titleD!==null)" [class]="rowData.titleD ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                {{ rowData.titleD ? '✔':'✖'}} </span>
            </td>
            <td style="flex: 0 0 45px; text-align: center;" *ngIf="rowData.titleType"><a href="#" (click)="toggleOverlay($event, op, rowData.titleType)">
              <span *ngIf="(rowData.titleD!==null)" [class]="rowData.titleD ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                {{ rowData.titleD ? '🛈':'⚠'}} </span> </a></td>

            <td style="flex: 0 0 45px; text-align: center;" *ngIf="enabledColumns.fuel">
              <span [class]="rowData.fuelClass" (click)="toggleOverlay($event, op, rowData.fuel)">{{rowData.fuelSymbol}}</span>
            </td>
            <td style="flex: 0 0 150px;" *ngIf="enabledColumns.containers">
              <div><span><a href="#" [ngClass]="rowData.photo3Cnt? null : 'a-disabled'">
              <i class="fas fa-camera" (click)="onPhotoClick($event, 3, rowData.containerId)"></i></a></span>
              <span class="fa-text" >{{rowData.containerNr}}</span>
              <span *ngIf="rowData.tracking"><a href="{{rowData.containerTrackingURL}}" target="_blank" >
                <i class="fas fa-external-link-alt"  style="padding-left: 6px;"></i></a></span>
            </div> </td>
            <td style="flex: 0 0 95px;" *ngIf="enabledColumns.auctionPrice" [style]="rowData.paidByClient? 'color: darkgrey;' : null" >{{
                (rowData.paidByClient && currentUser.type==c.USER_TYPE_CLIENT? 0 : rowData.auctionPrice)  | currency: 'USD' }}</td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.buyerFee">{{rowData.buyerFee  | currency: 'USD' }}</td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.storageFee">{{rowData.storageFee  | currency: 'USD' }}</td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.orderId">{{rowData.orderId}}</td>
            <td *ngIf="enabledColumns.carrier">{{rowData.carrier}}</td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.deliveryPrice" [className]="rowData.priceClassName" >{{rowData.deliveryPrice2 | currency: 'USD' }}</td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.buyerPay">{{rowData.buyerPay  | currency: 'USD' }}</td>
            <td style="flex: 0 0 90px;" *ngIf="enabledColumns.containerPrice" >{{rowData.containerPrice  | currency: 'USD' }}</td>
            <td style="flex: 0 0 80px;" *ngIf="enabledColumns.miscFee" >{{rowData.miscFee  | currency: 'USD' }}</td>
            <td style="flex: 0 0 95px;" *ngIf="enabledColumns.subtotal" >{{rowData.orderTotal  | currency: 'USD' }}</td>
            <td style="flex: 0 0 100px">
              <a href="#" [ngClass]="rowData.photo2Cnt? null : 'a-disabled'">
                <i class="fas fa-camera"  (click)="onPhotoClick($event, 2,rowData.id)"></i></a><span class="fa-text">{{rowData.yard}}</span></td>

            <td style="flex: 0 0 140px;">
              <ng-container *ngIf="rowData.dispatchStatus===0"  >
                <div style="color: darkgray;">No information</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===1"  >
                <div >Paid on:</div>
                <div>{{rowData.paymentDate}}</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===2"  >
                <div style="font-weight: bold;">Dispatched</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===3"  >
                <div>Picked up:</div>
                <div>{{rowData.pickupDate}}</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===4"  >
                <div style="font-weight: bold;">Delivered: </div>
                <div>{{rowData.deliveryDate}}</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===5"  >
                  <div style="font-weight: bold;">Consolidated</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===6"  >
                  <div style="font-weight: bold;">Loaded<span *ngIf="rowData.loadDate"> on:<div style="font-weight: normal;">
                    {{rowData.loadDate}}</div></span>
                  </div></ng-container>

              </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" >
      <tr style="height:40px">
          <td *ngFor="let col of [].constructor(enabledColumns.colSpan)">
              <div class="loading-text"></div>
              <p-skeleton [ngStyle]="{'width': '100%'}"></p-skeleton>
          </td>
      </tr>
  </ng-template>

    <ng-template pTemplate="summary" *ngIf="orderEditingEnabled">
      <button *ngIf="!clipbEnabled" type="button" pButton icon="pi pi-plus" (click)="onNewOrder()" label="&nbsp;Add new order" style="margin-right: 10px;"></button>
      <p-splitButton #spBut *ngIf="selectedList.length!==0 && !clipbEnabled" label="&nbsp;Selection" icon="fas fa-ellipsis-v" (onClick)="onSelection($event, spBut)"
        [model]="selItemsMenu"></p-splitButton>
      <button *ngIf="clipbEnabled" type="button" pButton icon="far fa-clipboard" (click)="onClipbCopy($event)" label="&nbsp;Copy selected" style="margin-right: 10px;"></button>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData>
      <tr>
          <td [attr.colspan]="enabledColumns.colSpan" style="background-color:lightgrey;" >

            <table [@inOutAnimationWidth]  >
              <tr>
                <td style="vertical-align: top;">
                  <div class="box2" style="margin-left: 20px; width: 400px;">
                    <div class="card card-w-title" style="padding: 5px;" >
                      <div class="bl-hdr">Auction info<i *ngIf="(orderEditingEnabled && rowData.inEditBy=='0') || rowData.inEditBy==currentUser.id"
                        (click)="onEditAuctionInfoToggle(rowData)"
                       [class]="(this.editAuctionInfo) ? 'fas fa-times bl-hdr-ic' : 'fas fa-edit bl-hdr-ic' " ></i></div>
                      <ng-container *ngTemplateOutlet="this.editAuctionInfo? AuctionInfoEdTpl : AuctionInfoTpl "></ng-container>
                      <ng-template #AuctionInfoTpl >
                        <div class="zbrcont">
                          <div><div class="box2 bl-col1">Date:</div><div class="box2">{{rowData.date}}</div></div>
                          <div><div class="box2 bl-col1">Auction:</div><div class="box2">
                            <div class="country-item">
                              <span  *ngIf="rowData.auctionIcon" style="width: 15px; display: inline-block; text-align: center; vertical-align: middle;">
                                <img  src="../../../assets/images/{{rowData.auctionIcon}}"  width="12" height="12"  />
                              </span>
                              <div style="padding-left: 5px;">{{rowData.auctionName}}</div>
                            </div>
                          </div></div>
                          <div><div class="box2 bl-col1">Auction acc.:</div><div class="box2">{{rowData.auctionAccName}}</div></div>
                          <div><div class="box2 bl-col1">Buyer:</div><div class="box2">{{rowData.buyerNr}}</div></div>
                          <div><div class="box2 bl-col1">Lot:</div><div class="box2">{{rowData.lot}}</div></div>
                          <div><div class="box2 bl-col1">Client:</div><div class="box2">{{rowData.consignee}}</div></div>
                          <div><div class="box2 bl-col1">Consignee:</div>
                            <div *ngIf="!consigneeEdit" class="box2" class="ellipsis" [style]="{'width':'260px'}">{{rowData.client}}
                              <span *ngIf="!rowData.client && currentUser.type==c.USER_TYPE_CLIENT"
                                  style="float: right; margin-right: 5px; vertical-align: middle;" >
                                <a href="#" (click)="onClientConsigneeEdit(rowData)"><i class="fas fa-edit"></i></a>
                               </span>
                            </div>
                            <div *ngIf="consigneeEdit" class="box2" [style]="{'width':'250px'}">
                              <p-dropdown [options]="consigneeList" [(ngModel)]="tmpOrder.client"  [style]="{'width':'200px'}"
                              scrollHeight="210px" appendTo="body" ></p-dropdown>
                              <button pButton pRipple type="button" (click)="consigneeEdit=false; onSaveAuctionInfo(); "
                                icon="pi pi-check" class="p-button-rounded p-button-text"></button>
                              <button pButton pRipple type="button" icon="pi pi-times" (click)="this.consigneeEdit=false;"
                                style="margin-left: -10px;" class="p-button-rounded p-button-danger p-button-text"></button>
                            </div>
                          </div>
                          <div><div class="box2 bl-col1">Yard:</div><div class="box2">{{rowData.yard}}</div></div>
                          <div><div class="box2 bl-col1">Location:</div><div class="box2" class="ellipsis" [style]="{'width':'245px'}">{{rowData.location}}</div></div>
                          <div><div class="box2 bl-col1">Sublot:</div><div class="box2" class="ellipsis" [style]="{'width':'245px'}">{{rowData.sublot}}&nbsp;</div></div>
                          <ng-container *ngIf="pricesEnabled">
                            <div><hr></div>
                            <div><div class="box2 bl-col1">Delivery rate:</div><div class="box2"><b>{{rowData.buyerPay | currency: 'USD' }}</b></div></div>
                            <div><div class="box2 bl-col1">Buyer fee:</div><div class="box2"><b>{{rowData.buyerFee | currency: 'USD' }}</b></div></div>
                            <div><div class="box2 bl-col1">Cl. storage fee:</div><div class="box2"><b>{{rowData.storageFee | currency: 'USD' }}</b></div></div>
                            <div *ngIf="currentUser.type==c.USER_TYPE_ADMIN || currentUser.type==c.USER_TYPE_MANAGER">
                              <div class="box2 bl-col1">Our storage fee:</div><div class="box2"><b>{{rowData.storageFee2 | currency: 'USD' }}</b></div>
                            </div>
                            <div><div class="box2 bl-col1">Container fee:</div><div class="box2"><b>{{rowData.containerPrice  | currency: 'USD' }}</b></div></div>
                            <div><div class="box2 bl-col1">Misc. fee:</div><div class="box2"><b>{{rowData.miscFee | currency: 'USD' }}</b></div></div>
                            <div><div class="box2 bl-col1">Misc. descr.:</div><div class="box2" class="ellipsis" [style]="{'width':'245px'}" >{{rowData.miscDescr}}&nbsp;</div></div>
                          </ng-container>
                        </div>
                      </ng-template>
                      <ng-template #AuctionInfoEdTpl >
                        <div><div class="box2 bl-col1">Date:</div><div class="box2" >
                          <p-calendar [(ngModel)]="tmpOrder.date" dateFormat="mm/dd/yy" [showIcon]="true"	appendTo="body" [inputStyle]="{'width':'210px'}"></p-calendar></div></div>
                        <div><div class="box2 bl-col1">Auction/acc:</div>
                          <div class="box2" style="width: 125px;">
                          <p-dropdown [options]="auctionList" [(ngModel)]="tmpOrder.auctionId" appendTo="body"
                          optionLabel="name" optionValue="id" placeholder="Select..." [style]="{'width':'90%'}" required>
                          <ng-template let-auction pTemplate="item">
                            <div class="country-item">
                              <span style="width: 15px; display: inline-block; text-align: center; vertical-align: middle;">
                                <img *ngIf="auction.icon" src="../../../assets/images/{{auction.icon}}"  width="12" height="12"  />
                              </span>
                              <div style="padding-left: 5px;" >{{auction.name}}</div>
                            </div>
                        </ng-template>
                        </p-dropdown>
                        </div>
                        <div class="box2" style=" width: 125px;">
                          <p-dropdown [options]="auctionAccList" [(ngModel)]="tmpOrder.auctionAcc" appendTo="body"
                          optionLabel="name" optionValue="id" placeholder="Select..." [style]="{'width':'100%'}" required></p-dropdown>
                        </div></div>

                        <div><div class="box2 bl-col1">Buyer:</div><div class="box2">
                          <input pInputText id="buyerNr" [(ngModel)]="tmpOrder.buyerNr" [style]="{'width':'100%'}" required/></div></div>
                        <div><div class="box2 bl-col1">Lot:</div><div class="box2">
                          <input pInputText id="lot" [(ngModel)]="tmpOrder.lot" [style]="{'width':'245px'}" required /></div></div>
                        <div><div class="box2 bl-col1">Client:</div><div class="box2">
                          <p-dropdown [options]="intContactList2" [(ngModel)]="tmpOrder.consignee" appendTo="body"
                            [style]="{'width':'100%'}"  ></p-dropdown>
                          </div></div>
                        <div><div class="box2 bl-col1">Consignee:</div><div class="box2">
                          <p-dropdown [options]="consigneeList" [(ngModel)]="tmpOrder.client" appendTo="body"
                            [style]="{'width':'100%'}" [disabled]="userPrivilege.order_fld_ed_consignee!=='1'"></p-dropdown>
                          </div></div>

                        <div class="box2 bl-col1">Yard:</div><div class="box2">
                          <p-dropdown [options]="yards" [(ngModel)]="selectedYard" appendTo="body"
                          optionLabel="yard" placeholder="Select..." [style]="{'width':'100%'}" required></p-dropdown>
                        </div>
                        <div><div class="box2 bl-col1">Location:</div><div class="box2">
                          <input pInputText  [(ngModel)]="tmpOrder.location"  [style]="{'width':'100%'}" required/></div></div>
                        <div><div class="box2 bl-col1">Sublot:</div><div class="box2">
                            <input pInputText id="sublot" [(ngModel)]="tmpOrder.sublot" [style]="{'width':'100%'}"/></div></div>
                        <div><div class="box2 bl-col1">Delivery rate:</div><div class="box2">
                          <div class="ui-inputgroup">
                            <span class="ui-inputgroup-addon">$</span>
                            <input pInputText id="auctionPrice" [(ngModel)]="tmpOrder.buyerPay"></div></div></div>
                        <div><div class="box2 bl-col1">Buyer fee:</div><div class="box2">
                          <div class="ui-inputgroup">
                            <span class="ui-inputgroup-addon">$</span>
                            <input pInputText id="buyerFee" [(ngModel)]="tmpOrder.buyerFee"></div></div></div>
                        <div><div class="box2 bl-col1">Cl. storage fee:</div><div class="box2">
                          <div class="ui-inputgroup">
                            <span class="ui-inputgroup-addon">$</span>
                            <input pInputText id="storageFee" [(ngModel)]="tmpOrder.storageFee"></div></div></div>
                        <div *ngIf="currentUser.type==c.USER_TYPE_ADMIN || currentUser.type==c.USER_TYPE_MANAGER">
                          <div class="box2 bl-col1">Our storage fee:</div><div class="box2">
                          <div class="ui-inputgroup">
                            <span class="ui-inputgroup-addon">$</span>
                            <input pInputText id="storageFee" [(ngModel)]="tmpOrder.storageFee2"></div></div></div>
                        <div><div class="box2 bl-col1">Container fee:</div><div class="box2">
                          <div class="ui-inputgroup">
                            <span class="ui-inputgroup-addon">$</span>
                            <input pInputText id="containerPrice" [(ngModel)]="tmpOrder.containerPrice"></div></div></div>
                        <div><div class="box2 bl-col1">Misc. fee:</div><div class="box2">
                          <div class="ui-inputgroup">
                            <span class="ui-inputgroup-addon">$</span>
                            <input pInputText id="miscFee" [(ngModel)]="tmpOrder.miscFee"></div></div></div>
                        <div><div class="box2 bl-col1">Misc. descr.:</div><div class="box2">
                          <input pInputText id="miscDescr" [(ngModel)]="tmpOrder.miscDescr" maxlength="200" [disabled]="tmpOrder.miscFee==0"
                            class="ellipsis" [style]="{'width':'100%'}"/></div></div>

                         <div><hr></div>
                          <div style="text-align: center;"><button type="button" pButton icon="pi pi-check" (click)="onSaveAuctionInfo()" label="Save changes"></button></div>
                      </ng-template>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: top;">
                  <div class="box2" style="margin-left: 20px; width: 400px; vertical-align:top;">
                    <div class="card card-w-title" style="padding: 5px;">
                      <div class="bl-hdr">Vehicle info<i *ngIf="(orderEditingEnabled && rowData.inEditBy=='0') || rowData.inEditBy==currentUser.id"
                        (click)="onEditCarInfoToggle(rowData)"
                         [class]="(this.editCarInfo) ? 'fas fa-times bl-hdr-ic' : 'fas fa-edit bl-hdr-ic' " ></i></div>
                        <ng-container *ngTemplateOutlet="this.editCarInfo? CarInfoEdTpl : CarInfoTpl "></ng-container>
                        <ng-template #CarInfoTpl >
                          <div class="zbrcont">
                            <div><div class="box2 bl-col1">Model:</div><div class="box2" class="ellipsis" style="width: 240px;" >{{rowData.car}}</div></div>
                            <div><div class="box2 bl-col1">VIN:</div><div class="box2">{{rowData.vin}}</div></div>
                            <div><div class="box2 bl-col1">Color:</div><div class="box2">{{rowData.color}}</div></div>
                            <div><div class="box2 bl-col1">Fuel:</div><div class="box2">{{rowData.fuel}}</div></div>
                            <div><div class="box2 bl-col1">Keys:</div><div class="box2" >
                              <span *ngIf="(rowData.keyFob!==null)" [class]="(rowData.keyFob) ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                                {{ rowData.keyFob ? '✔':'✖'}} </span></div></div>

                            <div><div class="box2 bl-col1">Title:</div><div class="box2"  style=" vertical-align: middle;">
                              <span *ngIf="(rowData.title!==null)" [class]="(rowData.title) ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                                {{ rowData.title ? '✔':'✖'}} </span>

                              <span style="display: inline-block;">
                              <span *ngIf="rowData.titleType" class="ellipsis" style="margin-left: 5px; width: 220px;">[{{rowData.titleType}}]</span>
                              <span *ngIf="docListTitle.length>0"  style=" margin-right: 5px; margin-left: 5px;">
                                <a href="#" (click)="showTitleDocViewPanel($event,  rowData.id)" ><i class="far fa-file-alt"></i></a></span>
                                </span>
                            </div></div>
                            <div *ngIf="pricesEnabled"><div class="box2 bl-col1">Value:</div><div class="box2"><b>{{rowData.auctionPrice | currency: 'USD' }}</b>
                              <span *ngIf="rowData.paidByClient" style="padding-left: 10px;">[Paid by client]</span></div></div>
                            <div><div class="box2 bl-col1">{{(currentUser.type!==c.USER_TYPE_CLIENT)?'Client info':'Notes'}}:</div>
                            <div class="box2" style="width: 250px; white-space: pre-wrap; ">{{rowData.clientInfo}}</div></div>
                            <div *ngIf="currentUser.type!==c.USER_TYPE_CLIENT"><div class="box2 bl-col1">Comment:</div><div class="box2" style="width: 250px; white-space: pre-wrap; ">
                              {{rowData.comment}}</div></div>
                          </div>
                        </ng-template>
                        <ng-template #CarInfoEdTpl >
                          <div><div class="box2 bl-col1">Year/Model:</div><div class="box2">
                            <input pInputText  [(ngModel)]="tmpOrder.year"  style="width: 50px;" placeholder="Year" required/>
                            <input pInputText  [(ngModel)]="tmpOrder.model"  style="width: 200px; margin-left: 5px;" placeholder="Model" required/></div></div>
                          <div><div class="box2 bl-col1">VIN:</div><div class="box2">
                            <input pInputText  [(ngModel)]="tmpOrder.vin"  [style.width.%]="100"  required/></div></div>
                          <div><div class="box2 bl-col1">Color/Fuel:</div><div class="box2">
                            <input pInputText  [(ngModel)]="tmpOrder.color"   style="margin-right: 5px; width: 120px;"/>
                            <p-dropdown [options]="fuelTypes" [(ngModel)]="tmpOrder.fuel" id="fuel" appendTo="body"
                            [style]="{'width':'125px'}" placeholder="Select fuel.." required></p-dropdown></div></div>
                          <div><div class="box2 bl-col1" >Keys/Title:</div><div class="box2" style="height: 35px;">
                            <div  style="height: 35px;  display: table-cell; vertical-align: middle;">
                              <span class="box2" style="width: 25px;"><i class="fas fa-key"></i></span><p-triStateCheckbox [(ngModel)]="tmpOrder.keyFob"></p-triStateCheckbox>
                              <span class="box2" style="width: 25px;">&nbsp;</span>
                              <span class="box2" style="width: 25px;"><i class="far fa-id-card"></i></span><p-triStateCheckbox [(ngModel)]="tmpOrder.title"></p-triStateCheckbox>
                            </div>
                          </div></div>
                          <div><div class="box2 bl-col1" >Doc type:</div><div class="box2">
                            <div  style="height: 35px; display: table-cell;">
                              <input pInputText  [(ngModel)]="tmpOrder.titleType"  style="width: 205px; margin-right: 10px;" placeholder="Doc type" />
                              <p-button icon="far fa-file-alt" (click)="showTitleDocEditPanel(tmpOrder.id)"></p-button>
                            </div>
                          </div></div>
                          <div ><div class="box2 bl-col1">Value:</div><div class="box2" style="width: 120px;">
                            <div class="ui-inputgroup">
                              <span class="ui-inputgroup-addon">$</span>
                              <input pInputText id="auctionPrice" [(ngModel)]="tmpOrder.auctionPrice" [style]="{'width':'100px'}"></div>
                            </div>
                            <div class="box2" style="padding-left: 10px;"><b>Client paid: </b>
                              <p-checkbox [disabled]="tmpOrder.auctionPrice==0" [binary]="true" [(ngModel)]="tmpOrder.paidByClient"></p-checkbox></div>
                          </div>
                          <div><div class="box2 bl-col1">Client info:</div><div class="box2">
                            <input pInputText  [(ngModel)]="tmpOrder.clientInfo"  [style]="{'width':'250px'}" /></div></div>
                          <div><div class="box2 bl-col1">Comment:</div><div class="box2">
                            <input pInputText  [(ngModel)]="tmpOrder.comment"  [style]="{'width':'250px'}" /></div></div>
                          <div><hr></div>
                          <div style="text-align: center;"><button type="button" pButton icon="pi pi-check" (click)="onSaveCarInfo()" label="Save changes"></button></div>
                        </ng-template>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: top;">
                  <div class="box2" style="margin-left: 20px; width: 400px; vertical-align:top;" >
                    <div class="card card-w-title" style="padding: 5px;">
                      <div class="bl-hdr">{{(currentUser.type!==c.USER_TYPE_CLIENT)?'Dispatch info':'Shipment info'}}
                        <i *ngIf="((orderEditingEnabled || currentUser.type===c.USER_TYPE_YARD)  && rowData.inEditBy=='0') || rowData.inEditBy==currentUser.id"
                        (click)="onEditDispatchInfoToggle(rowData)"
                         [class]="(this.editDispatchInfo) ? 'fas fa-times bl-hdr-ic' : 'fas fa-edit bl-hdr-ic' " ></i></div>
                        <ng-container *ngTemplateOutlet="this.editDispatchInfo? DispatchInfoEdTpl : DispatchInfoTpl "></ng-container>
                        <ng-template #DispatchInfoTpl >
                            <div><div class="box2 bl-col1 zbr">Payment date:</div><div class="box2 zbr">{{rowData.paymentDate}}&nbsp;</div></div>
                            <div><div class="box2 bl-col1">Pick up date:</div><div class="box2">{{rowData.pickupDate}}</div></div>
                            <div><div class="box2 bl-col1 zbr">Delivery date:</div><div class="box2 zbr">{{rowData.deliveryDate}}&nbsp;</div></div>
                            <div><div class="box2 bl-col1">Keys:</div><div class="box2" >
                              <span *ngIf="(rowData.keyFobD!==null)" [class]="(rowData.keyFobD) ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                                {{ rowData.keyFobD ? '✔':'✖'}} </span></div></div>
                            <div><div class="box2 bl-col1 zbr">Title:</div><div class="box2 zbr" >
                              <span *ngIf="(rowData.titleD!==null)" [class]="(rowData.titleD) ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
                                {{ rowData.titleD ? '✔':'✖'}} </span>&nbsp;</div></div>
                            <div *ngIf="rowData.yard==='NY'"><div class="box2 bl-col1">Lot tag:</div><div class="box2">
                              <div *ngIf="rowData.lotTag" style="width: 50px; padding: 3px; text-align: center;" [style]="rowData.tagStyle">{{rowData.lotTag}}</div></div></div>
                            <div *ngIf="currentUser.type!==c.USER_TYPE_CLIENT && currentUser.type!==c.USER_TYPE_VIEWER">
                              <div><div class="box2 bl-col1">Order ID:</div><div class="box2">{{rowData.orderId}}</div></div>
                              <div><div class="box2 bl-col1 zbr">Carrier:</div><div class="box2 zbr" >
                                <div class="ellipsis" style="width: 200px;">{{rowData.carrier}}</div>&nbsp;
                                <span style="float: right; margin-right: 5px; vertical-align: middle;">
                                <span  *ngIf="overlayCarrierTel">
                                  <a href="#" (click)="showCarrierInfoOverlay($event, op ,selectedCarrier)">
                                  <span class="fa-stack fa-2x"  style="font-size: 0.5em; margin-bottom: 3px;" >
                                    <i class="far fa-square fa-stack-2x"></i>
                                    <i class="fas fa-info fa-stack-1x"></i>
                                  </span></a>
                                </span>
                                <span *ngIf="selectedCarrier"><a [routerLink]="['/contacts']" target="_blank"  [queryParams]="{ id: rowData.carrierId }"
                                 style="padding-left: 5px;" ><i class="pi pi-user"  ></i></a></span>
                                </span>
                              </div></div>
                              <div><div class="box2 bl-col1">Check number:</div><div class="box2">{{rowData.checkNumber}}</div></div>
                              <div *ngIf="pricesEnabled"><div class="box2 bl-col1 zbr">Price:</div>
                                <div class="box2 zbr"><b>{{rowData.deliveryPrice | currency: 'USD' }}&nbsp;</b></div></div>
                              <div><div class="box2 bl-col1">Notes:</div>
                              <div class="box2 ellipsis"  >{{rowData.noteDisp}}</div></div>
                              <div  *ngIf="currentUser.type!==c.USER_TYPE_CLIENT"><div class="box2 bl-col1 zbr">Documents:</div>
                                <div class="box2 zbr"><span *ngIf="docListDispatch.length>0"  style=" margin-right: 5px; margin-left: 5px;">
                                  <a href="#" (click)="showDispatchDocViewPanel($event)" ><i class="far fa-file-alt"></i></a></span>
                                    </div>
                                  </div>
                            </div>
                            <div *ngIf="currentUser.type!==c.USER_TYPE_DISPATCH && currentUser.type!==c.USER_TYPE_YARD">
                              <div><hr></div>
                              <div><div class="box2 bl-col1 zbr">Container:</div>
                                <div class="box2 zbr">{{rowData.containerNr}}&nbsp;{{rowData.size ? '&nbsp;[' + rowData.size + ']' : '' }}
                                  <a style="float: right; margin-right: 5px;" href="#" *ngIf="docListCont.length>0" (click)="showContDocViewPanel($event,  rowData.containerId)">
                                    <i class="far fa-file-alt"></i></a>
                                  </div>
                              </div>
                              <div><div class="box2 bl-col1">Shipping line:</div><div class="box2">{{rowData.shippingLine}}</div></div>
                              <div><div class="box2 bl-col1 zbr">Tracking:</div><div class="box2 zbr">{{rowData.tracking}}&nbsp;
                                <span *ngIf="rowData.tracking"><a href="{{rowData.containerTrackingURL}}" target="_blank" style="float: right; margin-right: 5px; vertical-align: middle;" >
                                  <i class="fas fa-external-link-alt" aria-hidden="true" ></i></a></span>
                              </div></div>
                              <div><div class="box2 bl-col1">Load date:</div><div class="box2">{{rowData.loadDate}}</div></div>
                              <div><div class="box2 bl-col1 zbr">Agent:</div><div class="box2 zbr">{{rowData.destinationAgent}}&nbsp;</div></div>
                              <div><div class="box2 bl-col1">Destination:</div><div class="box2">{{rowData.destination}}</div></div>
                              <div><div class="box2 bl-col1 zbr">Invoice:</div><div class="box2 zbr">{{rowData.invNr}}&nbsp;
                                <span *ngIf="rowData.invNr"><a href="#" (click)="invoiceView($event, rowData)" target="_blank"
                                  style="float: right; margin-right: 5px; vertical-align: middle;" >
                                    <i class="far fa-fw fa-file-alt" aria-hidden="true" ></i></a></span>
                              </div></div>
                            </div>
                        </ng-template>
                        <ng-template #DispatchInfoEdTpl >
                          <div *ngIf="(hideDispatchEdit===false)">
                            <div><div class="box2 bl-col1">Payment date:</div><div class="box2">
                              <p-calendar [(ngModel)]="tmpOrder.paymentDate" dateFormat="mm/dd/yy" [showIcon]="true"
                              appendTo="body" [inputStyle]="{'width':'220px'}"></p-calendar></div></div>
                           <div><div class="box2 bl-col1">Pickup date:</div><div class="box2">
                             <p-calendar [(ngModel)]="tmpOrder.pickupDate" dateFormat="mm/dd/yy" [showIcon]="true"
                             appendTo="body" [inputStyle]="{'width':'220px'}"></p-calendar></div></div>
                           <div><div class="box2 bl-col1">Delivery date:</div><div class="box2">
                             <p-calendar [(ngModel)]="tmpOrder.deliveryDate" dateFormat="mm/dd/yy" [showIcon]="true"
                             appendTo="body" [inputStyle]="{'width':'220px'}"></p-calendar></div></div>

                            <div><div class="box2 bl-col1" >Keys/Title:</div><div class="box2" style="height: 35px;">
                              <div  style="height: 35px;  display: table-cell; vertical-align: middle;">
                                <span class="box2" style="width: 25px;"><i class="fas fa-key"></i></span><p-triStateCheckbox [(ngModel)]="tmpOrder.keyFobD"></p-triStateCheckbox>
                                <span class="box2" style="width: 25px;">&nbsp;</span>
                                <span class="box2" style="width: 25px;"><i class="far fa-id-card"></i></span><p-triStateCheckbox [(ngModel)]="tmpOrder.titleD"></p-triStateCheckbox>
                              </div>
                            </div></div>
                            <div *ngIf="rowData.yard==='NY'"><div class="box2 bl-col1">Lot tag:</div><div class="box2">
                              <input pInputText  [(ngModel)]="tmpOrder.lotTag"  [style]="{'width':'255px'}" /></div></div>
                            <div><div class="box2 bl-col1">Order ID:</div><div class="box2">
                              <input pInputText  [(ngModel)]="tmpOrder.orderId"  [style]="{'width':'255px'}" /></div></div>
                            <div><div class="box2 bl-col1">Carrier:</div><div class="box2">
                              <p-dropdown [options]="contactOptions" [(ngModel)]="tmpOrder.carrierId" [filter]="true"
                                 [style]="{'width':'215px'}" placeholder="Select..." appendTo="body" >
                                <ng-template let-tComp pTemplate="item">
                                  <span style="font-family: Calibri; font-weight: 600;">{{tComp.label + (tComp.label==='' ? '' : ',') }}</span>
                                  <span style="font-family: Calibri; font-style: italic; font-size: .8em;">&nbsp;{{tComp.city}}</span>
                                </ng-template>
                              </p-dropdown>
                              <div style="text-align: center; display: inline-block; vertical-align:middle; margin-left: 5px; margin-bottom: 5px;">
                                <button type="button" pButton icon="pi pi-plus" (click)="onCarrierAddNew()"></button></div>
                            </div></div>
                            <div><div class="box2 bl-col1">Check number:</div><div class="box2">
                              <input pInputText  [(ngModel)]="tmpOrder.checkNumber" [style]="{'width':'255px'}" /></div></div>
                            <div *ngIf="pricesEnabled"><div class="box2 bl-col1">Price:</div><div class="box2">
                            <div class="ui-inputgroup">
                              <span class="ui-inputgroup-addon">$</span>
                              <input pInputText  [(ngModel)]="tmpOrder.deliveryPrice"></div></div></div>
                            <div><div class="box2 bl-col1">Notes:</div><div class="box2">
                              <input pInputText  [(ngModel)]="tmpOrder.noteDisp"  [style]="{'width':'250px'}" /></div></div>
                            <div  *ngIf="currentUser.type!==c.USER_TYPE_CLIENT"><div class="box2 bl-col1">Documents:</div><div class="box2">
                              <div  style="height: 35px; display: table-cell;">
                                <p-button icon="far fa-file-alt" (click)="showDispatchDocEditPanel(tmpOrder.id)"></p-button>
                              </div>
                            </div></div>

                          </div>

                          <div *ngIf="currentUser.type!==c.USER_TYPE_DISPATCH && currentUser.type!==c.USER_TYPE_YARD">
                            <div><hr></div>
                            <div><div class="box2 bl-col1">Container:</div><div class="box2">
                              <p-dropdown [options]="containerList" [ngModel]="tmpContainer.id" appentTo="body" scrollHeight="300px"
                                (onChange)="onContainerChange($event)" [style]="{'width':'170px'}" [filter]="true" >
                                <ng-template let-cont pTemplate="item">
                                  <div style="position:relative; height:30px; " >
                                    <div>{{cont.label}}</div>
                                    <div style="font-size: 10px; border-bottom: grey;">
                                      <span *ngIf="cont.label">[{{cont.cars}}]</span><span style="margin-left: 10px;">{{cont.loadDate}}</span></div>
                                  </div>
                                </ng-template>
                              </p-dropdown>
                              <div style="text-align: center; display: inline-block; vertical-align:middle; margin-left: 5px; margin-bottom: 5px;">
                                <button type="button" pButton icon="pi pi-pencil" (click)="onContainerEdit()" [disabled]="!tmpContainer.id"></button></div>
                              <div style="text-align: center; display: inline-block; vertical-align:middle;  margin-left: 5px; margin-bottom: 5px;">
                                <button type="button" pButton icon="pi pi-plus" (click)="onContainerNew()"></button></div>
                            </div></div>
                            <div class="zbrcont">
                              <div><div class="box2 bl-col1">Size:</div><div class="box2">{{tmpContainer.size}}</div></div>
                              <div><div class="box2 bl-col1">Shipping line:</div><div class="box2">{{tmpContainer.shippingLine}}</div></div>
                              <div><div class="box2 bl-col1">Tracking:</div><div class="box2">{{tmpContainer.tracking}}</div></div>
                              <div><div class="box2 bl-col1">Load date:</div><div class="box2">{{tmpContainer.loadDate}}</div></div>
                              <div><div class="box2 bl-col1">Agent:</div><div class="box2">{{tmpContainer.destinationAgent}}</div></div>
                              <div><div class="box2 bl-col1">Destination:</div><div class="box2">{{tmpContainer.destination}}</div></div>
                            </div>
                          </div>
                          <div><hr></div>
                          <div style="text-align: center;"><button type="button" pButton icon="pi pi-check" (click)="onSaveDispatchInfo()" label="Save changes"></button></div>
                        </ng-template>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: top;" >
                  <div class="box2" style="margin-left: 20px; width: 400px; vertical-align:top;">
                    <div class="card card-w-title" style="padding: 5px;">
                      <div class="bl-hdr">Photo of vehicle <i *ngIf="((orderEditingEnabled
                        || currentUser.type===c.USER_TYPE_YARD
                        || currentUser.type===c.USER_TYPE_YARD_F)
                        && selectedPhotoType !== 3  && rowData.inEditBy=='0')  || rowData.inEditBy==currentUser.id" (click)="onEditPhotoInfoToggle(rowData)"
                       [class]="(this.editPhotoInfo) ? 'fas fa-times bl-hdr-ic' : 'fas fa-edit bl-hdr-ic' " ></i></div>
                      <div style="padding-top: 10px;">
                        <p-selectButton [options]="photoTypeOptions" [(ngModel)]="selectedPhotoType"
                          (onChange)="onPhotoTypeChange($event,rowData)"></p-selectButton>
                          <div  *ngIf="galleryImages.length>0" style="padding-top: 10px; width: 120px; font-weight: bold; float:right">Photo: {{this.galleryCurSelectedImage}} of {{this.galleryImages.length}}</div>
                        <div *ngIf="galleryImages.length>0">
                          <ngx-gallery [options]="galleryOptions" [images]="galleryImages"  (change)="onPhotoInedxChange($event)"></ngx-gallery>
                        </div>
                        <div *ngIf="galleryImages.length==0" style="width: 100% ">
                          <img alt="no image" src="../../assets/layout/images/noimage.jpg" style="display:block; margin:auto;">
                        </div>
                      </div>
                    </div>
                  </div>

                </td>
              </tr>
            </table>

          </td>
      </tr>
    </ng-template>
</p-table>

<p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" >
  <ng-template pTemplate>
      <div *ngIf="overlayMode=='text'">{{this.overlayText}}</div>
      <div *ngIf="overlayMode=='carrier'">
        <div><i class="fas fa-city" style="color: grey;"></i>&nbsp;{{overlayCarrier.city}}</div>
        <div *ngIf="overlayCarrier.phone1">
          <a href="tel:{{overlayCarrier.phone1}}"><i class="fas fa-mobile-alt"></i></a>&nbsp;{{overlayCarrier.phone1}}</div>
        <div *ngIf="overlayCarrier.phone2">
          <a href="tel:{{overlayCarrier.phone2}}"><i class="fas fa-mobile-alt"></i></a>&nbsp;{{overlayCarrier.phone2}}</div>
        <div *ngIf="overlayCarrier.email">
          <a href="tel:{{overlayCarrier.email}}"><i class="fas fa-at"></i></a>&nbsp;{{overlayCarrier.email}}</div>
      </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #opgf [dismissable]="false" >
  <ng-template pTemplate>
    <div class="box2" style="width: 380px" >
      <div class="card card-w-title" style="padding: 2px;">
        <div class="bl-hdr">Advanced filter</div>
          <div><div class="box2 bl-col1" style="vertical-align: middle; padding-left: 5px;">Client ID:</div><div class="box2">
            <p-dropdown [options]="intContactList" [(ngModel)]="globalConsigneeFilter" appendTo="body"
              [style]="{'width':'100%'}" ></p-dropdown>
          </div></div>
        <div><div class="box2 bl-col1" style="vertical-align: middle; padding-left: 5px;">Auction:</div><div class="box2">
          <p-dropdown [options]="auctionList" [(ngModel)]="globalAuctionFilter" appendTo="body"
            optionLabel="name" optionValue="id" placeholder="Select..." [style]="{'width':'100%'}">
          <ng-template let-auction pTemplate="item">
            <div class="country-item">
              <span style="width: 15px; display: inline-block; text-align: center; vertical-align: middle;">
                <img *ngIf="auction.icon" src="../../../assets/images/{{auction.icon}}"  width="12" height="12"  />
              </span>
              <div style="padding-left: 5px;" >{{auction.name}}</div>
            </div>
        </ng-template>
        </p-dropdown>
        </div></div>
        <div><div class="box2 bl-col1" style="vertical-align: middle;  padding-left: 5px;">Auction acc:</div><div class="box2">
          <p-dropdown [options]="auctionAccList" [(ngModel)]="globalAuctionAccFilter" appendTo="body"
          optionLabel="name" optionValue="id" placeholder="Select..." [style]="{'width':'100%'}"></p-dropdown>
        </div></div>
        <div><div class="box2 bl-col1" style="vertical-align: middle;  padding-left: 5px;">No document:</div><div class="box2">
          <p-dropdown [options]="gfDocTypes" [(ngModel)]="globalNoDocFilter" appendTo="body"
            placeholder="Select..." [style]="{'width':'100%'}"></p-dropdown>
        </div></div>
        <div><div class="box2 bl-col1" style="vertical-align: middle;  padding-left: 5px;">Archived:</div><div class="box2">
          <p-checkbox [(ngModel)]="globalArchivedFilter" [binary]="true"> </p-checkbox>
        </div></div>
        <div><hr></div>
          <div style="text-align: center;">
            <button type="button" pButton pRipple icon="pi pi-filter"  (click)="globalFilterApply($event, opgf)"
              class="p-button-outlined" style="margin-right: 10px;" label="Apply" ></button>
            <button type="button" pButton pRipple icon="pi pi-times"  (click)="globalFilterCancel($event, opgf)"
              class="p-button-outlined" style="margin-right: 10px;" label="Clear"></button>
          </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-menu #menu [popup]="true" [model]="menuItems">
</p-menu>

<app-orderedit [order] = "tmpOrder" (newOrder)="gotNewOrder($event)" ></app-orderedit>
<app-docedit [docList]="docList"  (listChanged)="onDocListChanged($event)"></app-docedit>
<app-containeredit (newContainer)="onContainerNewSave($event)" (updateContainer)="onContainerUpdateSave($event)"></app-containeredit>
<app-contactedit (newContact)="onCarrierNewSave($event)"></app-contactedit>
<app-emailbody-order-tpl style="display: none;"></app-emailbody-order-tpl>

<app-photoedit (dialogHide)="onPhotoEditClosed()" [imageList] = "galleryImages" (listChanged)="onImageListChanged($event)"
  [orderId]="selectedOrderId" [photoType]="selectedPhotoType"></app-photoedit>

<p-dialog [(visible)]="displayHistDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'1000px'}" >
  <p-header>
    <span style="padding-left: 10px;">History of changes</span>
  </p-header>
  <div>
    <p-table #tblObj [value]="historyList"   [loading]="loadingHist" [autoLayout]="true"  [scrollable]="true"
      dataKey="id"  [scrollHeight]=" '60vh' " [rowHover]="true">
      <ng-template pTemplate="header" >
        <tr>
          <th style="width: 150px;" >Modify date</th>
          <th style="width: 150px;">User</th>
          <th >Field</th>
          <th style="width: 260px;">Old info</th>
          <th style="width: 260px;">New info</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr class="p-selectable-row ui-tr" [attr.id]="rowData.id" >
            <td style="width: 150px;">{{rowData.date | date:'short'}}</td>
            <td style="width: 150px;">{{rowData.user}}</td>
            <td >{{rowData.field}}</td>
            <td style="width: 260px;">
              <div style="width: 100%;" class="ellipsis">{{rowData.old_val}}</div></td>
            <td style="width: 260px;">
              <div style="width: 100%;" class="ellipsis">{{rowData.new_val}}</div></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayPhotoDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{'width':'800px'}" >
  <p-header>
    <button type="button" pButton icon="pi pi-download" (click)="onDownloadImages()"></button><span style="padding-left: 10px;">Photo of vehicle</span>
  </p-header>
  <div><ngx-gallery [options]="galleryOptionsFS" [images]="galleryImages" ></ngx-gallery></div>
</p-dialog>

<p-dialog  [(visible)]="displayDocDialog" [focusOnShow]="false"  showEffect="false" [modal]="true" [style]="{width: '600px'}">
  <p-header >
    <div class="box2" [style.width.px]="220"></div>
    <div class="box2" [style.width.px]="250"><span>Document list</span></div>
  </p-header>
  <app-doclist [docList]="docList"></app-doclist>
</p-dialog>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
</div>

<div #tblCbView *ngIf="selectedOrders.length > 0" >

<table style=" border-collapse: collapse; border: 1px solid #999; padding: 0.5rem;  text-align: left;">
  <thead>
    <tr style="background-color: #DEE;">
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 100px;">Date</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 150px;" *ngIf="enabledColumns.location">Location</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px;">Buyer</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 110px;">Lot</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 60px;" *ngIf="enabledColumns.lotTag">Tag</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 250px">Vehicle</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 200px" ><span>VIN</span></th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 120px;" *ngIf="enabledColumns.clientId">Client ID</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 130px;" *ngIf="enabledColumns.client">Consignee</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px;" *ngIf="enabledColumns.color">Color</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 45px; text-align: center;" *ngIf="enabledColumns.key"><i class="fas fa-key"></i></th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 45px; text-align: center;"><i class="far fa-id-card"></i></th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 45px; text-align: center;" *ngIf="enabledColumns.fuel"><i class="fas fa-tint"></i></th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 150px; text-align: center;" *ngIf="enabledColumns.containers">Container</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 95px; white-space: pre-wrap;" *ngIf="enabledColumns.auctionPrice">Auction price</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px; white-space: pre-wrap;" *ngIf="enabledColumns.buyerFee">Buyer fee</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px; white-space: pre-wrap;" *ngIf="enabledColumns.storageFee">Storage fee</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px;" *ngIf="enabledColumns.orderId">Order ID</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left;" *ngIf="enabledColumns.carrier">Carrier</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px; white-space: pre-wrap;" *ngIf="enabledColumns.deliveryPrice">Delivery price</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px; white-space: pre-wrap;" *ngIf="enabledColumns.buyerPay">Delivery rate</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 90px; white-space: pre-wrap;" *ngIf="enabledColumns.containerPrice">Container price</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px;" *ngIf="enabledColumns.miscFee">Misc. fee</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 95px;" *ngIf="enabledColumns.subtotal">Subtotal</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 100px">Yard</th>
      <th style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 140px;">Status</th>
    </tr>  </thead>
  <tbody>
      <tr *ngFor="let rowData of selectedOrders">
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 100px;">{{rowData.date}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 150px;" *ngIf="enabledColumns.location"><span *ngIf="rowData.sublot"><a href="#" >
          <i class="fas fa-info-circle"></i></a></span><span class="fa-text">{{rowData.location}}</span></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px;">
          {{rowData.buyerNr}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 110px;"><a href="#" [ngClass]="rowData.photo1Cnt? null : 'a-disabled'" ><i class="fas fa-camera" (click)="onPhotoClick($event, 1,rowData.id)" ></i></a>
          <span class="fa-text"><a href="{{rowData.auctionURL}}"  target="_blank">{{rowData.lot}}</a></span></td>
        <td *ngIf="enabledColumns.lotTag" style="width: 60px; text-align: center;">
          <div *ngIf="rowData.lotTag" style="width: 30px; padding: 3px; text-align: center;" [style]="rowData.tagStyle">{{rowData.lotTag}}</div></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 250px">
          {{rowData.car}}</td>
        <td style= "border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 200px"><span *ngIf="selectionEnabled" style="display: inline-block; padding-right: 10px; width: 30px;">
          <p-checkbox name="cargroup" value="{{rowData.id}}" *ngIf="rowData.client!==''"
            [(ngModel)]="selectedList" (click)="onCBClick($event)">Paid</p-checkbox>
        </span><span>{{rowData.vin}}</span>
          <div *ngIf="rowData.invId"><a href="#" (click)="invoiceView($event, rowData)">
            <i style="margin-right: 3px;" class="far fa-fw fa-file-alt"></i></a>{{rowData.invNr}}</div></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 120px;" *ngIf="enabledColumns.clientId">{{rowData.consignee}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 130px;" *ngIf="enabledColumns.client">
          <span *ngIf="selectionEnabled && rowData.consigneeCountry" style="padding-right: 5px;">
            <img src="../../../assets/images/flag_placeholder.png" style="width: 20px; text-align: center;"
              [class]="'flag flag-' + rowData.consigneeCountry.toLowerCase()"   />
            </span>
          <span>{{rowData.client}}</span></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 80px;" *ngIf="enabledColumns.color">{{rowData.color}}</td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 45px; text-align: center;" *ngIf="enabledColumns.key"><div *ngIf="(rowData.keyFobD!==null)">
          <span [class]=" rowData.keyFobD ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
            {{ rowData.keyFobD ? '✔':'✖'}} </span>
          </div></td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width: 45px; text-align: center;" *ngIf="!rowData.titleType">
          <span *ngIf="(rowData.titleD!==null)" [class]="rowData.titleD ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
            {{ rowData.titleD ? '✔':'✖'}} </span>
        </td>
        <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left;width: 45px; text-align: center;" *ngIf="rowData.titleType"><a href="#" (click)="toggleOverlay($event, op, rowData.titleType)">
          <span *ngIf="(rowData.titleD!==null)" [class]="rowData.titleD ? 'uni-symb fa-color-green' : 'uni-symb fa-color-red'">
            {{ rowData.titleD ? '🛈':'⚠'}} </span> </a></td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left ;width:  45px; text-align: center;" *ngIf="enabledColumns.fuel">
              <span [class]="rowData.fuelClass" (click)="toggleOverlay($event, op, rowData.fuel)">{{rowData.fuelSymbol}}</span>
            </td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  150px;" *ngIf="enabledColumns.containers">
              <div>
              <span class="fa-text" >{{rowData.containerNr}}</span>
              <span *ngIf="rowData.tracking"><a href="{{rowData.containerTrackingURL}}" target="_blank" >
                <i class="fas fa-external-link-alt"  style="padding-left: 6px;"></i></a></span>
            </div> </td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  95px;" *ngIf="enabledColumns.auctionPrice" [style]="rowData.paidByClient? 'color: darkgrey;' : null" >{{
                (rowData.paidByClient && currentUser.type==c.USER_TYPE_CLIENT? 0 : rowData.auctionPrice)  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  80px;" *ngIf="enabledColumns.buyerFee">{{rowData.buyerFee  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  80px;" *ngIf="enabledColumns.storageFee">{{rowData.storageFee  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  80px;" *ngIf="enabledColumns.orderId">{{rowData.orderId}}</td>
            <td *ngIf="enabledColumns.carrier">{{rowData.carrier}}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  80px;" *ngIf="enabledColumns.deliveryPrice" [className]="rowData.priceClassName" >{{rowData.deliveryPrice2 | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  80px;" *ngIf="enabledColumns.buyerPay">{{rowData.buyerPay  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  90px;" *ngIf="enabledColumns.containerPrice" >{{rowData.containerPrice  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  80px;" *ngIf="enabledColumns.miscFee" >{{rowData.miscFee  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  95px;" *ngIf="enabledColumns.subtotal" >{{rowData.orderTotal  | currency: 'USD' }}</td>
            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  100px"><span class="fa-text">{{rowData.yard}}</span></td>

            <td style="border: 1px solid #999; padding: 0.5rem;  text-align: left; width:  140px;">
              <ng-container *ngIf="rowData.dispatchStatus===0"  >
                <div style="color: darkgray;">No information</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===1"  >
                <div >Paid on:</div>
                <div>{{rowData.paymentDate}}</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===2"  >
                <div style="font-weight: bold;">Dispatched</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===3"  >
                <div>Picked up:</div>
                <div>{{rowData.pickupDate}}</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===4"  >
                <div style="font-weight: bold;">Delivered: </div>
                <div>{{rowData.deliveryDate}}</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===5"  >
                  <div style="font-weight: bold;">Consolidated</div></ng-container>
              <ng-container *ngIf="rowData.dispatchStatus===6"  >
                  <div style="font-weight: bold;">Loaded<span *ngIf="rowData.loadDate"> on:<div style="font-weight: normal;">
                    {{rowData.loadDate}}</div></span>
                  </div></ng-container>
              </td>
    </tr>
  </tbody>
</table>
</div>
