import { Container } from '../../models/container';
import { ChangeDetectorRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { Component } from '@angular/core';
import { ContainerService } from '@app/services/container.service';


@Component({
  selector: 'app-emailbody-cont-tpl',
  templateUrl: 'emailbody.cont.component.html',
})
export class EmailBodyContComponent {
  elRef: ElementRef;
  wireTo: string;
  wireDate: string;
  wireAmount: string;
  wireFrom: string;
  containerList: Container[];
  shippingList: string[];
  priceSum: number;

  constructor(
    elRef: ElementRef,
    private containerService: ContainerService,
    private refDet: ChangeDetectorRef
    ) {
    this.elRef = elRef;
    this.containerList = [];
    this.shippingList = [];
  }

  setData( data: any ) {
    if ( data.wireDate !== undefined) { this.wireDate = data.wireDate; }
    if ( data.wireFrom !== undefined) { this.wireFrom = data.wireFrom; }
    if ( data.containerList !== undefined) {
      return this.containerService.getByIdList(data.containerList)
      .then( list => {
        this.containerList = list;
        this.priceSum = 0;
        this.shippingList = [];
        list.forEach( i => {
          this.priceSum += Number( i.price );
          let str = i.shippingLine.toUpperCase();
          str = str.replace(/\s/g,  '');
          if (  !this.shippingList.includes(str) ) { this.shippingList.push(str); }
        });
        if (this.shippingList.length !== 1) {
          this.wireTo = null;
        } else { this.wireTo = this.shippingList[0]; }
        this.refDet.detectChanges();
      });
    }
  }

  getShippingList() {
    return this.shippingList;
  }

  getHtmlContent() {
    return this.elRef.nativeElement.innerHTML;
  }
}
