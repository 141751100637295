import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Yard } from '../models';

@Injectable({ providedIn: 'root' })
export class YardsService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}/api/yards.php`)
        .toPromise()
        .then(
          res => res as Yard[]);
    }
}

