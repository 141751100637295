

<p-dialog  [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="true" (onHide)="selDocType=null;"
  [modal]="true" [style]="{'max-width': '600px'}">
  <p-header >
    <div class="box2" [style.width.px]="250">
      <p-splitButton #spBut *ngIf="!selDocType && moduleId!=1" [model]="docTypeMenu" label="Add document" (onClick)="spBut.onDropdownButtonClick($event)"></p-splitButton>

      <p-fileUpload *ngIf="selDocType || moduleId==1" #fubauto name="documents[]" customUpload="true" (uploadHandler)="docUploader($event, fubauto)"
        mode="basic" [chooseLabel]="moduleId==1? 'Add ...':'Browse ...'" [chooseIcon]="null"
        [auto]="true" [showCancelButton]="false" multiple="multiple" accept=".pdf,.jpg,.png,.jpeg" maxFileSize="20480000" ></p-fileUpload>
    </div>
    <div class="box2" [style.width.px]="250"><span>Document list</span></div>
  </p-header>
  <div style="padding: 5px; text-align: center;" *ngIf="selDocType">
    <p-chip [removable]="true" (onRemove)="selDocType=null">
      <span>Document type:</span><span style="font-weight: bold; padding-left: 5px;">{{selDocType}}</span>
    </p-chip></div>
  <div>
    <p-dataView #dv [value]="docList" [paginator]="false" [rows]="20"  emptyMessage="No documents found!">
      <ng-template let-docFile pTemplate="listItem">
        <div class="p-col-12">
          <div class="box2" style="width: 70px; text-align: center;">
            <a  (click)="null">
              <i [class]="docFile.icon" style="font-size: 60px; "></i></a>
          </div>
          <div class="box2" style="width: 400px;">
            <div *ngIf="moduleId!=1">
              <div class="box2 bl-col1">Type:</div>
              <div class="box2">{{docFile.type}}</div>
            </div>
            <div>
              <div class="box2 bl-col1">Name:</div>
              <div class="box2" style="overflow:hidden; white-space:nowrap; text-overflow:ellipsis;">{{docFile.fileName}}</div>
            </div>
            <div>
              <div class="box2 bl-col1">Uploaded:</div>
              <div class="box2">{{docFile.uploadDate | date }}</div>
            </div>
          </div>
          <div class="box2" style="width: 90px;" *ngIf="!editByClient">
            <p-button icon="fas fa-edit" (click)="onEditDocument(docFile)" style="padding-right: 10px;"></p-button>
            <p-button icon="far fa-trash-alt" (click)="onDeleteDocumentEd(docFile)" ></p-button>
          </div>
        </div>
    </ng-template>
    </p-dataView>
  </div>

</p-dialog>
<p-dialog *ngIf="tmpDocFile"  [(visible)]="showDocEdDlg" [focusOnShow]="true"  showEffect="true" [modal]="true" [style]="{'max-width': '600px'}">
  <p-header >
    <div class="box2" [style.width.px]="400"><span>Document Edit</span></div>
  </p-header>
  <div>
    <div><div class="box2" style="width: 100px; font-weight: bold;">Type:</div>
      <div class="box2">
      <p-dropdown [options]="docTypes" [(ngModel)]="tmpDocFile.type" appendTo="body"
        [style]="{'width':'100%'}" ></p-dropdown>
      </div></div>

    <div class="box2" style="width: 100px; font-weight: bold;">File name:</div>
    <div class="box2">
    <div class="ui-inputgroup" style="width: 290px;">
      <input pInputText [(ngModel)]="tmpDocFile.fname" style="width: 250px;">
      <span class="ui-inputgroup-addon">.{{tmpDocFile.fext}}</span>
    </div></div>
  </div>
  <p-footer>
    <div style="text-align: center;">
      <button type="button" pButton icon="pi pi-check" (click)="onSaveDocEdit()" label="Save changes"></button>
    </div>
  </p-footer>
</p-dialog>
