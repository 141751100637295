import { OrdersService } from '@app/services/order.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Auction, AuctionAcc, Order, Yard } from '@app/models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { YardsService } from '@app/services/yard.service';
import { Message } from 'primeng/api';
import { ContactService } from '@app/services/contact.service';
import { AuctionService } from '@app/services/auction.sevice';
import { AuthenticationService } from '@app/services/authentication.service';


@Component({
  selector: 'app-orderedit',
  templateUrl: './orderedit.component.html',
  styleUrls: ['./orderedit.component.css'],
  providers: [ConfirmationService,MessageService]
})

export class OrderEditComponent implements OnInit {

  @Input() order: Order;
  @Output() newOrder = new EventEmitter<Order>();

  yards: Yard[];
  selectedYard: Yard;
  displayDialog = false;
  fuelTypes: SelectItem[];
  errorMsg: Message[] ;
  dropString: string;
  intContactList: any[];
  consigneeList: any[];
  auctionList: Auction[];
  auctionAccList: AuctionAcc[];
  userPrivilege: any;


  constructor(
    private yardService: YardsService,
    private ordersService: OrdersService,
    private contactService: ContactService,
    private auctionService: AuctionService,
    private confirmationService: ConfirmationService,
  ) {
    this.fuelTypes = [
      { label: 'Flex fuel', value: 'Flex fuel'},
      { label: 'Electric',  value: 'Electric'},
      { label: 'Diesel',  value: 'Diesel'},
      { label: 'Gas' , value: 'Gas'},
      { label: 'Hybrid engine', value: 'Hybrid engine'}
    ];
    this.errorMsg = [];
    this.userPrivilege = [];
    this.selectedYard = null;
    this.auctionList = null;
    this.auctionAccList = null;
  }

  getFuelTypes() {
    return this.fuelTypes;
  }

  ngOnInit(): void {
    this.consigneeList = [];
    this.intContactList = [];
    this.errorMsg = [];
    this.loadInternContacts();
  }

  saveOrder() {
    this.order.yard = this.selectedYard.yard;
    this.newOrder.emit( this.order );
    this.selectedYard = null;
  }

  loadInternContacts(){
    this.intContactList = [
      { label: '', value: '' }
    ];
    this.contactService.getContactList('4,3') // 4-International client 3-client
    .then( list => {
      list.forEach( c=> {
        this.intContactList.push({ label: c.clientId, value: c.clientId });
      });
    });
  }

  onClientChange(event) {
    this.contactService.getConsigneeList(event.value)
    .then( list => { this.consigneeList = list;}  );
  }

  submitOrder(form) {
    if (form.invalid) {
      this.errorMsg = [{severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {
      this.ordersService.isVINused( form.value.vin )
      .toPromise().then(
        cnt => {
          if (cnt === 0) {
              this.saveOrder();
          } else {
            this.confirmationService.confirm({
              message: 'Car with same VIN number exist in orders<br>Are you sure that you want to proceed?',
              header: 'Confirmation',
              key: 'orderedit',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.saveOrder();
              }
          });
          }
        });

    }

  }

  onDrop( ev ) {
    const data = ev.event.dataTransfer.getData('text');
    const obj = JSON.parse( data );
    obj.fuel = obj.fuel.charAt(0).toUpperCase() + obj.fuel.slice(1).toLowerCase();
    obj.titleType = obj.docType;
    obj.auctionId = '1'; // Copart
    Object.assign(this.order, obj);
  }

  show( ord ) {
    if(ord.id===0) { this.selectedYard = null; }
    this.order = ord;
    this.displayDialog = true;
    this.yardService.getAll().then(yards => {  this.yards = yards;  });
    this.auctionService.getAll().then( lst => { this.auctionList = lst; });
    this.auctionService.getAuctionAcc().then( lst => { this.auctionAccList = lst; });
  }

  hide() {
    this.displayDialog = false;
    this.selectedYard = null;
  }

  setUserPrivilege( priv: any) {
    this.userPrivilege = priv;
  }

  // get diagnostic() { return JSON.stringify(this.order); }

}
