import { ContainerCar } from './../models/containerCar';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Container } from '@app/models/container';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })

export class ContainerService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getAll(filter) {
    let params = new HttpParams();
    params = params.append('filter', JSON.stringify(filter) );
    return this.http.get<Container[]>(`${environment.apiUrl}/api/containers.php`, {params})
    .toPromise()
    .then(  res => res as Container[]);
  }

  getByIdList( idList: string) {
    let params = new HttpParams();
    params = params.append('cmd', 'containers_by_list' );
    params = params.append('idList', idList );
    return this.http.get<Container[]>(`${environment.apiUrl}/api/containers.php`, {params})
      .toPromise()
      .then(  res => res as Container[]);
  }

  getById(id: number) {
    let params = new HttpParams();
    params = params.append('cmd', 'container_by_id' );
    params = params.append('id', id.toString() );
    return this.http.get<Container>(`${environment.apiUrl}/api/containers.php`, {params})
    .toPromise()
    .then(  res => res as Container);
  }

  getList() {
    let params = new HttpParams();
    params = params.append('cmd', 'container_list' );
    return this.http.get<SelectItem[]>(`${environment.apiUrl}/api/containers.php`, {params})
      .toPromise()
      .then( res => res as SelectItem[] );
  }

  getContainerToVinList() {
    let params = new HttpParams();
    params = params.append('cmd', 'container_to_vin_list' );
    return this.http.get<any[]>(`${environment.apiUrl}/api/containers.php`, {params})
      .toPromise()
      .then( res => res as any[] );
  }


  getCarList(contId) {
    let params = new HttpParams();
    params = params.append('cmd', 'car_list' );
    params = params.append('contId', contId );
    return this.http.get<ContainerCar[]>(`${environment.apiUrl}/api/containers.php`, {params})
      .toPromise()
      .then( res => res as ContainerCar[] );
  }

  getNextId() {
    let params = new HttpParams();
    params = params.append('cmd', 'container_next_id' );
    return this.http.get<any>(`${environment.apiUrl}/api/containers.php`, {params})
      .toPromise().then( res => res );
  }


  getNew(): Container {
    return {id: null, carCount: 0, carList: null, containerNr: null, loadDate: null, origin: null, emailId: 0,
      size: null, forwarder: null,  price: '0.00', destination: null, note: null, payment: 0,
      destinationAgent: null, eta: null, shippingLine: null, tracking: null, bookingNr: null };
  }

  update(cont: Container) {
    const msg =  {cmd: 'container_update',  obj: cont };
    return this.http.post(`${environment.apiUrl}/api/containers.php`, msg, this.httpOptions );
  }

  insertNew( cont: Container): Observable<any> {
    const msg =  {cmd: 'container_new',  obj: cont };
    return this.http.post(`${environment.apiUrl}/api/containers.php`, msg, this.httpOptions );
  }

  setPaymentStatus(contList: string, statNr: number) {
    const msg =  {cmd: 'set_payment_status',  list: contList, pmType: statNr  };
    return this.http.post(`${environment.apiUrl}/api/containers.php`, msg, this.httpOptions )
    .toPromise().then(  res => res as Container[]);
  }

  setEmailId(contList: string, emId: number) {
    const msg =  {cmd: 'set_email_id',  list: contList, emailId: emId  };
    return this.http.post(`${environment.apiUrl}/api/containers.php`, msg, this.httpOptions )
    .toPromise().then(  res => res as Container[]);
  }

  getCarAutosuggest(val, contId) {
    let params = new HttpParams();
    params = params.append('cmd' , 'suggest' );
    params = params.append('val' , val );
    params = params.append('contId' , contId );
    return this.http.get<string[]>(`${environment.apiUrl}/api/containers.php`, {params})
    .pipe( map( res => res as string[] ));
  }

  suggestDestination(cityName: string) {
    let params = new HttpParams();
    params = params.append('cmd' , 'suggest_dest' );
    params = params.append('dest', cityName);
    return this.http.get<any[]>(`${environment.apiUrl}/api/containers.php`, {params})
    .pipe( map( res => res as any[] ));
  }

  suggestDestinationAgent(agent: string) {
    let params = new HttpParams();
    params = params.append('cmd' , 'suggest_dest_agent' );
    params = params.append('dest', agent);
    return this.http.get<string[]>(`${environment.apiUrl}/api/containers.php`, {params})
    .pipe( map( res => res as string[] ));
  }


  addCarToContainer(ordId, contId) {
    const msg =  {cmd: 'add_car',  containerId: contId, ordId };
    return this.http.post<ContainerCar[]>(`${environment.apiUrl}/api/containers.php`, msg, this.httpOptions )
      .toPromise()
        .then( res => res as ContainerCar[] );
  }

  removeCarFromContainer(ordId, contId ) {
    const msg =  {cmd: 'del_car', containerId: contId , orderId: ordId};
    return this.http.post<ContainerCar[]>(`${environment.apiUrl}/api/containers.php`, msg, this.httpOptions )
      .toPromise()
        .then( res => res as ContainerCar[] );
  }

}
