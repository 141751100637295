<div>
<p-dataView #dv [value]="docList" [paginator]="false" [rows]="5" emptyMessage="No documents found!">
  <ng-template let-docFile pTemplate="listItem">
    <div class="p-col-12">
      <div class="photo-details">
        <div class="box2" style="width: 70px; text-align: center;">
          <a href="#" (click)="toggleDownlOverlay($event, docFile.id);">
            <i [class]="docFile.icon" style="font-size: 60px; "></i></a>

        </div>
        <div class="box2" style="width: 85%; ">
          <div>
            <span class="box2 bl-col1" style="width: 110px;">Type:</span>
            <span class="box2" style="width: 75%;">{{docFile.type}}</span>
          </div>
          <div>
            <span class="box2 bl-col1" style="width: 110px;">Name:</span>
            <span class="box2" style="width: 75%;" class="ellipsis" >{{docFile.fileName}}</span>
          </div>
          <div>
            <div class="box2 bl-col1" style="width: 110px;">Uploaded:</div>
            <div class="box2">{{docFile.uploadDate | date }}</div>
          </div>
        </div>
        <div style="width: 100%; padding: 5px;  background-color: #f4f4f4; " *ngIf="docFile.id === selectedFileId" @inOutAnimation>
            <button type="button" pButton icon="pi pi-download" (click)="fileOpen( docFile.hashName, true )" ></button>
            <button type="button" pButton icon="pi pi-eye" (click)="fileOpen( docFile.hashName, false )" style="margin-left: 10px;" ></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-dataView>
</div>
