import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from './../services/authentication.service';
import { Message } from 'primeng/api';
import { from } from 'rxjs';



@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    submitted = false;
    returnUrl: string;
    error = '';
    msgs: Message[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required ]
        });

        // get return url from route parameters or default to ',/'
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
          return;
        }

        const d = new Date();
        const tz = d.getTimezoneOffset();

        this.authenticationService.login(this.f.username.value, this.f.password.value, tz)
          .pipe(first())
          .subscribe(
            user => {
              if(user.type==='9') this.router.navigate(['/contacts']);
            else this.router.navigate(['/']);
            },
            error => {
              this.error = error;
              this.msgs.push({severity: 'error', summary: 'Error Message', detail: error});
            });
    }

    onInfoByVIN(event) {
      this.router.navigate(['infobyvin']);
      event.stopPropagation();
      event.preventDefault();
    }
}
