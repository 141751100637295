import { PhotoService } from '@app/services/photo.service';
import { OrdersService } from '@app/services/order.service';
import { Component, OnInit } from '@angular/core';
import { Message, SelectItem } from 'primeng/api';
import { Order } from '@app/models/order';
import { NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-infobyvin',
  templateUrl: './infobyvin.component.html',
  styleUrls: ['./infobyvin.component.css']
})
export class InfobyvinComponent implements OnInit {
  errorMsg: Message[];
  vinOfCar: string;
  tmpOrder: Order;
  photoTypeOptions: SelectItem[];
  selectedPhotoType = 1;
  galleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  galleryCurSelectedImage: number;


  constructor(
    private orderService: OrdersService,
    private photoService: PhotoService
  ) { }

  ngOnInit(): void {
    this.photoTypeOptions = [
        {label: 'Auction', value: 1},
        {label: 'Yard', value: 2},
        {label: 'Container', value: 3} ];
    this.galleryOptions = [  {  width: '100%', height: '300px', thumbnails: false , preview: false, imageDescription: false  }  ];
    this.vinOfCar = null;
    this.galleryCurSelectedImage = 1;
  }

  onPhotoInedxChange( event ) {
    this.galleryCurSelectedImage = event.index + 1;
  }

  onPhotoTypeChange(event, row) {
    this.selectedPhotoType = event.value;
    this.loadImages(row.id);
  }

  loadImages(orderid) {
    let nr = 1;
    this.galleryImages = [];
    this.photoService.getPhotosOf( orderid, this.selectedPhotoType )
    .toPromise().then( photos => {
      if (photos === null) { photos = []; }
      photos.forEach ( photo => {
        photo.small = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=small`;
        photo.medium = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=medium`;
        photo.big = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=big`;
        photo.description = 'Photo: ' + nr + ' of ' + photos.length;
        nr = nr + 1;
      });
      this.galleryImages = photos;
    });
  }

  onSearchVin(event) {
    event.preventDefault();
    event.stopPropagation();
    this.tmpOrder = null;
    this.orderService.getOrderByVinEnd( this.vinOfCar )
    .then( ord => {
      if( !ord ) {
        this.errorMsg = [{severity: 'error', summary: 'Error', detail: 'VIN number not found!' } ];
      } else {
        ord.car = ord.year + ' ' + ord.model;
        this.tmpOrder = ord;
        this.loadImages(ord.id);
      }
    });
  }

}
