import { EmailMessage } from '@app/models/email';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })

export class EmailService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  sendEmail(mail: EmailMessage) {
    const msg =  {cmd: 'email_send',  obj: mail };
    return this.http.post(`${environment.apiUrl}/api/email.php`, msg, this.httpOptions );
  }

  loadEmail( id: number) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_email_by_id' );
    params = params.append('emailId', id.toString() );
    return this.http.get<EmailMessage>(`${environment.apiUrl}/api/email.php`, {params})
    .toPromise()
    .then(  res => res as EmailMessage);
  }

}

