import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, UserType } from '../models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersService {
  httpOptions = {
    headers: new HttpHeaders({  'Content-Type':  'application/json' })
  };

  constructor(private http: HttpClient) {
  }

  getTypeList() {
    let params = new HttpParams();
    params = params.append('cmd', 'type_list' );
    return this.http.get<UserType[]>(`${environment.apiUrl}/api/users.php`, {params})
    .toPromise()
    .then(  res => res as UserType[]);
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/api/users.php`)
      .pipe( map(
        users => users as User[]
      ));
  }

  getNew(): User{
    return {id: 0, username: null, name: null, email: null, clientId: null, contactId: 0, canChatWith: null, yard: null,
      type: null, password: null, created: null, modified: null, lastLogin: null, enabled: null, consigneeName: null,
      history: false, ordStatusChngSMS: false, ordLoadedEmail: false, ordPhotoChngEmail: false };
  }

  getById(id: number) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_by_id' );
    params = params.append('userId', id.toString() );
    return this.http.get<User>(`${environment.apiUrl}/api/users.php`, {params})
      .pipe( map(
        user => user as User
      ));
  }

  getPriv(id: number) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_priv' );
    params = params.append('userId', id.toString() );
    return this.http.get<any[]>(`${environment.apiUrl}/api/users.php`, {params})
    .toPromise()
    .then(  res => res );
  }


  insertNewUser(user: User): Observable<any> {
    const msg =  {cmd: 'new_user',  obj: user };
    return this.http.post(`${environment.apiUrl}/api/users.php`, msg, this.httpOptions );
  }

  updateUser(user: User): Observable<any> {
    const msg =  {cmd: 'update_user',  obj: user };
    return this.http.post(`${environment.apiUrl}/api/users.php`, msg, this.httpOptions );
  }

  changeOption( option: string, value: boolean, id: number) {
    const msg =  {cmd: 'change_option',  option, value,  id};
    return this.http.post(`${environment.apiUrl}/api/users.php`, msg, this.httpOptions );
  }
}
