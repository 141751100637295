import { NoticeService } from './../../services/notice.sevice';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Notice } from '@app/models/notice';
import { User } from '@app/models/user';

@Component({
  selector: 'app-noticeedit',
  templateUrl: './noticeedit.component.html',
  styleUrls: ['./noticeedit.component.css']
})
export class NoticeEditComponent implements OnInit {
  notice: Notice;
  @Output() saveNoticeEvt = new EventEmitter<Notice>();
  displayDialog = false;
  contacts: User[];
  selectedContact: User;
  selectedContacts: User[];
  editable = true;

  constructor(
    private noticeService: NoticeService
  ) {   }

  ngOnInit(): void {
    this.notice = this.noticeService.getNew();
  }

  show( nt: Notice, editable: boolean ) {
    this.notice = nt;
    this.editable = editable;
    this.selectedContacts = [];
    if( Number(this.notice.clientId) !==0 ) {
      this.selectedContact = this.contacts
        .find(c => Number(c.id) === Number(this.notice.clientId));
    }else {
      this.selectedContact = null;
    }
    this.displayDialog = true;
  }

  saveNotice() {
    if( this.editable) {

      if( (!this.selectedContact && !this.selectedContacts.length)
        || this.notice.subject == null ||
        this.notice.subject === '' )return;

        if(this.selectedContacts.length > 0) { // array of new notices
          this.selectedContacts.forEach( cont => {
            this.notice.clientId = Number(cont.id);
            this.notice.client = cont.name;
            this.noticeService.insertNewNotice(this.notice)
              .subscribe((notice: Notice) => { });
          });
          setTimeout(() => {
            this.saveNoticeEvt.emit();
          }, 500);

        } else { // updated notice
          this.notice.clientId = Number(this.selectedContact.id);
          this.notice.client = this.selectedContact.name;
          this.noticeService.updateNotice(this.notice)
            .subscribe((notice: Notice) => {
              this.saveNoticeEvt.emit();
            });
        }

    }
    this.displayDialog = false;
  }

  setClientList( usr: User[] ) {
    this.contacts = usr;
  }
}
