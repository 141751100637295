import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Auction } from '../models';

@Injectable({ providedIn: 'root' })
export class AuctionService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}/api/auctions.php`)
        .toPromise()
        .then(
          res => res as Auction[]);
    }

    getAuctionAcc() {
      let params = new HttpParams();
      params = params.append('cmd', 'get_auction_acc' );
      return this.http.get<any>(`${environment.apiUrl}/api/auctions.php`, {params})
        .toPromise().then( res => res);
    }

}

