<p-dialog [header]="editable ? 'Compose notice' : 'View notice'" [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="fade"
    [modal]="true"  [style]="{'width':'600px'}">
  <div class="p-grid ui-fluid"   [style.min-height.vh]="35" >
    <div class="p-field" [style.height.px]="65">
      <div class="box2"  [style.width.%]="100">
        <label for="type" [style.margin-top.px]="5">Contact</label>
        <p-dropdown *ngIf="!editable || notice.id>0" id="client" name="client" [options]="contacts" [(ngModel)]="selectedContact" appendTo="body"
            [readonly]="!editable || notice.id>0" optionLabel="name" placeholder="Select contact" [style]="{'width':'100%'}" ngModel required></p-dropdown>
        <p-multiSelect *ngIf="editable && notice.id==0" id="client" [options]="contacts" [(ngModel)]="selectedContacts" name="client"
            [style]="{'width':'100%'}" optionLabel="name" appendTo="body" ngModel required></p-multiSelect>
      </div>
    </div>
    <div class="p-field" [style.height.px]="65">
      <label for="subject">Subject</label>
      <input pInputText id="subject" name="subject"  [style.width.%]="100" [(ngModel)]="notice.subject" [disabled]="!editable" required/>
    </div>
    <div class="p-field">
      <label for="text" [style.padding-top.px]="10">Text</label>
      <textarea pInputTextarea id="text" name="text"  [style.width.%]="100" [disabled]="!editable"
        rows="8" cols="30" [(ngModel)]="notice.text" ></textarea>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix" >
      <button type="button" pButton icon="pi pi-check" (click)="saveNotice(); "  [label]="this.editable?'Save':'Close'" ></button>
    </div>
  </p-footer>
</p-dialog>
