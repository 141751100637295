<div class="content-section implementation">
  <p-table #tblObj [value]="users"   [loading]="loading" scrollHeight="65vh"
   [scrollable]="true"   columnResizeMode="expand" dataKey="id" rowHover="true">
    <ng-template pTemplate="caption">
      <div class="box2" [style.width.%]="40">
        <button type="button" pButton icon="pi pi-plus" (click)="onNewUser()"></button>
      </div>
      <div class="box2" [style.width.%]="60">
        <div class="cap-hdr"><i style="margin-right: 20px;" class="fas fa-fw fa-user"></i>Users</div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pColumn="edit" style="flex: 0 0 50px;"></th>
        <th pColumn="enabled" style="flex: 0 0 100px;">Enabled</th>
        <th pColumn="type" style="flex: 0 0 150px;">Type</th>
        <th pColumn="yard" style="flex: 0 0 100px;">Yard</th>
        <th pColumn="username" style="flex: 0 0 150px;">Username</th>
        <th pColumn="name" style="flex: 0 0 200px;">Contact name</th>
        <th pColumn="clientId" style="flex: 0 0 200px;">ClientId</th>
        <th pColumn="email">Contact email</th>
        <th pColumn="lastLogin" style="flex: 0 0 170px;">Last login</th>
        <th pColumn="created" style="flex: 0 0 170px;">Created</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded">
      <tr class="ui-selectable-row ui-tr"  #row [attr.id]="rowData.id" (click)="expandClick(rowData, tblObj)">
        <td style="flex: 0 0 50px; text-align: center;"><a href="#" (click)="onEditUser($event,rowData)"><i class="far fa-edit" ></i></a></td>
        <td style="flex: 0 0 100px;"><p-inputSwitch [(ngModel)]="rowData.enabledBin" (onChange)="onUserEnableChange($event, rowData)"></p-inputSwitch></td>
        <td style="flex: 0 0 150px;">{{rowData.typeName}}</td>
        <td style="flex: 0 0 100px;">{{rowData.yard}}</td>
        <td style="flex: 0 0 150px;">{{rowData.username}}</td>
        <td style="flex: 0 0 200px;">{{rowData.name}}</td>
        <td style="flex: 0 0 200px;">{{rowData.clientId}}</td>
        <td >{{rowData.email}}</td>
        <td style="flex: 0 0 170px;">{{rowData.lastLogin}}</td>
        <td style="flex: 0 0 170px;">{{rowData.created}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData >
      <tr>
        <td colspan="10" style="background-color:lightgrey">
          <div class="box2" style="margin-left: 20px; width: 400px;">
            <div class="card card-w-title" style="padding: 5px;" >
              <div class="bl-hdr">Options</div>
              <div class="zbrcont">
                <div><div class="box2 bl-col1">History view:</div>
                  <div class="box2"><p-inputSwitch [(ngModel)]="rowData.history"
                      (onChange)="onOptionChange($event,'history', rowData.id)"></p-inputSwitch></div></div>

                <div><div class="box2 bl-col1">SMS on order status change:</div>
                  <div class="box2"><p-inputSwitch [(ngModel)]="rowData.ordStatusChngSMS"
                      (onChange)="onOptionChange($event,'ordStatusChngSMS', rowData.id)"></p-inputSwitch></div></div>

                <div><div class="box2 bl-col1">Email on order status 'Loaded':</div>
                  <div class="box2"><p-inputSwitch [(ngModel)]="rowData.ordLoadedEmail"
                        (onChange)="onOptionChange($event,'ordLoadedEmail', rowData.id)"></p-inputSwitch></div></div>

                <div><div class="box2 bl-col1">Email on order photos change:</div>
                  <div class="box2"><p-inputSwitch [(ngModel)]="rowData.ordPhotoChngEmail"
                      (onChange)="onOptionChange($event,'ordPhotoChngEmail',rowData.id)"></p-inputSwitch></div></div>
                </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div style="text-align:left">
        <button type="button" pButton icon="pi pi-plus" (click)="onNewUser()" label="&nbsp;Add new user"></button>
      </div>
    </ng-template>
</p-table>

<p-dialog header="User details" [(visible)]="displayUserDialog" [focusOnShow]="false"  showEffect="fade" [modal]="true"  [style]="{width: '400px'}">
  <form #userForm="ngForm"   (ngSubmit)="submitUser(userForm)"  >
    <div class="p-grid ui-fluid" *ngIf="tmpUser">
      <div class="p-field" [style.height.px]="65">
        <label for="username">Username</label>
        <input pInputText id="username" name="username"  [style.width.%]="100" [(ngModel)]="tmpUser.username" required/>
      </div>

      <div class="p-field" [style.height.px]="65">
        <label for="password">Password</label>
        <input pInputText id="password" name="password"  [style.width.%]="100" [(ngModel)]="tmpUser.password" [required]="tmpUser.id==0"/>
      </div>

      <div class="p-field" [style.height.px]="65">
        <div class="box2"  [style.width.%]="50">
          <label for="type" [style.margin-top.px]="5">Type</label>
            <p-dropdown id="type" name="type" [options]="userTypes" [(ngModel)]="selectedType" appendTo="body"
            optionLabel="typeStr" placeholder="Select user type" [style]="{'width':'100%'}" ngModel required></p-dropdown>
        </div>
        <div class="box2" [style.width.%]="50"  [style.padding-left.px]="20">
          <label for="enabled" [style.margin-top.px]="5">Enabled</label>
          <p-dropdown id="enabled" name="enabled" [options]="enabledOptions" [(ngModel)]="selectedEnabledOpt" appendTo="body"
            optionLabel="optionStr" [style]="{'width':'100%'}" ngModel required ></p-dropdown>
        </div>
      </div>
      <div class="p-field" [style.height.px]="65" *ngIf="tmpUser.type=='4'">
        <label for="yard" [style.margin-top.px]="5">Assigned to yard</label>
        <p-multiSelect id="yard" name="yard" [options]="yardList" [(ngModel)]="selectedYards" appendTo="body"
          optionLabel="yard" [style]="{'width':'100%'}"></p-multiSelect>

      </div>

      <div class="p-field" [style.height.px]="65">
        <label for="name" [style.margin-top.px]="5">Contact name</label>
        <p-dropdown id="name" name="name" [options]="contactList" [(ngModel)]="tmpUser.contactId" appendTo="body"
          optionLabel="name" optionValue="id" placeholder="Select contact" [style]="{'width':'86%'}" ngModel ></p-dropdown>
          <div style="text-align: center; display: inline-block; margin-left: 10px; margin-bottom: 4px; vertical-align:middle;">
            <button type="button" pButton icon="pi pi-plus" (click)="onAddContact(tmpUser)"></button>
          </div>
      </div>

      <div class="p-field" [style.height.px]="65">
        <label for="canChatWith" [style.margin-top.px]="5">Can chat with users</label>
        <p-multiSelect [options]="chatUsers" [(ngModel)]="selectedChatUsers" name="canChatWith"
        [style]="{'width':'100%'}" optionLabel="name" appendTo="body"></p-multiSelect>
      </div>

    </div>
  </form>
  <p-messages [(value)]="errorMsg"></p-messages>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button type="button" pButton icon="pi pi-check" (click)="submitUser(userForm)" label="Save"></button>
      </div>
    </p-footer>
</p-dialog>
<app-contactedit (newContact)="onContactNewSave($event)"></app-contactedit>


</div>
