import { ContainerCar } from '@app/models/containerCar';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Order } from '../models';
import { Observable } from 'rxjs';
import { multiply } from 'lodash';

@Injectable({ providedIn: 'root' })
export class OrdersService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getAll(filter) {
      let params = new HttpParams();
      params = params.append('filter', JSON.stringify(filter) );
      return this.http.get<Order[]>(`${environment.apiUrl}/api/orders.php`, {params})
      .pipe( map( orders => orders as Order[]  ));
  }

  getOrdersInfo(filter) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_orders_info' );
    params = params.append('filter', JSON.stringify(filter) );
    return this.http.get<any>(`${environment.apiUrl}/api/orders.php`, {params})
      .toPromise().then(info => info  );
  }

  getOrderById(id: number ) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_order_byid' );
    params = params.append('id', id.toString() );
    return this.http.get<Order>(`${environment.apiUrl}/api/orders.php`, {params})
      .toPromise().then( res => res as Order);
  }

  getOrderByVinEnd(vin: string ) {
    let params = new HttpParams();
    params = params.append('cmd', 'get_order_by_vin_end' );
    params = params.append('vinEnd', vin );
    return this.http.get<Order>(`${environment.apiUrl}/api/orders.php`, {params})
      .toPromise().then( res => res as Order);
  }

  isVINused(vinnr: string ) {
    const filter = {vin: vinnr};
    let params = new HttpParams();
    params = params.append('filter', JSON.stringify(filter) );
    params = params.append('select', 'vin' );
    return this.http.get<string[]>(`${environment.apiUrl}/api/orders.php`, {params})
    .pipe( map( vins => vins.length  ));
  }

  suggestList(fieldName: string, val: string ) {
    const arr = {value: val, field: fieldName };
    let params = new HttpParams();
    params = params.append('suggest', JSON.stringify(arr) );
    return this.http.get<string[]>(`${environment.apiUrl}/api/orders.php`, {params})
    .pipe( map( res => res as string[] ));
  }



  insertNewOrder(order: Order): Observable<any> {
    const msg =  {cmd: 'new_order',  obj: order };
    return this.http.post(`${environment.apiUrl}/api/orders.php`, msg, this.httpOptions );
  }

  updateOrder(order: Order): Observable<any> {
    const msg =  {cmd: 'update_order',  obj: order };
    return this.http.post(`${environment.apiUrl}/api/orders.php`, msg, this.httpOptions );
  }

  editOrder(order: Order): Observable<any> {
    const msg =  {cmd: 'edit_order',  obj: order };
    return this.http.post(`${environment.apiUrl}/api/orders.php`, msg, this.httpOptions );
  }

  editFinish(userId: number): Observable<any> {
    const msg =  {cmd: 'edit_finish_order',  obj: userId };
    return this.http.post(`${environment.apiUrl}/api/orders.php`, msg, this.httpOptions );
  }

  deleteOrder(order: Order): Observable<any> {
    const msg =  {cmd: 'delete_order',  obj: order };
    return this.http.post(`${environment.apiUrl}/api/orders.php`, msg, this.httpOptions );
  }

  archiveOrder(order: Order): Observable<any> {
    const msg =  {cmd: 'archive_order',  obj: order };
    return this.http.post(`${environment.apiUrl}/api/orders.php`, msg, this.httpOptions );
  }

  getNewOrder(): Order {
    const order: Order = {
      id: 0, yard: '', date: '', lot: '', location: '', containerNr: '', color: '', carrierId: 0,
      buyerNr: '', auctionPrice: 0, buyerPay: 0, vin: '', car: '', deliveryPrice: 0, paidByClient: false,
      year: '', model: '', consignee: '', sublot: '', keyFob: null, title: false, clientId: null,
      clientInfo: '', comment: '', carrier: '', pickupDate: '', deliveryDate: '', lotTag: null,
      shippingLine: '', size: '', destination: '', destinationAgent: '', loadDate: '', auctionId: null,
      titleType: '', eta: '', checkNumber: '', orderId: '', photo1Cnt: null, forwarder: null,
      photo2Cnt: null, photo3Cnt: null, client: null, fuel: null, paymentDate: null, auctionName: null,
      containerPrice: 0, buyerFee: 0, storageFee: 0, storageFee2: 0, orderTotal: 0, containerId: 1, tracking: null,
      miscFee: 0, miscDescr: null, noteDisp: null, keyFobD: null, titleD: null, consigneeCountry: null,
      auctionAcc: null, auctionAccName: null, invId: null, invNr: null, invUUID: null, inEditBy: 0, archived: false,
    };
  //  order.date = formatDate(new Date(), 'MM/dd/yyyy', 'en');
    return order;
  }


}

