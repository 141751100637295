import { DocFile } from './../models/docfile';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocFileService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) {
  }

  getFileList(moduleId: number, subId: number) {
    const cmd = 'get_files';
    return this.http.post<DocFile[]>(`${environment.apiUrl}/api/docfiles.php`, { cmd, moduleId, subId }, this.httpOptions )
    .pipe(   map( files => files as DocFile[]  ));
  }

  deleteFile(fileId: number): Observable<any> {
    const cmd = 'delete_file';
    return this.http.post<any>(`${environment.apiUrl}/api/docfiles.php`, { cmd, fileId }, this.httpOptions );
  }

  uploadFile(file: File, moduleId: number, subId: number, docType: string): Observable<Response> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleId', moduleId.toString());
    formData.append('subId', subId.toString());
    formData.append('docType', docType);
    formData.append('cmd', 'upload_file');

    return this.http.post<Response>(`${environment.apiUrl}/api/docfiles.php`, formData);
  }

  updateFile(file: DocFile, id: number): Observable<any> {
    const msg =  {
      fileName: file.fname + '.' + file.fext,
      type: file.type,
      id: id.toString(),
      cmd: 'update_file' };
    return this.http.post<any>(`${environment.apiUrl}/api/docfiles.php`, msg, this.httpOptions);
  }

}
