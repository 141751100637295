import { PhotoService } from '@app/services/photo.service';
import { ContactService } from '@app/services/contact.service';
import { ContainerCar } from './../../models/containerCar';
import { Component, OnInit,  Output, EventEmitter, ViewChild } from '@angular/core';
import { Message, SelectItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { Container } from '@app/models/container';
import { ContainerService } from '@app/services/container.service';
import { cloneDeep } from 'lodash';
import { DocFile, Order, User } from '@app/models';
import { environment } from '@environments/environment';
import { DocFileService } from '@app/services/docfile.service';
import { DocEditComponent } from '../docedit/docedit.component';
import { YardsService } from '@app/services/yard.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { OrdersService } from '@app/services/order.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { Constants } from '@app/models/constants';
import { NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9/public-api';
import { PhotoEditComponent } from '../photoedit/photoedit.component';


@Component({
  selector: 'app-containeredit',
  templateUrl: './containeredit.component.html',
  styleUrls: ['./containeredit.component.css'],
})

export class ContainerEditComponent implements OnInit {
  @Output() newContainer = new EventEmitter<number>();
  @Output() updateContainer = new EventEmitter<number>();
  @Output() refreshContainer = new EventEmitter<unknown>();
  @ViewChild(DocEditComponent) docEdit: DocEditComponent;
  @ViewChild(PhotoEditComponent) photoEdit: PhotoEditComponent;
  c: any;
  displayDialog = false;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  errorMsg3: Message[];
  errorMsg2: Message[];
  destResults: string[];
  containerTypes: SelectItem[];
  container: Container;
  contCars: ContainerCar[];
  addNewCarMode = false;
  selectedVIN: string;
  autoComplList: string[];
  docList: DocFile[];
  originList: any[];
  vinToAdd: string;
  containerToVinList: any[];
  currentUser: User;
  intContactList: any[];
  tabIndex: number;
  emitEvents = true;
  editPhoto = false;

  editDisabled = {
    size: false,
    origin: false,
    consignee: false,
    destination: false,
    price: false,
  }

  fileURL = `${environment.apiUrl}/api/docfiles.php` + '?download_file=';

  constructor(
    private yardService: YardsService,
    private containerService: ContainerService,
    private contactService: ContactService,
    private authenticationService: AuthenticationService,
    private orderService: OrdersService,
    private docService: DocFileService,
    private router: Router,
    private photoService: PhotoService,
  ) {
    this.containerTypes = [
      { label: '', value: ''},
      { label: '40\' HC', value: '40\' HC'},
      { label: '20\' HC', value: '20\' HC'},
      { label: '45\' HC', value: '45\' HC'},
    ];
  }

  ngOnInit(): void {
    this.c = Constants;
    this.intContactList = [];
    this.tabIndex = 0;
    this.errorMsg3 = [];
    this.errorMsg2 = [];
    this.galleryImages = [];
    this.container = this.containerService.getNew();
    this.contCars = [];
    this.addNewCarMode = false;
    this.vinToAdd = '';
    this.containerToVinList = [];
    this.originList = [];
    this.yardService.getAll().then(yards => {
      this.originList = [];
      this.originList.push( {label: '', value: ''} );
      yards.forEach( y => { this.originList.push( {label: y.yard, value: y.yard}); });
    });
    this.galleryOptions = [  {  width: '100%', height: '300px', thumbnails: false ,
    preview: false }  ];
    this.authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = {...user};
        if ( this.currentUser.type === this.c.USER_TYPE_YARD  ) {
          this.editDisabled.size = true;
          this.editDisabled.origin = true;
          this.editDisabled.destination = true;
          this.editDisabled.consignee = true;
          this.editDisabled.price = true;
        }
      });
    this.loadConsigneeList();
  }


  submitContainer(form) {
    if (form.invalid) {
      this.errorMsg3 = [{severity: 'error', summary: 'Form vallidation!', detail: 'Fix errors and try again!'}];
    } else {
      if (this.container.price === '') { this.container.price = '0.00'; }
      if (this.container.id == null) {
        this.containerService.insertNew(this.container)
        .subscribe((cont: Container) => {
          this.saveContCars(cont.id);
          if(this.emitEvents)this.newContainer.emit(cont.id);
        });
      } else {
        this.containerService.update(this.container)
        .subscribe((cont: Container) => {
          this.saveContCars(cont.id);
          if(this.emitEvents)this.updateContainer.emit(cont.id);
        });
      }
      this.displayDialog = false;
    }
  }

  saveContCars(contId: number) {
    const promises = [];
    this.contCars.forEach( car => {
      if( car.status === 'new') {
        promises.push( this.containerService.addCarToContainer(car.id, contId));
      }
      if( car.status === 'delete') {
        promises.push( this.containerService.removeCarFromContainer(car.id, contId));
      }
    });
    Promise.all(promises)
    .then( cars => {
      cars.forEach( c => { c.status = 'loaded';  });
      this.contCars = cars;
      this.updateContainer.emit(contId);
    });
    this.addNewCarMode = false;
  }

  getNewContainer(): Promise<Container> {
    return this.containerService.getNextId()
      .then( res => {
        const cont = this.containerService.getNew();
        cont.containerNr = 'Consolid_' + res.id.padStart(4,'0');
        cont.bookingNr = 'Pending Booking';
        return cont;
      } );
  }

  show(cont: Container, tabIdx: number = 0) {
    this.tabIndex = tabIdx;
    this.emitEvents = true;
    this.addNewCarMode = false;
    this.selectedVIN = '';
    cont.loadDate = cont.loadDate ? formatDate(cont.loadDate, 'MM/dd/yyyy', 'en') : null;
    this.container = cloneDeep(cont);
    if ( this.container.price === '0.00') { this.container.price = ''; }

    if ( this.container.id !== null ) {
      this.containerService.getCarList(this.container.id)
      .then( cars => {
        this.contCars = cars;
        cars.forEach( c => {
          c.status = 'loaded';
          if (c.vin === this.vinToAdd) {this.vinToAdd = ''; }
        });
        this.displayDialog = true;
      });
      this.loadImages(this.container.id);
    } else {
      this.contCars = [];
      this.displayDialog = true;
    }
  }

  onDeleteCar(car: ContainerCar, event) {
    if( car.status === 'new') {
      const pos = this.contCars.findIndex( c=> c.vin === car.vin);
      if( pos !== -1) {
        this.contCars.splice( pos, 1);
      }
    }
    if( car.status === 'loaded') {
      car.status = 'delete';
    }
    event.stopPropagation();
    event.preventDefault();
  }

  onAddCar(cont: Container) {
    this.onAddVin(cont, '');
  }

  onAddVin(cont: Container, vin: string) {
    if ( vin === null ) { vin = ''; }
    this.addNewCarMode = true;
    this.selectedVIN = vin;
    this.containerService.getContainerToVinList()
      .then( list => {
        this.containerToVinList = list;
      });
  }

  addCarsByOrdersIds(list: string[]) {
    list.forEach( id=> {
      this.orderService.getOrderById(Number(id))
      .then( ord => { this.addContainerCarFromOrder( ord );   });
    });
  }

  addContainerCarFromOrder( ord: Order) {
    this.contCars.push({
      id: ord.id,
      date: ord.date,
      location: ord.location,
      buyerNr: ord.buyerNr,
      lot: ord.lot,
      vin: ord.vin,
      model: ord.model,
      year: ord.year,
      auctionPrice: ord.auctionPrice,
      consignee: ord.consignee,
      containerId: ord.containerId,
      status: 'new',
      lotTag: null
    });
    this.tabIndex = 1;
  }

  onCreateInvoice( cont: Container ) {
    const navigationExtras: NavigationExtras = {
      queryParams: { add_container: cont.id },
      skipLocationChange: true
    };
    this.router.navigate(['/invoices'], navigationExtras );
  }

  onAddCarComplete() {
    this.vinToAdd = '';
    let curCont = null;
    curCont = this.containerToVinList.find( i => i.vin === this.selectedVIN );
    if ( curCont === undefined) {
      this.errorMsg2 = [{severity: 'error', summary: 'Error', detail: 'VIN number does not exist!'} ];
      return;
    } else
    if ( curCont.containerNr !== null) {
      this.errorMsg2 = [{severity: 'error', summary: 'Error', detail: 'VIN number is assigned to: ' + curCont.containerNr } ];
      return;
    } else {
      const lst = [curCont.id];
      curCont = this.contCars.find( c => c.vin === this.selectedVIN );
      if(curCont !== undefined ) { // is in list
        this.errorMsg2 = [{severity: 'error', summary: 'Error', detail: 'VIN number is in list! ' } ];
        return;
      }
      this.addCarsByOrdersIds( lst );
    }
  }

  searchVIN(event) {
    this.containerService.getCarAutosuggest(event.query, this.container.id)
      .toPromise().then(data => {
        this.autoComplList = data;
    });
  }

  setVinToBeAdded( vin: string) {
    this.vinToAdd = vin;
  }

  onTabChange(event, contId) {
    this.addNewCarMode = false;
    if (event.index === 2) {
      this.loadDocList(Number(contId));
    }
  }

  loadDocList(id: number) {
    this.docService.getFileList( 2, id ) // 1- contacts, 2 - container
      .subscribe( list => {
        this.docList = list;
      });
  }

  onDocumentEdit(contId) {
    this.docEdit.show( 2, contId); // 1- contacts, 2 - container
  }

  onDocListChanged(id) {
    this.loadDocList(id);
  }

  getDestSuggest(event) {
    let str = event.query as string;
    str = str.replace(' ','#');
    str = str.replace('-','#');
    this.containerService.suggestDestination(str)
    .toPromise().then( res => {
      this.destResults = [];
      res.forEach( i => {
        this.destResults.push( i.city + ', ' + i.country);
      } );
    });
  }

  getDestAgentSuggest(event) {
    const str = event.query as string;
    this.containerService.suggestDestinationAgent(str)
    .toPromise().then( res => {
      this.destResults = res;
    });
  }

  loadConsigneeList(){
    this.intContactList = [
      // { label: '', value: null }
    ];
    this.contactService.getContactList('7') // 7 - consignee
    .then( list => {
      list.forEach( c=> {
        this.intContactList.push({ label: c.clientId, value: c.clientId });
      });
    });
  }

  loadImages(containerid) {
    let nr = 1;
    this.galleryImages = [];
    this.photoService.getPhotosOf( containerid, 3 )
    .toPromise().then( photos => {
      if (photos === null) { photos = []; }
      photos.forEach ( photo => {
        photo.small = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=small`;
        photo.medium = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=medium`;
        photo.big = `${environment.apiUrl}/api/images.php?file=` + photo.big + `&size=big`;
        photo.description = 'Photo: ' + nr + ' of ' + photos.length;

        nr = nr + 1;
      });

      this.galleryImages = photos;
    }
    );
  }

  onEditPhotoToggle(cont: Container) {
    if (this.editPhoto) {
      this.editPhoto = false;
      this.photoEdit.hide();
    } else {
        this.editPhoto = true;
        this.photoEdit.show(this.galleryImages, cont.id);
    }
  }

  onPhotoEditClosed() {
    this.editPhoto = false;
  }

  onImageListChanged(id) {
    this.loadImages(id);
  }
}
