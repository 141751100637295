<p-dialog  [(visible)]="displayDialog" [focusOnShow]="false"  showEffect="false" [modal]="true"  [keepInViewport]="true"
 appentTo="body" (onHide)="onHide()" [style]="{width: '550px'}">
  <p-header *ngIf="!uploadMode" >
    <div style="width: 400px;" >
      <span style="display: inline-block;" *ngIf="!showCamera">
        <button type="button" pButton icon="pi pi-plus" (click)="onAddImage()" [label]="!isMobile? '&nbsp;Add more ...': ''"></button>
        <button *ngIf="isMobile" type="button" pButton icon="fas fa-camera" style="margin-left: 10px;" (click)="onAddByCamera()" ></button>
      </span>
      <span style="margin-left: 20px;">Images of vehicle</span>
    </div>
  </p-header>
  <p-header *ngIf="uploadMode">
    <span style="display: inline-block;" >
      <button type="button" pButton  (click)="onAddImage()" label="Back to list"></button>
    </span>
    <span style="margin-left: 20px;">Images of vehicle</span>
  </p-header>

  <div *ngIf="!uploadMode">
    <p-dataView #dv [value]="imageList" [paginator]="false" [rows]="20" >
      <ng-template let-image pTemplate="listItem">
        <div class="p-col-12">
            <div class="photo-details">
                <div style="width: 100%;">
                    <img src="{{image.small}}" width="160px" height="120px">
                    <div class="p-grid" >
                        <div class="p-col-12" style="width: 240px; display: inline-block; vertical-align: top;
                          text-overflow: ellipsis; overflow: hidden; font-weight: bold;">{{image.file_name}}</div>
                        <div class="p-col-12">Upload date: <b>{{image.upload_date | date }}</b></div>
                        <div class="p-col-12">Dimensions: <b>{{image.dimensions }}</b></div>
                        <div class="p-col-12">Size: <b>{{(image.size/1024).toFixed(2)+'Kb' }}</b></div>
                    </div>
                </div>
                <button pButton type="button" icon="far fa-trash-alt" (click)="onDeleteImage($event, image)"></button>
            </div>
        </div>
    </ng-template>
    </p-dataView>
  </div>
  <div *ngIf="uploadMode">
    <div >
      <p-fileUpload name="images[]" customUpload="true" (uploadHandler)="photoUploader($event)" (onClear)="onCancelUpload()"
        [showCancelButton]="false" multiple="multiple" accept=".jpeg,.jpg,.png" maxFileSize="10000000" >
      </p-fileUpload>
    </div>
  </div>
  <div *ngIf="showCamera" style="margin-top: 5px;">
    <div #vidCont  class="cam_wrapper" (window:resize)="onResize($event)" (window:orientationchange)="onResize($event)"
      [style]="isLandscape? '':'background-color: #A0A0A0;'">
      <div *ngIf="isLandscape">
        <webcam *ngIf="camWidth>0 " [height]="camHeight" [width]="camWidth" [trigger]="triggerObservable"
          [videoOptions]="videoOptions"
          (imageCapture)="handleImage($event)" [imageQuality]="1">
        </webcam>
        <button pButton type="button" icon="fas fa-camera" style="position: absolute; bottom: 40px; left: 50%; color: white;"
          class="p-button-rounded p-button-outlined" label="" (click)="captureImage();"></button>
      </div>
      <div *ngIf="!isLandscape">
        <img src="../../../assets/images/phone-to-landscape.png" width="200" height="200" style="padding: 20px;">
      </div>
    </div>
  </div>

</p-dialog>
