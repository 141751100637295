import { Contact } from '@app/models/contact';
import { ContactService } from '@app/services/contact.service';
import { AuthenticationService } from './../services/authentication.service';
import { EmailBodyContComponent } from '../components/emailbody/emailbody.cont.componet';
import { ContainerService } from '@app/services/container.service';
import { Container } from '@app/models/container';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ViewChildren, ElementRef, QueryList, ChangeDetectorRef, Inject } from '@angular/core';
import { ContainerEditComponent } from '@app/components/containeredit/containeredit.component';
import { DocFile, User, Yard } from '@app/models';
import { DocFileService } from '@app/services/docfile.service';
import { MenuItem } from 'primeng/api/menuitem';
import { YardsService } from '@app/services/yard.service';
import { EmailEditComponent } from '@app/components/emailedit/emailedit.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { formatDate } from '@angular/common';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ContainerCar } from '@app/models/containerCar';
import { Constants } from '@app/models/constants';
import { NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';

@Component({
  templateUrl: 'containerview.component.html',
  styleUrls: ['containerview.component.css'],
  providers: [DialogService, ConfirmationService]
})

export class ContainerViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ContainerEditComponent) containerEdit: ContainerEditComponent;
  @ViewChild(EmailBodyContComponent) emailBodyTpl: EmailBodyContComponent;
  @ViewChildren('row', { read: ElementRef }) rowElement: QueryList<ElementRef>;
  @ViewChild('tblCtrObj') tblView: any;
  @ViewChild('tblCbView') tblCbView;
  ref: DynamicDialogRef;
  c: any;
  loading = false;
  galleryImages: NgxGalleryImage[];
  galleryCurSelectedImage: number;
  galleryOptions: NgxGalleryOptions[];
  containerList: Container[];
  selectedContainers: Container[];
  selectedContainerId: string[];
  docList: DocFile[];
  selectedList: string[];
  paymentItems: MenuItem[];
  menuItems: MenuItem[];
  pmTypeList: any[];
  originList: any[];
  contFilter: any;
  contCars: ContainerCar[];
  contCarList: ContainerCar[][];
  searchBar: boolean;
  overlayText: string;
  currentUser: User;
  displayDocDialog = false;
  containerTrackURL = 'http://www.shippingline.org/track/?type=container&container=';
  selectionEnabled = false;
  editingEnabled = true;
  selConsignee: Contact;
  selConsigneeList: Contact[];
  colSpan: string;
  curExpandedRowId: number;
  gfDocTypes: SelectItem[];
  globalNoDocFilter: string;
  clipbEnabled = false;
  promises = [];
  promisesConsignee = [];

  enabledColumns = {
    tracking: true,
    payment: true,
    price: true
  };

  constructor(
    private yardService: YardsService,
    private confirmationService: ConfirmationService,
    private containerService: ContainerService,
    private contactService: ContactService,
    private docService: DocFileService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.c = Constants;
    this.curExpandedRowId = null;
    this.loading = true;
    this.searchBar = false;
    this.contCars = [];
    this.contCarList = [];
    this.selectedList = [];
    this.selectedContainers = [];
    this.selectedContainerId = [];
    this.selConsigneeList = [];
    this.contFilter = {};
    this.colSpan = '15';
    this.selConsignee = null;
    this.globalNoDocFilter = '';
    this.galleryOptions = [  {  width: '100%', height: '300px', thumbnails: false , preview: false, imageDescription: false  }  ];
    this.authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = {...user};
        if(this.currentUser){ // onLogout == NULL
          if(this.currentUser.yard !== null) {
            this.contFilter.yard = this.currentUser.yard;
          }
        }
        this.loadContainers();
        if ( this.currentUser.type === this.c.USER_TYPE_YARD  ) {
          this.enabledColumns.tracking = false;
          this.enabledColumns.payment = false;
          this.enabledColumns.price = false;
          this.editingEnabled = false;
          this.colSpan = '12';
        }
      });

    this.paymentItems = [
      {label: 'Set PAID',     command: () => {  this.setPaid();     }},
      {label: 'Set UNPAID',   command: () => {  this.setUnpaid();   }},
      {label: 'Set RELEASED', command: () => {  this.setReleased(); }},
      {separator: true},
      {label: 'Clear selection', command: () => {  this.selectedList = []; this.selectionEnabled = false;}},
    ];
    this.pmTypeList = [
      {label: 'All', value: ''},
      {label: 'Unpaid', value: '0'},
      {label: 'Paid', value: '1'},
      {label: 'Released', value: '2'}
    ];
    this.yardService.getAll().then(yards => {
      this.originList = [];
      this.originList.push( {label: 'All', value: ''} );
      yards.forEach( y => { this.originList.push( {label: y.yard, value: y.yard}); });
    });
    this.gfDocTypes = [
      {label: '', value: 0},
      {label: 'Master B/L (Bill of Landing)', value: 'Master B/L (Bill of Landing)'},
      {label: 'Validated Titles', value: 'Validated Titles'},
      {label: 'Container Release', value: 'Container Release'},
    ];

  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => { // route parameter
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  loadContainers(){
    this.loading = true;
    this.containerService.getAll(this.contFilter)
    .then( containerList => {
      this.containerList = containerList;
      this.containerList.forEach(element => {
        if ( element.emailId !== null ) { element.emailId = Number(element.emailId); }
      });
      this.loading = false;
    });
  }

  onToggleCntSearchBar( tbl, event ) {
    event.stopPropagation();
    event.preventDefault();

    if (this.searchBar === true) {
      this.searchBar = false;
      tbl.reset();
    } else {
      this.searchBar = true;
    }
  }

  expandClick(rowData: Container, tblObj) {
    if (tblObj.expandedRowKeys[rowData.id] != null) { // colapse
      delete tblObj.expandedRowKeys[rowData.id];
      tblObj.expandedRowKeys = {};
      this.curExpandedRowId = null;
    } else { // expand
      this.curExpandedRowId = rowData.id;
      tblObj.expandedRowKeys = {};
      tblObj.expandedRowKeys[rowData.id] = true;
      this.loadContCarsByContId( rowData.id);
      setTimeout( () => {
        const el = this.rowElement.find(r => r.nativeElement.getAttribute('id') === rowData.id);
        el.nativeElement.scrollIntoView({behavior: 'smooth', inline: 'start', block: 'start'});
      },200 );
      this.selConsignee = null;
      this.contactService.getConsignee( rowData.destinationAgent)
        .then( cnt => {  this.selConsignee = cnt;   });
    }
  }

  onNewContainer() {
    this.containerEdit.getNewContainer()
      .then( cont => this.containerEdit.show( cont ) );
  }

  loadContCarsByContId( id: number) {
    this.containerService.getCarList(id)
    .then( cars => {
      cars.forEach( car=> car.tagStyle = this.getLogTagStyle(car.lotTag) );
      this.contCars = cars;
    });
  }

  onContainerNewSave(id: number) {
    this.loading = true;
    this.containerService.getAll(null)
    .then( containerList => {
      this.containerList = containerList;
      this.loading = false;
    });
  }

  onContainerUpdateSave(id: number) {
    this.loading = true;
    this.containerService.getAll(null)
    .then( containerList => {
      this.containerList = containerList;
      if(this.curExpandedRowId)
        this.loadContCarsByContId( this.curExpandedRowId);
      this.loading = false;
    });
  }

  showDocViewPanel(event,  contId) {
    event.stopPropagation();
    event.preventDefault();
    this.loadDocList(contId);
    this.displayDocDialog = true;
  }

  loadDocList(id: number) {
    this.docService.getFileList( 2, id )
      .subscribe( list => {
        this.docList = list;
        this.docList.forEach( i=> {
          if(i.type === 'null') i.type = '';
        });
      });
  }

  onPayment() {
    const tmpArr = [];
    let havePaid = false;
    this.containerList.forEach(element => {
      if ( this.selectedList.includes(element.id.toString()) ) {
        tmpArr.push(element);
        if ( element.emailId !== null ) { havePaid = true; }
      }
    });
    if ( havePaid ) {
      let msg = 'Your selection have containers with payment email sent!<br>';
      tmpArr.forEach ( element => {
        if ( element.emailId !== null ) { msg += `<b>`; }
        msg += `[${element.containerNr}] => `;
        if ( element.emailId === null ) {
          msg += `OK <br>`;
        } else { msg += `SENT <br>`;   }
        if ( element.emailId !== null ) { msg += `</b>`; }
      });
      msg += `Do you realy want to include this in payment email again? `;
      this.confirmationService.confirm({
        header: 'Information',
        message:  msg,
        accept: () => this.showEmailDialog()
      });
    } else { this.showEmailDialog(); }

  }

  showEmailDialog() {
    this.emailBodyTpl.setData( {
      wireDate: formatDate(new Date(), 'MM/dd/yyyy', 'en'),
      wireFrom: 'Transbaltica Inc.',
      containerList: this.selectedList.toString()
    }).then ( () => {
      const lines = this.emailBodyTpl.getShippingList();
      if (lines.length === 1) {
        this.onTplDataLoaded(lines[0]);
      } else {
        setTimeout( () => {
          this.confirmationService.confirm({
            rejectVisible: false,
            acceptLabel: 'Ok',
            header: 'Information',
            message: 'You selected more than one Shipping Line!<br>' +
              'Please select only <b>ONE</b> and try again!<br>' + 'Your selection: [<b>' + lines.toString() + '</b>]'
          });
        }, 200 );
      }
    });
  }

  loadStoredEmail(event, id: number) {
    event.stopPropagation();
    event.preventDefault();
    this.ref = this.dialogService.open(EmailEditComponent, {
      data: {
          to: '',
          subject: '',
          emailId: id,
          body: ''
      },
      header: 'Prewiew stored email',
      width: '50%'
    });

  }


  onTplDataLoaded( line: string) {
    const date = formatDate(new Date(), 'MM/dd/yyyy', 'en');
    this.ref = this.dialogService.open(EmailEditComponent, {
      data: {
          to: '',
          emailId: 0, // new email
          subject: date + ' Payment details for ' + line + ' containers',
          body: this.emailBodyTpl.getHtmlContent()
      },
      header: 'Compose email',
      width: '50%'
    });
    this.ref.onClose.subscribe((resp: any) => {
      if (resp !== undefined) {
        this.updateEmailIds(resp.emailId);
        this.confirmationService.confirm({
          header: 'Confirmation',
          message: 'Selected containers state change to <b>PAID</b> ?',
          accept: () => { this.updatePaymentStatus(1);  },
          reject: () => { this.selectedList = []; }
        });
      }
    });
  }

  setPaid() {
    this.updatePaymentStatus(1);
  }

  setUnpaid() {
    this.updatePaymentStatus(0);
  }

  setReleased() {
    this.updatePaymentStatus(2);
  }

  updateEmailIds( emailId: number) {
    this.loading = true;
    const list =  this.selectedList.toString();
    this.containerService.setEmailId( list, emailId )
    .then(containerList => {
      this.containerList = containerList;
      this.loading = false;
    });
  }

  updatePaymentStatus(type: number) {
    this.loading = true;
    const list =  this.selectedList.toString();
    this.containerService.setPaymentStatus( list, type )
    .then(containerList => {
      this.containerList = containerList;
      this.loading = false;
      this.selectedList = [];
    });
  }

  toggleOverlay(event, overl, txt) {
    event.stopPropagation();
    event.preventDefault();
    this.overlayText = txt;
    overl.toggle(event);
  }

  onUpdateContainerList() {
    this.loading = true;
    this.containerService.getAll(null)
    .then( containerList => {
      this.containerList = containerList;
      this.loading = false;
    });
  }

  onMenuShow(event, menu, cont: Container) {
    event.stopPropagation();
    event.preventDefault();
    const items = [
      { label: ' Expand row', icon: 'fas fa-expand', command: () => {  this.expandClick(cont, this.tblView); }},
      { label: 'Edit row', icon: 'fas fa-edit', command: ()=>{ this.containerEdit.show(cont); }}
    ];
    if ( this.currentUser.type !== this.c.USER_TYPE_YARD  ) {
      items.push( { label: 'Add to invoice', icon: 'fas fa-file-alt', command: () => { this.containerEdit.onCreateInvoice(cont);  } } );
    }
    this.menuItems = [{
      label: 'Options',
      items
    }];
    menu.toggle(event);
  }

  onEditContCars(cont: Container) {
    this.containerEdit.show(cont, 1);
  }

  getLogTagStyle( tag ) {
    if( Number(tag) >=0 && Number(tag) <= 200 ) return ' background-color: #80A0DF; ';
    if( Number(tag) >200 && Number(tag) <= 300 ) return ' background-color: #F8F880; ';
    if( Number(tag) >=300 && Number(tag) <= 450 ) return ' background-color: #FF8080 ';
    return '';
  }

  toggleGlobalFilter(ev, opgf ){
    // this.auctionService.getAuctionAcc()
     // .then( lst => {
    //    this.auctionAccList = lst;
        opgf.toggle(ev);
    //  });
  }

  globalFilterApply(event, opgf){
    this.onGlobalFilterChange();
    opgf.hide(event);
  }

  onGlobalFilterChange(){
    if(this.globalNoDocFilter!=='') {
      this.contFilter.globalNoDoc = this.globalNoDocFilter;
    } else {
      this.contFilter.globalNoDoc = undefined;
    }

    this.loadContainers();
  }

  globalFilterCancel(event, opgf){
    if(
        this.globalNoDocFilter !==''
        ) {

      this.globalNoDocFilter='';
      this.onGlobalFilterChange();
    }
    opgf.hide(event);
  }

  clipboardEnable() {
    if(this.clipbEnabled) {
      this.clipbEnabled = false;
      this.selectedContainers = [];
      this.selectedContainerId = [];
    } else {
      this.clipbEnabled = true;
    }
  }

  onCBSelClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  loadContCarsList() {
    this.contCarList = [];
    this.selConsigneeList = [];
    this.promises = [];
    this.promisesConsignee = [];

    this.selectedContainers.forEach( cont => {
      this.promisesConsignee.push(
        this.contactService.getConsignee( cont.destinationAgent )
        .then( cnt => {  this.selConsigneeList.push(cnt);   })
      );

      this.promises.push(this.containerService.getCarList(cont.id)
      .then( cars => {
        cars.forEach( car=> car.tagStyle = this.getLogTagStyle(car.lotTag) );
        this.contCarList.push(cars);
      }));
    });
  }

  onClipbCopy(event) {
    this.loading = true;
    this.selectedContainers = [];
    this.selectedContainerId.forEach(contId => {
      const tOrd = this.containerList.find(cont => Number(cont.id) === Number(contId));
      if(tOrd){
        (tOrd as any).consObj = this.containerService.getNew();
        this.selectedContainers.push(tOrd);
      }
    });

    this.loadContCarsList();
    Promise.all(this.promises)
      .then( () =>{
        Promise.all(this.promisesConsignee)
          .then( () =>{
            this.loading = false;
            this.selectedContainers.forEach( cont => {
              const tCons = this.selConsigneeList.find(c => c.clientId === cont.destinationAgent);
              (cont as any).consObj = tCons;
            });

            this.changeDetectorRef.detectChanges();
            const el = this.tblCbView.nativeElement;
            const data = new Blob([el.innerHTML], {type: 'text/html'})
            const item = [new ClipboardItem({'text/html': data})];
            navigator.clipboard.write(item).then();
            this.clipboardEnable();
          });
      }
      );
  }
}
