import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Notice } from '../models';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class NoticeService {
    apiURL = `${environment.apiUrl}/api/notices.php`;
    httpOptions = {
      headers: new HttpHeaders({  'Content-Type':  'application/json' })
    };

    constructor(private http: HttpClient) { }

    getAll(userId) {
      let params = new HttpParams();
      params = params.append('userId', userId.toString() );
      return this.http.get<Notice[]>(this.apiURL, {params})
      .toPromise()
      .then( res => res as Notice[]);
    }

    getById(id: number) {
      let params = new HttpParams();
      params = params.append('cmd', 'get_by_id' );
      params = params.append('noticeId', id.toString() );
      return this.http.get<Notice>(this.apiURL, {params})
      .toPromise()
      .then( res => res as Notice);
    }

    getNew(): Notice{
      return { id: 0, clientId: 0, client: null, date: null, subject: null, text: null, confirmed: false };
    }

    deleteOrder(notice: Notice): Observable<any> {
      const msg =  {cmd: 'delete_notice', obj: notice };
      return this.http.post(this.apiURL, msg, this.httpOptions );
    }

    insertNewNotice(notice: Notice): Observable<any> {
      const msg =  {cmd: 'new_notice',  obj: notice };
      return this.http.post(this.apiURL, msg, this.httpOptions );
    }

    updateNotice(notice: Notice): Observable<any> {
      const msg =  {cmd: 'update_notice',  obj: notice };
      return this.http.post(this.apiURL, msg, this.httpOptions );
    }

    accptNotice(id: number) {
      let params = new HttpParams();
      params = params.append('cmd', 'accept_id' );
      params = params.append('noticeId', id.toString() );
      return this.http.get<Notice>(this.apiURL, {params})
      .toPromise()
      .then( res => res as Notice);
    }
}

