import { NoticeEditComponent } from './components/noticeedit/noticeedit.component';
import { NoticeViewComponent } from './views/noticeview.component';
import { EmailBodyContComponent } from './components/emailbody/emailbody.cont.componet';
import { EmailBodyOrderComponent } from './components/emailbody/emailbody.order.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { LoginComponent } from './login/login.component';
import { MenuService } from '@app/services/menu.service';
import { AppTopBarComponent } from '@app/app.topbar.component';
import { AppMenuComponent } from '@app/menu/menu.component';
import { AppMenuitemComponent } from '@app/menu/menuitem.component';
import { OrderViewComponent } from '@app/views';
import { UserViewComponent } from '@app/views';
import { ContainerViewComponent } from '@app/views';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { ContactViewComponent } from '@app/views';
import { OrderEditComponent } from '@app/components/orderedit/orderedit.component';
import { PhotoEditComponent } from '@app/components/photoedit/photoedit.component';
import { ContainerEditComponent } from '@app/components/containeredit/containeredit.component';
import { ContactEditComponent } from '@app/components/contactedit/contactedit.component';
import { InvoiceViewComponent } from '@app/views';
import { InvoiceEditComponent } from '@app/components/invoiceedit/invoiceedit.component';
import { DocEditComponent } from '@app/components/docedit/docedit.component';
import { DocListComponent } from '@app/components/doclist/doclist.component';
import { EmailEditComponent } from '@app/components/emailedit/emailedit.component';
import { SettingsEditComponent } from './components/settingsedit/settingsedit.component';
import { DndModule } from 'ngx-drag-drop';
import { JwtModule } from '@auth0/angular-jwt';
import { NgChatModule } from 'ng-chat';
import { WebcamModule } from 'ngx-webcam';

// Primeng modules
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { ConfirmationService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { SMSEditComponent } from './components/smsedit/smsedit.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { InputNumberModule } from 'primeng/inputnumber';
import { ContSelComponent } from './components/invoiceedit/contsel/contsel.component';
import { AppPriceValidateDirective } from './components/orderedit/price.validator';
import { InfobyvinComponent } from './views/infobyvin/infobyvin.component';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SkeletonModule } from 'primeng/skeleton';
import { FormsModule } from '@angular/forms';
import { InjectionToken } from '@angular/core';

export function userTokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent,
    OrderViewComponent,
    UserViewComponent,
    OrderEditComponent,
    ContainerEditComponent,
    ContainerViewComponent,
    PhotoEditComponent,
    InvoiceViewComponent,
    InvoiceEditComponent,
    ContactViewComponent,
    ContactEditComponent,
    DocEditComponent,
    DocListComponent,
    EmailEditComponent,
    EmailBodyContComponent,
    EmailBodyOrderComponent,
    SMSEditComponent,
    ContSelComponent,
    AppPriceValidateDirective,
    InfobyvinComponent,
    SettingsEditComponent,
    NoticeViewComponent,
    NoticeEditComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MessageModule,
    MessagesModule,
    DialogModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    OverlayPanelModule,
    CheckboxModule,
    TabViewModule,
    TriStateCheckboxModule,
    SelectButtonModule,
    ConfirmDialogModule,
    NgxGalleryModule,
    DataViewModule,
    ToastModule,
    FileUploadModule,
    DndModule,
    AutoCompleteModule,
    InputTextareaModule,
    InputSwitchModule,
    SplitButtonModule,
    DynamicDialogModule,
    EditorModule,
    ProgressBarModule,
    TooltipModule,
    ProgressSpinnerModule,
    SidebarModule,
    MenuModule,
    NgChatModule,
    RippleModule,
    CardModule,
    MultiSelectModule,
    RadioButtonModule,
    TieredMenuModule,
    InputNumberModule,
    ChipModule,
    WebcamModule,
    SlideMenuModule,
    SkeletonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: userTokenGetter,
      },
    })

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService, MessageService, ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
